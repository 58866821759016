import React from "react";
import {
	// ClipboardCopyIcon,
	DotsHorizontalIcon,
	// EyeIcon,
	// SelectorIcon,
	// TrashIcon,
	ViewGridAddIcon,
} from "@heroicons/react/solid";
import { Col, Button, Dropdown, ListGroup } from "react-bootstrap";

const KanbanList = (props) => {
	const { id, listRef, extraProps = {}, title } = props;

	const onCardAdd = () => {
		props.onCardAdd && props.onCardAdd(id);
	};

	// Uncomment the functions if needed
	// const onListCopy = () => {
	//   props.onListCopy && props.onListCopy(id);
	// };

	// const onListMove = () => {
	//   props.onListMove && props.onListMove();
	// };

	// const onListDelete = () => {
	//   props.onListDelete && props.onListDelete(id);
	// };

	return (
		<Col xs={12} lg={6} xl={4} xxl={3} ref={listRef} {...extraProps}>
			<div className="d-flex justify-content-between align-items-center kanban-title-container mb-2">
				<h5 className="kanban-title d-flex align-items-center w-100 fs-6 fw-bold p-2 m-0">
					{title}
				</h5>

				<Dropdown>
					<Dropdown.Toggle
						as={Button}
						variant="link"
						size="sm"
						className="fs-6 px-1 py-0"
					>
						<DotsHorizontalIcon className="icon icon-xs text-gray-500" />
					</Dropdown.Toggle>

					<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
						<Dropdown.Item className="d-flex align-items-center" onClick={onCardAdd}>
							<ViewGridAddIcon className="icon icon-xs text-gray-400 me-2" /> Add Card
						</Dropdown.Item>
						{/* Uncomment the menu items if needed */}
						{/* <Dropdown.Item className="d-flex align-items-center" onClick={onListCopy}>
              <ClipboardCopyIcon className="icon icon-xs text-gray-400 me-2" /> Copy List
            </Dropdown.Item>
            <Dropdown.Item className="d-flex align-items-center" onClick={onListMove}>
              <SelectorIcon className="icon icon-xs text-gray-400 me-2" /> Move List
            </Dropdown.Item>
            <Dropdown.Item className="d-flex align-items-center">
              <EyeIcon className="icon icon-xs text-gray-400 me-2" /> Watch
            </Dropdown.Item>
            <Dropdown.Divider as="div" className="my-1" />
            <Dropdown.Item className="d-flex align-items-center" onClick={onListDelete}>
              <TrashIcon className="icon icon-xs text-danger me-2" /> Remove
            </Dropdown.Item> */}
					</Dropdown.Menu>
				</Dropdown>
			</div>

			<ListGroup className="kanban-list">{props.children}</ListGroup>
		</Col>
	);
};

export default KanbanList;
