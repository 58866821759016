import React, { useEffect, useState } from "react";
import {
	Button,
	Col,
	Form,
	Image,
	InputGroup,
	Modal,
	Row,
} from "react-bootstrap";

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

const ViewTeamMembersModal = (props) => {
	const { title, membersData, showModal, setShowModal, isUpdated } = props;
	const [searchValue, setSearchValue] = useState("");
	const [teamMembers, setTeamMembers] = useState(
		membersData?.map((tm) => ({
			...tm,
			show: true,
		})),
	);

	useEffect(() => {
		setTeamMembers(
			membersData?.map((tm) => ({
				...tm,
				show: true,
			})),
		);
	}, [membersData, isUpdated]);

	const onSearchValueChange = (e) => {
		const newSearchValue = e.target.value;
		const searchResults = teamMembers.map((tm) => ({
			...tm,
			show:
				tm.first_name.toLowerCase().includes(newSearchValue.toLowerCase()) ||
				tm.last_name.toLowerCase().includes(newSearchValue.toLowerCase()),
		}));

		setSearchValue(newSearchValue);
		setTeamMembers(searchResults);
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			scrollable
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">{title} - Members</Modal.Title>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="search" className="mb-3">
						<InputGroup className="search-bar">
							<Form.Control
								type="text"
								placeholder="Search board members.."
								value={searchValue}
								onChange={onSearchValueChange}
							/>
						</InputGroup>
					</Form.Group>

					<div className="px-3" style={{ overflowY: "scroll", maxHeight: "300px" }}>
						{teamMembers?.length === 0 ? (
							<p className="text-center">No members in this team yet</p>
						) : (
							<div
								className="px-3"
								style={{ overflowY: "scroll", maxHeight: "300px" }}
							>
								{teamMembers
									?.filter((m) => m.show)
									.map((m) => (
										<Row
											key={`board-member-${m?._id}`}
											className="kanban-card-member border-bottom py-2"
										>
											<Col xs={2}>
												{m?.profile_image ? (
													<Image
														src={m?.profile_image}
														className="avatar-md rounded-circle"
													/>
												) : (
													<div className="avatar-md rounded-circle d-flex align-items-center justify-content-center fw-bold bg-secondary">
														<span>
															{getInitialsOfFirstTwoWords(
																`${m?.first_name.charAt(0).toUpperCase()}${m?.first_name.slice(
																	1,
																)} ${m?.last_name.charAt(0).toUpperCase()}${m?.last_name.slice(
																	1,
																)}`,
															)}
														</span>
													</div>
												)}
											</Col>
											<Col
												xs={8}
												className="d-flex align-items-center justify-content-start"
											>
												<h4 className="fs-6 text-dark mb-0">
													{m?.first_name &&
														m.first_name.charAt(0).toUpperCase() + m.first_name.slice(1)}{" "}
													{m?.last_name &&
														m.last_name.charAt(0).toUpperCase() + m.last_name.slice(1)}
												</h4>
											</Col>
										</Row>
									))}
							</div>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pb-0"></Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ViewTeamMembersModal;
