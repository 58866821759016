import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import {
	getOrganisationReport,
	getTeamReport,
	getUserReport,
} from "services/reportService";
import { toast } from "react-toastify";

const ReportOptionModal = (props) => {
	const { setShow, show, type } = props;
	const [downloadType, setDownloadType] = useState("");
	const [timeFrame, setTimeFrame] = useState("");
	const [itemId, setItemId] = useState("");
	const [title, setTitle] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (props.user && props.type === "user") {
			setTitle(
				`Download Report for ${props.user.first_name} ${props.user.last_name}`,
			);
			setItemId(props.user._id);
		} else if (props.type === "team") {
			setTitle(`Download Report for ${props.team.name}`);
			setItemId(props.team._id);
		} else {
			setTitle("Download Organisational Report");
		}
	}, [props]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
			let response;
			if (type === "user") {
				response = await getUserReport(itemId, timeFrame, downloadType);
			} else if (type === "team") {
				response = await getTeamReport(itemId, timeFrame, downloadType);
			} else {
				response = await getOrganisationReport(timeFrame, downloadType);
			}
			// Create a blob from the response data
			const blob = new Blob([response.data]);

			// Create a URL for the blob and initiate download
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;

			const currentDate = new Date().toLocaleString("en-NG", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				hour12: false,
			});
			link.setAttribute("download", `report_${currentDate}.${downloadType}`);

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);

			toast("File downloaded successfully", {
				type: "success",
				autoClose: 2000,
			});
		} catch (err) {
			toast(err.message || "An error occurred", {
				type: "error",
				autoClose: 2000,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal as={Modal.Dialog} centered show={show} onHide={() => setShow(false)}>
			<Form className="modal-content p-3" onSubmit={handleSubmit}>
				<Modal.Header className="pb-0 border-0">
					<h5 className="fw-normal">{title}</h5>
					<Button variant="close" onClick={() => setShow(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Select
							value={downloadType}
							className="mb-3"
							onChange={(e) => setDownloadType(e.target.value)}
						>
							<option value="">Select download type</option>
							<option value="pdf">{"PDF"}</option>
							<option value="csv">{"CSV/Excel"}</option>
						</Form.Select>
					</Form.Group>
					<Form.Group id="timeFrame" className="mb-3">
						<Form.Select
							value={timeFrame}
							className="mb-3"
							onChange={(e) => setTimeFrame(e.target.value)}
						>
							<option value="">Select time frame</option>
							<option value="monthly">{"Monthly"}</option>
							<option value="quarterly">{"Quarterly"}</option>
							<option value="yearly">{"Yearly"}</option>
						</Form.Select>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0">
					<Button variant="outline-gray-500" onClick={() => setShow(false)}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						type="submit"
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						Download
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ReportOptionModal;
