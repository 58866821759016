import httpService from "./httpService";

export const getAnalytics = async () => {
	try {
		const res = await httpService.get("/master/analytics");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

///
export const getUserProgressTracker = async () => {
	let url = `/analytics/user-five-progress`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getUserActivities = async () => {
	let url = `/analytics/user-activities`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getUserPerformance = async () => {
	let url = `/analytics/user-performance`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

///
export const getMemberProgressTracker = async (userId) => {
	let url = `/analytics/member-five-progress/${userId}`;	
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getMemberActivities = async (userId) => {
	let url = `/analytics/member-activities/${userId}`;	
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getMemberPerformance = async (userId) => {
	let url = `/analytics/member-performance/${userId}`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

//////

export const getTeamProgressTracker = async (id) => {
	let url = `/analytics/team-five-progress/${id}`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getTeamActivities = async (id) => {
	let url = `/analytics/team-activities/${id}`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getTeamPerformance = async (id) => {
	let url = `/analytics/team-performance/${id}`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

////

export const getOrgProgressTracker = async () => {
	let url = `/analytics/org-five-progress`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getOrgActivities = async () => {
	let url = `/analytics/org-activities`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getOrgPerformance = async () => {
	let url = `/analytics/org-performance`;
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
