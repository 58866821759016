import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// core styles
import "./assets/css/styles.css";
import "./assets/css/index.css"; 
// vendor styles
import "leaflet/dist/leaflet.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datetime/css/react-datetime.css";
import App from './App';
import ScrollToTop from "./components/ScrollToTop";

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<ScrollToTop />
				<App />
				<ToastContainer />
			</Router>
		</PersistGate>
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
