// Here are more informations about the scroll restoration of React Router
// https://reactrouter.com/web/guides/scroll-restoration

import { useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ScrollToTop() {
	const location = useLocation();
	const navigate = useNavigate();

	useLayoutEffect(() => {
		navigate(location.pathname, { replace: true }); // Update the route without adding a new entry in the history stack
		window.scrollTo({ top: 0, left: 0, behavior: "auto" });
	}, [location.pathname, navigate]);

	return null;
}
