import httpService from "./httpService";

export const createTeam = async (form) => {
	try {
		const response = await httpService.post("/teams/create-team", form);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't create team, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllTeams = async () => {
	try {
		const res = await httpService.get("/teams/all-teams");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllTeamsById = async (teamId) => {
	try {
		const res = await httpService.get(`/teams/all-teams-by-id/${teamId}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getMyTeams = async (userId) => {
	let url = `/teams/my-teams`;
	if (userId) {
		url += `?id=${userId}`;
	}
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getSingleTeam = async (id) => {
	try {
		const res = await httpService.get(`/teams/single-team/${id}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const addTeamMember = async (form) => {
	const { teamId, userId } = form;
	try {
		const response = await httpService.put(`/teams/add-team-member/${teamId}`, {
			userId: userId,
		});
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't add member, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const removeTeamMember = async (form) => {
	const { teamId, userId } = form;
	try {
		const response = await httpService.patch(
			`/teams/remove-team-member/${teamId}`,
			{ userId: userId },
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't remove member, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const deleteTeam = async (id) => {
	try {
		const response = await httpService.delete(`/teams/delete-team/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't delete team, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const updateTeam = async (id, form) => {
	try {
		const response = await httpService.patch(`/teams/update-team/${id}`, form);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't update team, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
