import React, { useState } from "react";

const OtpInput = ({ length, onChange }) => {
	const [otp, setOtp] = useState(Array(length).fill(""));

	const handleChange = (event, index) => {
		const value = event.target.value;
		setOtp((prevOtp) => {
			const newOtp = [...prevOtp];
			newOtp[index] = value;
			return newOtp;
		});
		onChange(otp.join(""));
	};

	const handleKeyDown = (event, index) => {
		if (event.key === "Backspace" && !otp[index] && index > 0) {
			setOtp((prevOtp) => {
				const newOtp = [...prevOtp];
				newOtp[index - 1] = "";
				return newOtp;
			});
			onChange(otp.slice(0, -1).join(""));
		} else if (/^\d$/.test(event.key)) {
			setOtp((prevOtp) => {
				const newOtp = [...prevOtp];
				newOtp[index] = event.key;
				return newOtp;
			});
			onChange(
				[...otp.slice(0, index), event.key, ...otp.slice(index + 1)].join(""),
			);
			if (index < length - 1) {
				document.querySelector(`#otp-${index + 1}`).focus();
			}
		}
	};

	const inputStyle = {
		width: "3rem",
		height: "3rem",
		fontSize: "2rem",
		textAlign: "center",
		marginRight: "1rem",
		border: "1px solid #ccc",
		borderRadius: "0.25rem",
	};

	const otpContainerStyle = {
		display: "flex",
		justifyContent: "center",
		margin: "auto auto",
	};

	return (
		<div style={otpContainerStyle}>
			{Array.from({ length }).map((_, index) => (
				<input
					key={index}
					id={`otp-${index}`}
					type="text"
					inputMode="numeric"
					pattern="[0-9]*"
					value={otp[index]}
					onChange={(event) => handleChange(event, index)}
					onKeyDown={(event) => handleKeyDown(event, index)}
					maxLength="1"
					style={inputStyle}
				/>
			))}
		</div>
	);
};

export default OtpInput;
