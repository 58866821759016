import React from "react";
import moment from "moment-timezone";
import { Col, Row, Card, Form } from "react-bootstrap";

const ProfileInfoForm = (props) => {
	const { user } = props;

	const user_firstname =
		user?.first_name.charAt(0).toUpperCase() + user?.first_name.slice(1);
	const user_lastname =
		user?.last_name.charAt(0).toUpperCase() + user?.last_name.slice(1);

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<h5 className="mb-4">General information</h5>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="firstName">
								<Form.Label>First Name</Form.Label>
								<Form.Control readOnly type="text" value={user_firstname} />
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="lastName">
								<Form.Label>Last Name</Form.Label>
								<Form.Control readOnly type="text" value={user_lastname} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="title">
								<Form.Label>Title</Form.Label>
								<Form.Control readOnly type="text" value={user?.title} />
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="jobTitle">
								<Form.Label>Job Title</Form.Label>
								<Form.Control readOnly type="text" value={user?.job_title} />
							</Form.Group>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col md={6} className="mb-3">
							<Form.Group id="birthday">
								<Form.Label>Birthday</Form.Label>
								<Form.Control
									readOnly
									type="text"
									value={
										user?.dob
											? moment(user?.dob, "YYYY-MM-DD").format("DD [of] MMMM")
											: ""
									}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="gender">
								<Form.Label>Gender</Form.Label>
								<Form.Control readOnly type="text" value={user?.gender} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="email">
								<Form.Label>Email</Form.Label>
								<Form.Control readOnly type="email" value={user?.email} />
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="phone">
								<Form.Label>Phone</Form.Label>
								<Form.Control readOnly type="text" value={user?.phone} />
							</Form.Group>
						</Col>
					</Row>

					<h5 className="my-4">Location</h5>
					<Row>
						<Col sm={9} className="mb-3">
							<Form.Group id="address">
								<Form.Label>Address</Form.Label>
								<Form.Control readOnly type="text" value={user?.address} />
							</Form.Group>
						</Col>
						<Col sm={3} className="mb-3">
							<Form.Group id="addressNumber">
								<Form.Label>House Number</Form.Label>
								<Form.Control readOnly type="text" value={user?.house_number} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={4} className="mb-3">
							<Form.Group id="city">
								<Form.Label>City</Form.Label>
								<Form.Control readOnly type="text" value={user?.city} />
							</Form.Group>
						</Col>
						<Col sm={4} className="mb-3">
							<Form.Group id="state">
								<Form.Label>State</Form.Label>
								<Form.Control readOnly type="text" value={user?.state} />
							</Form.Group>
						</Col>
						<Col sm={4}>
							<Form.Group id="zip">
								<Form.Label>ZIP</Form.Label>
								<Form.Control readOnly type="text" value={user?.zip} />
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	);
};

export default ProfileInfoForm;
