import React, { useState, useCallback, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import { URLs } from "routes";
import { getUserActivities } from "services/analyticsService";

const MemberTaskWidget = (props) => {
	const [taskTotal, setTaskTotal] = useState(0);

	const getData = useCallback(async () => {
		try {
			const { data } = await getUserActivities();
				setTaskTotal(data.totalTasks);
		
		} catch (err) {
			console.error("Error:", err.message);
		}
	}, []);

	useEffect(() => {
		getData();
	}, [getData]);

	
	return (
		<Card border="0" className="shadow">
			<Card.Body>
				<Row className="d-block d-xxl-flex align-items-center">
					<Col xs={12} xxl={6} className="px-xxl-0 mb-3 mb-xxl-0">
						<div
							style={{ width: "100%", height: "140px" }}
							className="mb-xxl-0 d-flex justify-content-center align-items-center"
						>
							<h3 className="fw-extrabold mb-1 fs-1">{taskTotal}</h3>
						</div>
					</Col>
					<Col xs={12} xxl={6} className="ps-xxl-4 pe-xxl-0">
						<h2 className="fs-5 fw-normal mb-1">Total Task</h2>
						<div className="small d-flex mt-1">
							<span className={`text-success fw-bolder me-1`}>{taskTotal}</span> So far
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default MemberTaskWidget;
