import { SET_MESSAGE } from "./messageActions";
import { getAllProjects } from "services/projectService";

export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";
// action creators
export const getProjects = () => async (dispatch) => {
	dispatch({ type: GET_PROJECTS_REQUEST });
	try {
		const { success, message, data } = await getAllProjects();
		if (success === true) {
			dispatch({
				type: GET_PROJECTS_SUCCESS,
				payload: data,
			});
		} else {
			dispatch({ type: GET_PROJECTS_FAIL });
			dispatch({
				type: SET_MESSAGE,
				payload: { text: message, success: success },
			});
		}
	} catch (err) {
		dispatch({ type: GET_PROJECTS_FAIL });
		dispatch({
			type: SET_MESSAGE,
			payload: { text: err.message, success: false },
		});
	}
};

