import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateTeam } from "services/teamService";

const EditTeam = (props) => {
	const {
		setShowEdit,
		showEdit,
		title,
		description,
		teamID,
		setIsUpdated,
		isUpdated,
	} = props;
	const [newDesc, setNewDesc] = useState(description || "");
	const [newTitle, setNewTitle] = useState(title || "");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
			const response = await updateTeam(teamID, {
				name: newTitle,
				description: newDesc,
			});
			const { success, message } = response;
			if (success === true) {
				setShowEdit(false);
				setIsUpdated(!isUpdated);
				setLoading(false);
				toast(message, { type: "success", autoClose: 5000 });
			} else {
				toast(message, { type: "error", autoClose: 5000 });
				setLoading(false);
			}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 5000 });
			setLoading(false);
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			show={showEdit}
			onHide={() => setShowEdit(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Edit Team
					</h5>
					<Button variant="close" onClick={() => setShowEdit(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Control
							autoFocus
							type="text"
							value={newTitle}
							onChange={(e) => setNewTitle(e.target.value)}
						/>
					</Form.Group>

					<Form.Group id="description" className="mb-3">
						<Form.Control
							autoFocus
							multiple
							as="textarea"
							value={newDesc}
							onChange={(e) => setNewDesc(e.target.value)}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0">
					<Button variant="outline-gray-500" onClick={() => setShowEdit(false)}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						Submit and Update
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default EditTeam;
