import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { HomeIcon, SearchIcon } from "@heroicons/react/solid";
import {
	Col,
	Row,
	Form,
	Button,
	Breadcrumb,
	InputGroup,
} from "react-bootstrap";

import MembersTable from "components/MembersTable";
import { useDispatch } from "react-redux";
import {
	revokeUserRole,
	suspendUser,
	unsuspendUser,
	makeMaster,
	makeAdmin,
	getAllUsers,
	activateUser,
	deativateUser,
} from "services/userService";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";
import { realtimeUpdate } from "shared/socket";
import AddMemberModal from "components/AddMember";

const SwalWithBootstrapButtons = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary me-3",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

const DashboardMembers = () => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [members, setMembers] = useState([]);
	const [users, setUsers] = useState(
		members?.map((u) => ({ ...u, isSelected: false, show: true })),
	);

	const getMembers = useCallback(async () => {
		try {
			const { success, data, message } = await getAllUsers();
			if (success === true) {
				setMembers(
					data?.map((u) => ({
						...u,
						isSelected: false,
						show: true,
					})),
				);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, []);

	useEffect(() => {
		getMembers();
	}, [getMembers]);

	useEffect(() => {
		realtimeUpdate.on("team-updated", (data) => {
			if (data) {
				getMembers();
				dispatch(getUser());
			}
		});
	}, [dispatch, getMembers]);

	useEffect(() => {
		// Update the users state based on the members state
		const updatedUsers = members?.map((u) => ({
			...u,
			isSelected: false,
			show: true,
		}));
		setUsers(updatedUsers);
	}, [members]);

	const [searchValue, setSearchValue] = useState("");
	const [statusFilter, setStatusFilter] = useState("all");
	const selectedUsersIds = users.filter((u) => u.isSelected).map((u) => u?._id);
	const totalUsers = users.length;
	const allSelected = selectedUsersIds.length === totalUsers;

	const changeSearchValue = (e) => {
		const newSearchValue = e.target.value;
		const newUsers = users.map((u) => ({
			...u,
			show:
				u.first_name.toLowerCase().includes(newSearchValue.toLowerCase()) ||
				u.last_name.toLowerCase().includes(newSearchValue.toLowerCase()),
		}));

		setSearchValue(newSearchValue);
		setUsers(newUsers);
	};

	const changeStatusFilter = (e) => {
		const newStatusFilter = e.target.value;
		const newUsers = users.map((u) => ({
			...u,
			show: u.status === newStatusFilter || newStatusFilter === "all",
		}));
		setStatusFilter(newStatusFilter);
		setUsers(newUsers);
	};

	const selectAllUsers = () => {
		const newUsers =
			selectedUsersIds.length === totalUsers
				? users.map((u) => ({ ...u, isSelected: false }))
				: users.map((u) => ({ ...u, isSelected: true }));

		setUsers(newUsers);
	};

	const selectUser = (id) => {
		const newUsers = users.map((u) =>
			u?._id === id ? { ...u, isSelected: !u.isSelected } : u,
		);
		setUsers(newUsers);
	};

	const makeUserMaster = async (id) => {
		const textMessage = "Are you sure you want to make this user Master?";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "warning",
			title: "Confirm Role Assignment",
			text: textMessage,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const response = await makeMaster(id);
				if (response.success === true) {
					getMembers();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire("Role", response.message, "success");
				} else {
					await SwalWithBootstrapButtons.fire("Error", response.message, "error");
				}
			} catch (error) {
				await SwalWithBootstrapButtons.fire("Error", error.message, "error");
			}
		}
	};

	const makeUserAdmin = async (id) => {
		const textMessage = "Are you sure you want to make this user Admin?";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "warning",
			title: "Confirm Role Assignment",
			text: textMessage,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const response = await makeAdmin(id);
				if (response.success === true) {
					getMembers();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire("Role", response.message, "success");
				} else {
					await SwalWithBootstrapButtons.fire("Error", response.message, "error");
				}
			} catch (error) {
				await SwalWithBootstrapButtons.fire("Error", error.message, "error");
			}
		}
	};

	const revokeRole = async (id) => {
		const textMessage = "Are you sure you want to revoke user Role";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "warning",
			title: "Confirm Role Revoke",
			text: textMessage,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const response = await revokeUserRole(id);
				if (response.success === true) {
					getMembers();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire("Role", response.message, "success");
				} else {
					await SwalWithBootstrapButtons.fire("Error", response.message, "error");
				}
			} catch (error) {
				await SwalWithBootstrapButtons.fire("Error", error.message, "error");
			}
		}
	};

	const suspendThisUser = async (id) => {
		const textMessage = "Are you sure you want to suspend this user";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "warning",
			title: "Confirm User Suspension",
			text: textMessage,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const response = await suspendUser(id);
				if (response.success === true) {
					getMembers();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire(
						"Suspended",
						response.message,
						"success",
					);
				} else {
					await SwalWithBootstrapButtons.fire("Error", response.message, "error");
				}
			} catch (error) {
				await SwalWithBootstrapButtons.fire("Error", error.message, "error");
			}
		}
	};

	const unsuspendThisUser = async (id) => {
		const textMessage = "Are you sure you want to unsuspend this user";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "warning",
			title: "Confirm User Unsuspension",
			text: textMessage,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const response = await unsuspendUser(id);
				if (response.success === true) {
					getMembers();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire(
						"Unsuspended",
						response.message,
						"success",
					);
				} else {
					await SwalWithBootstrapButtons.fire("Error", response.message, "error");
				}
			} catch (error) {
				await SwalWithBootstrapButtons.fire("Error", error.message, "error");
			}
		}
	};

	const activateThisUser = async (id) => {
		const textMessage = "Are you sure you want to activate this user";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "warning",
			title: "Confirm User Activation",
			text: textMessage,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const response = await activateUser(id);
				if (response.success === true) {
					getMembers();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire(
						"Activated",
						response.message,
						"success",
					);
				} else {
					await SwalWithBootstrapButtons.fire("Error", response.message, "error");
				}
			} catch (error) {
				await SwalWithBootstrapButtons.fire("Error", error.message, "error");
			}
		}
	};

	const deactivateThisUser = async (id) => {
		const textMessage = "Are you sure you want to deactivate this user";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "warning",
			title: "Confirm User Deactivation",
			text: textMessage,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const response = await deativateUser(id);
				if (response.success === true) {
					getMembers();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire(
						"Deactivated",
						response.message,
						"success",
					);
				} else {
					await SwalWithBootstrapButtons.fire("Error", response.message, "error");
				}
			} catch (error) {
				await SwalWithBootstrapButtons.fire("Error", error.message, "error");
			}
		}
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb
						className="d-none d-md-inline-block"
						listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
					>
						<Breadcrumb.Item>
							<HomeIcon className="icon icon-xs" />
						</Breadcrumb.Item>
						<Breadcrumb.Item>Amebo Board</Breadcrumb.Item>
						<Breadcrumb.Item active>Staff Members List</Breadcrumb.Item>
					</Breadcrumb>
					<h4>Staff Member List</h4>
				</div>
				<div className="btn-toolbar mb-2 mb-md-0">
					<Button variant="secondary" size="sm" onClick={() => setShowModal(true)}>
						Add New Member
					</Button>
				</div>
			</div>

			<div className="table-settings mb-4">
				<Row className="justify-content-between align-items-center">
					<Col xs={9} lg={8} className="d-md-flex">
						<InputGroup className="me-2 me-lg-3 fmxw-300">
							<InputGroup.Text>
								<SearchIcon className="icon icon-xs" />
							</InputGroup.Text>
							<Form.Control
								type="text"
								placeholder="Search users"
								value={searchValue}
								onChange={changeSearchValue}
							/>
						</InputGroup>
						<Form.Select
							value={statusFilter}
							className="fmxw-200 d-none d-md-inline"
							onChange={changeStatusFilter}
						>
							<option value="all">All</option>
							<option value="active">Active</option>
							<option value="inactive">Inactive</option>
							<option value="pending">Pending</option>
							<option value="suspended">Suspended</option>
							<option value="unsuspended">Unsuspended</option>
						</Form.Select>
					</Col>
					<Col xs={3} lg={4} className="d-flex justify-content-end"></Col>
				</Row>
			</div>

			<MembersTable
				users={users.filter((u) => u.show)}
				allSelected={allSelected}
				selectUser={selectUser}
				revokeRole={revokeRole}
				suspendUser={suspendThisUser}
				unsuspendUser={unsuspendThisUser}
				activateUser={activateThisUser}
				deactivateUser={deactivateThisUser}
				makeAdmin={makeUserAdmin}
				makeMaster={makeUserMaster}
				selectAllUsers={selectAllUsers}
			/>

			<AddMemberModal
				showModal={showModal}
				setShowModal={setShowModal}
				updateMembers={getMembers}
			/>
		</>
	);
};

export default DashboardMembers;
