import React, { useState, useEffect } from "react";
import {
	BellIcon,
	CogIcon,
	InboxIcon,
	MenuAlt1Icon,
} from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import {
	Row,
	Col,
	Nav,
	Form,
	Image,
	Button,
	Navbar,
	Dropdown,
	Container,
	ListGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { realtimeUpdate } from "shared/socket";
import { URLs } from "routes";
import AvatarPlaceholder from "assets/img/avatar_placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/authActions";
import Moment from "react-moment";
import { getMyNotifications, markAsRead } from "services/notificationService";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";

const Topbar = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);
	const [notifications, setNotifications] = useState([]);
	const [isMarked, setIsMarked] = useState(false);

	const [userData, setUserData] = useState(user);

	useEffect(() => {
		setUserData(user);
	}, [user]);

	const logoutHandler = () => {
		dispatch(logout(navigate));
	};

	const getNotifications = async () => {
		try {
			const { success, data, message } = await getMyNotifications();
			if (success === true) {
				setNotifications(data);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	};

	useEffect(() => {
		getNotifications();

		return () => {
			setIsMarked(false);
		};
	}, [isMarked]);

	useEffect(() => {
		realtimeUpdate.on("notification-updated", (data) => {
			if (data) {
				getNotifications();
				dispatch(getUser());
			}
		});
	}, [dispatch]);

	// Only show indicator if there are unread notifications
	const newNotifications = notifications?.filter((n) => n.read === false);

	const bellIconClasses = newNotifications?.length > 0 ? "unread" : "";

	const markNotificationsAsRead = async (notificationId) => {
		try {
			const { success, message } = await markAsRead(notificationId);
			if (success === true) {
				setIsMarked(true);
			} else {
				console.log(message);
				toast("Could not mark message at the moment", {
					type: "error",
					autoClose: 3000,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	const toggleContracted = () => {
		props.toggleContracted && props.toggleContracted();
	};

	const Notification = (props) => {
		const { createdAt, msg, read, _id } = props;
		const readClassName = read ? "text-success" : "text-danger";
		// const receiveTime = moment(time).fromNow();

		return (
			<ListGroup.Item className="list-group-item-action border-bottom">
				<Row className="align-items-center">
					<Col xs="auto">
						<BellIcon className={`icon icon-sm bell-shake ${readClassName}`} />
					</Col>
					<Col className="ps-0 ms-2">
						<p
							className="font-small mt-1 mb-0"
							style={{
								fontWeight: `${read ? "" : "bold"}`,
							}}
						>
							{msg}
						</p>

						<div className="d-flex justify-content-between align-items-center mt-1">
							<div>
								{read ? (
									""
								) : (
									<Button
										variant="secondary"
										size="xs"
										onClick={() => markNotificationsAsRead(_id)}
									>
										Mark as read
									</Button>
								)}
							</div>
							<div
								className="text-end"
								style={{ textAlign: "right", fontStyle: "italic" }}
							>
								<small className={readClassName}>
									<Moment fromNow>{createdAt}</Moment>
								</small>
							</div>
						</div>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	};

	return (
		<Navbar
			expand
			variant="dark"
			className="navbar-top navbar-dashboard ps-0 pe-2 pb-0"
		>
			<Container fluid className="px-0">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						<Button
							size="lg"
							id="sidebar-toggle"
							variant="icon-only"
							className="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3"
							onClick={toggleContracted}
						>
							<MenuAlt1Icon className="toggle-icon" />
						</Button>
						<Form className="navbar-search form-inline"></Form>
					</div>
					<Nav className="align-items-center">
						<Dropdown as={Nav.Item}>
							<Dropdown.Toggle
								as={Nav.Link}
								className={`text-dark notification-bell ${bellIconClasses} `}
							>
								<BellIcon className="icon icon-sm bell-shake text-gray-900" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu-lg dropdown-menu-center mt-2 py-0">
								<ListGroup className="list-group-flush">
									<Nav.Link
										href="#"
										className="text-center text-primary fw-bold border-bottom border-light py-3"
									>
										Notifications
									</Nav.Link>

									<div
										style={{
											height: "300px",
											overflow: "scroll",
											overflowX: "hidden",
										}}
									>
										{notifications?.map((n) => (
											<Notification key={`notification-${n?._id}`} {...n} />
										))}
									</div>

									<Dropdown.Item className="text-center text-primary fw-bold py-3"></Dropdown.Item>
								</ListGroup>
							</Dropdown.Menu>
						</Dropdown>

						<Dropdown as={Nav.Item} className="ms-lg-3">
							<Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
								<div className="media d-flex align-items-center">
									{userData?.profile_image ? (
										<Image
											src={userData?.profile_image}
											className="avatar rounded-circle"
										/>
									) : (
										<Image src={AvatarPlaceholder} className="avatar rounded-circle" />
									)}{" "}
									<div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
										<span className="mb-0 font-small fw-bold text-gray-900">
											{userData?.first_name &&
												userData?.first_name.charAt(0).toUpperCase() +
													userData?.first_name.slice(1)}{" "}
											{userData?.last_name &&
												userData?.last_name.charAt(0).toUpperCase() +
													userData?.last_name.slice(1)}{" "}
										</span>
									</div>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
								<Dropdown.Item
									as={Link}
									to={URLs.Settings.path}
									className="d-flex align-items-center"
								>
									<CogIcon className="dropdown-icon text-gray-400 me-2" /> Settings
								</Dropdown.Item>
								<Dropdown.Item
									as={Link}
									to={URLs.Messages.path}
									className="d-flex align-items-center"
								>
									<InboxIcon className="dropdown-icon text-gray-400 me-2" /> Messages
								</Dropdown.Item>
								<Dropdown.Divider as="div" className="my-1" />

								<Dropdown.Item
									className="d-flex align-items-center"
									onClick={() => logoutHandler()}
								>
									<LogoutIcon className="dropdown-icon text-danger me-2" /> Logout
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</div>
			</Container>
		</Navbar>
	);
};

export default Topbar;
