import { getAllUsers } from "services/userService";
import { SET_MESSAGE } from "./messageActions";

export const GET_MEMBERS_REQUEST = "GET_MEMBERS_REQUEST";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAIL = "GET_MEMBERS_FAIL";
export const CLEAR_MEMBERS = "CLEAR_MEMBERS";

// action creators
export const getMembers = () => async (dispatch) => {
	dispatch({ type: GET_MEMBERS_REQUEST });
	try {
		const { success, message, data } = await getAllUsers();
		if (success === true) {
			dispatch({
				type: GET_MEMBERS_SUCCESS,
				payload: data,
			});
		} else {
			dispatch({ type: GET_MEMBERS_FAIL });
			dispatch({
				type: SET_MESSAGE,
				payload: { text: message, success: success },
			});
		}
	} catch (err) {
		dispatch({ type: GET_MEMBERS_FAIL });
		dispatch({
			type: SET_MESSAGE,
			payload: { text: err.message, success: false },
		});
	}
};

export const clearMembers = () => (dispatch) => {
	dispatch({ type: CLEAR_MEMBERS });
};
