import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {authReducer} from "redux/reducers/authReducer";
import { userReducer } from "redux/reducers/userReducer";
import { messageReducer } from "redux/reducers/messageReducer";
// import {memberReducer} from "redux/reducers/memberReducer";
// import {projectReducer} from "redux/reducers/projectReducer";
// import {eventReducer} from "redux/reducers/eventReducer";
// import {teamReducer} from "redux/reducers/teamReducer";
// import {myProjectReducer} from "redux/reducers/myProjectReducer";
// import { myTeamReducer } from "redux/reducers/myTeamReducer";
// import { singleProjectReducer } from "redux/reducers/singleProjectReducer";
// import { chatReducer } from "redux/reducers/chatReducer";

const rootReducer = combineReducers({
	authId: authReducer,
	user: userReducer,
	message: messageReducer,
	// members: memberReducer,
	// projects: projectReducer,
	// teams: teamReducer,
	// chats: chatReducer,
	// events: eventReducer,
	// myProjects: myProjectReducer,
	// myTeams: myTeamReducer,
	// singleProject: singleProjectReducer,
});

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	whiteList: ["authId", "user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [ReduxThunk];

export const store = createStore(
	persistedReducer,
	composeWithDevTools(applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store);
