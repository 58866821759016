import { HomeIcon } from "@heroicons/react/solid";
import { FolderOpenIcon } from "@heroicons/react/outline";

import React, { useState } from "react";
import { Card, Breadcrumb, Tooltip, OverlayTrigger } from "react-bootstrap";
import RepositoryViewModal from "components/RepositoryViewModal";

const Repositories = () => {
	const [showModal, setShowModal] = useState(false);
	const [repoType, setRepoType] = useState("");

	const modalTrigger = (type) => {
		setRepoType(type);
		setShowModal(true);
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb
						className="d-none d-md-inline-block"
						listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
					>
						<Breadcrumb.Item>
							<HomeIcon className="icon icon-xs" />
						</Breadcrumb.Item>
						<Breadcrumb.Item>Amebo Board</Breadcrumb.Item>
						<Breadcrumb.Item active>Repositories</Breadcrumb.Item>
					</Breadcrumb>
					<h4>Repositories</h4>
				</div>
				<div className="btn-toolbar mb-2 mb-md-0"></div>
			</div>
			<div className="mt-5 d-flex flex-wrap flex-column">
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip className="m-0">Click to open</Tooltip>}
				>
					<Card
						border="0"
						className="shadow me-3 mb-3 p-2 "
						style={{ width: "200px", cursor: "pointer" }}
						onClick={() => modalTrigger("done")}
					>
						<p className="fs-5 mb-0">
							<FolderOpenIcon className="h-6 w-6 icon icon-md" /> &nbsp; Done
						</p>
					</Card>
				</OverlayTrigger>
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip className="m-0">Click to open</Tooltip>}
				>
					<Card
						border="0"
						className="shadow me-3 mb-3 p-2"
						style={{ width: "200px", cursor: "pointer" }}
						onClick={() => modalTrigger("suspended")}
					>
						<p className="fs-5 mb-0">
							<FolderOpenIcon className="h-6 w-6 icon icon-md" />
							&nbsp; Suspended
						</p>
					</Card>
				</OverlayTrigger>
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip className="m-0">Click to open</Tooltip>}
				>
					<Card
						border="0"
						className="shadow me-3 mb-3 p-2"
						style={{ width: "200px", cursor: "pointer" }}
						onClick={() => modalTrigger("archived")}
					>
						<p className="fs-5 mb-0">
							<FolderOpenIcon className="h-6 w-6 icon icon-md" />
							&nbsp; Archived
						</p>
					</Card>
				</OverlayTrigger>
			</div>

			<RepositoryViewModal
				repoType={repoType}
				showModal={showModal}
				setShowModal={setShowModal}
			/>
		</>
	);
};

export default Repositories;
