import { CalendarIcon, PlusIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import SelectTeamModal from "./SelectTeamModal";
import { createProject } from "services/projectService";
import { toast } from "react-toastify";

const CreateProjectModal = (props) => {
	const { showModal, setShowModal } = props;
	const [projectName, setProjectName] = useState("");
	const [projectDescription, setProjectDescription] = useState("");
	const [dueDate, setDueDate] = useState(new Date());
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [showTeamModal, setShowTeamModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const isDateInPast = (currentDate) => {
		const now = new Date();
		return currentDate < now;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		// Check if all required fields are filled out
		if (projectName === "") {
			toast("Please fill in a project name.", { type: "error", autoClose: 2000 });
			setLoading(false);
			return;
		}

		if (selectedTeam?._id === "") {
			toast("Please select a team", { type: "error", autoClose: 2000 });
			setLoading(false);
			return;
		}

		// Check if the due_date is a valid ISO string
		if (!moment(dueDate.toISOString(), moment.ISO_8601, true).isValid()) {
			toast("Invalid due date.", { type: "error", autoClose: 2000 });
			setLoading(false);
			return;
		}
		try {
			const response = await createProject({
				name: projectName,
				description: projectDescription,
				team: selectedTeam?._id,
				due_date: dueDate.toISOString(),
			});
			const { success, message } = response;
			if (success === true) {
				toast(message, { type: "success", autoClose: 2000 });
				props?.updateProject();
				setLoading(false);
				setTimeout(() => {
					setShowModal(false);
					setProjectDescription("");
					setProjectName("");
					setDueDate("");
					setSelectedTeam(null);
				}, 2000); // Wait for 2 second before closing the modal
			} else {
				toast(message, { type: "error", autoClose: 2000 });
				setLoading(false);
				setTimeout(() => {
					setShowModal(false);
				}, 1500); // Wait for 1.5 second before closing the modal
			}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setLoading(false);
			setTimeout(() => {
				setShowModal(false);
			}, 1500); // Wait for 1.5 second before closing the modal
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Create a New Project
					</h5>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Control
							autoFocus
							type="text"
							value={projectName}
							onChange={(e) => setProjectName(e.target.value)}
							placeholder={`Enter a title for this`}
						/>
					</Form.Group>

					<Form.Group id="description" className="mb-3">
						<Form.Control
							required
							autoFocus
							multiple
							as="textarea"
							value={projectDescription}
							onChange={(e) => setProjectDescription(e.target.value)}
							placeholder={`Enter a description for this`}
						/>
					</Form.Group>

					<Form.Group id="endDate" className="mb-2">
						<Form.Label>Select Due Date</Form.Label>
						<Datetime
							timeFormat={false}
							value={dueDate}
							onChange={setDueDate}
							isValidDate={(currentDate) => !isDateInPast(currentDate)}
							renderInput={(props, openCalendar) => (
								<InputGroup>
									<InputGroup.Text>
										<CalendarIcon className="icon icon-xs" />
									</InputGroup.Text>
									<Form.Control
										type="text"
										placeholder="YYYY-MM-DD"
										value={moment(dueDate).format("YYYY-MM-DD")}
										onFocus={openCalendar}
										onChange={() => {}}
									/>
								</InputGroup>
							)}
						/>
					</Form.Group>
					<Form.Group className="mt-3">
						<Form.Label>Select team:</Form.Label>
						<InputGroup>
							<Form.Control
								type="text"
								value={selectedTeam?.name || ""}
								placeholder="Select a team"
								onClick={() => setShowTeamModal(true)}
								readOnly
							/>
							<Button
								variant="outline-secondary"
								onClick={() => setShowTeamModal(true)}
							>
								Select
							</Button>
						</InputGroup>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0 mt-3">
					<Button variant="outline-gray-500" onClick={() => setShowModal(false)}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						type="submit"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						<PlusIcon className="icon icon-xs me-2" />
						Create Project
					</Button>
				</Modal.Footer>
			</Form>
			<SelectTeamModal
				showTeamModal={showTeamModal}
				setShowTeamModal={setShowTeamModal}
				setSelectedTeam={setSelectedTeam}
			/>
		</Modal>
	);
};

export default CreateProjectModal;
