import { PlusIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";

import SelectTeamModal from "./SelectTeamModal";
import { addTeamToProject } from "services/projectService";
import { toast } from "react-toastify";

const AddTeamToProjectModal = (props) => {
	const { showModal, setShowModal } = props;
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [showTeamModal, setShowTeamModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		if (selectedTeam?._id === "") {
			toast("Please select a team", { type: "error", autoClose: 2000 });
			setLoading(false);
			return;
		}

		try {
			const response = await addTeamToProject(props._id, {
				team: selectedTeam?._id,
			});
			const { success, message } = response;
			if (success === true) {
				toast(message, { type: "success", autoClose: 2000 });
				props?.getProjects();
				setLoading(false);
				setTimeout(() => {
					setShowModal(false);
					setSelectedTeam(null);
				}, 2000); // Wait for 2 second before closing the modal
			} else {
				toast(message, { type: "error", autoClose: 2000 });
				setLoading(false);
				setTimeout(() => {
					setShowModal(false);
				}, 1500); // Wait for 1.5 second before closing the modal
			}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setLoading(false);
			setTimeout(() => {
				setShowModal(false);
			}, 1500); // Wait for 1.5 second before closing the modal
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Add Team to Project: {props.name}
					</h5>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group className="mt-3">
						<Form.Label>Select team:</Form.Label>
						<InputGroup>
							<Form.Control
								type="text"
								value={selectedTeam?.name || ""}
								placeholder="Select a team to add to this project"
								onClick={() => setShowTeamModal(true)}
								readOnly
							/>
							<Button
								variant="outline-secondary"
								onClick={() => setShowTeamModal(true)}
							>
								Select
							</Button>
						</InputGroup>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0 mt-3">
					<Button variant="outline-gray-500" onClick={() => setShowModal(false)}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						type="submit"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						<PlusIcon className="icon icon-xs me-2" />
						Add Team
					</Button>
				</Modal.Footer>
			</Form>
			<SelectTeamModal
				showTeamModal={showTeamModal}
				setShowTeamModal={setShowTeamModal}
				setSelectedTeam={setSelectedTeam}
			/>
		</Modal>
	);
};

export default AddTeamToProjectModal;
