import React from "react";
import { Card } from "react-bootstrap";

const SettingsUserCard = (props) => {

	const { fullName, picture, coverPhoto, jobTitle, location } = props;

	return (
		<Card border="0" className="shadow text-center p-0">
			<div
				style={{ backgroundImage: `url(${coverPhoto})` }}
				className="profile-cover rounded-top"
			/>
			<Card.Body className="pb-5">
				<Card.Img
					src={picture}
					className="avatar-xl rounded-circle mx-auto mt-n7 mb-4"
				/>
				<Card.Title>{fullName}</Card.Title>
				<Card.Subtitle className="fw-normal">{jobTitle}</Card.Subtitle>
				<Card.Text className="text-gray mb-4">{location}</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default SettingsUserCard;