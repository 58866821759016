import { io } from "socket.io-client";
import { socketUrl } from "./url";

// Function to establish the initial WebSocket connection
function connectSocket() {
	return io(socketUrl, {
		extraHeaders: {
			"Content-Type": "application/json",
		},
		transports: ["websocket"],
		autoConnect: true,
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 5000,
		reconnectionAttempts: 3,

	});
}

// Establish WebSocket connection
let socket = connectSocket();

// Event listener for connection errors
socket.on("connect_error", (err) => {
	console.log(`Connection Error due to: ${err.message}`);	
});

// Define a separate socket for realtime updates if needed
const realtimeUpdate = io("/realtime-update");

export { socket, realtimeUpdate };
