import {
	ChatAlt2Icon,
	DotsHorizontalIcon,
	EyeIcon,
} from "@heroicons/react/solid";
import { Button, Dropdown } from "react-bootstrap";
// import React, { useState } from "react";

import { Col, Card, Image, Row, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { URLs } from "routes";

const capitalizeFirstLetter = (string) =>
	string[0].toUpperCase() + string.slice(1);

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

const MessageCard = (props) => {
	const { chatData, showActiveChatUI } = props;
	const navigate = useNavigate();

	const name = `${chatData?.first_name
		.charAt(0)
		.toUpperCase()}${chatData?.first_name.slice(1)} ${chatData?.last_name
		.charAt(0)
		.toUpperCase()}${chatData?.last_name.slice(1)}`;

	return (
		<>
			{showActiveChatUI ? (
				<Card
					border="bottom"
					className="hover-state rounded-0 rounded-top py-3"
					onClick={() => navigate(`${URLs.Message.path + "/" + chatData?._id}`)}
				>
					<Card.Body className="d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
						<Row className="kanban-card-member py-2 align-items-center">
							<Col xs="auto">
								{chatData?.profile_image ? (
									<Image
										src={chatData?.profile_image}
										className="avatar-md rounded-circle"
									/>
								) : (
									<div className="avatar-md rounded-circle d-flex align-items-center justify-content-center fw-bold bg-secondary">
										<span>{getInitialsOfFirstTwoWords(name)}</span>
									</div>
								)}
							</Col>
							<Col>
								<h4 className="fs-6 text-dark mb-0">{capitalizeFirstLetter(name)}</h4>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			) : (
				<Card border="bottom" className="hover-state rounded-0 rounded-top py-3">
					<Card.Body className="d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
						<Row className="kanban-card-member py-2 align-items-center">
							<Col xs="auto">
								{chatData?.profile_image ? (
									<Image
										src={chatData?.profile_image}
										className="avatar-md rounded-circle"
									/>
								) : (
									<div className="avatar-md rounded-circle d-flex align-items-center justify-content-center fw-bold bg-secondary">
										<span>{getInitialsOfFirstTwoWords(name)}</span>
									</div>
								)}
							</Col>
							<Col>
								<h4 className="fs-6 text-dark mb-0">{capitalizeFirstLetter(name)}</h4>
							</Col>
						</Row>
						<Dropdown as={ButtonGroup} className="ms-auto">
							<Dropdown.Toggle
								as={Button}
								split
								variant="link"
								className="text-dark m-0 p-0"
							>
								<DotsHorizontalIcon className="icon icon-xs" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
								<Dropdown.Item
									className="d-flex align-items-center"
									onClick={() => navigate(`${URLs.Message.path + "/" + chatData?._id}`)}
								>
									<ChatAlt2Icon className="dropdown-icon text-gray-400 me-2" />
									Send Message
								</Dropdown.Item>
								<Dropdown.Item
									as={Link}
									to={URLs.Profile.path + "/" + chatData?._id}
									className="d-flex align-items-center"
								>
									<EyeIcon className="dropdown-icon text-gray-400 me-2" />
									View Details
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Card.Body>
				</Card>
			)}
		</>
	);
};

export default MessageCard;
