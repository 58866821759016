import React, { useState, useCallback, useEffect } from "react";

import { Col, Row, Card } from "react-bootstrap";
import { getOrgActivities } from "services/analyticsService";

const OrgProjectWidget = () => {
	const [projectTotal, setProjectTotal] = useState(0);
	
	const getData = useCallback(async () => {
		try {
			const { success, message, data } = await getOrgActivities();
			if (success === true) {
				setProjectTotal(data.numberOfProjects);
			} else {
				console.log("SERVER_ERROR:", message);
			}
		} catch (err) {
			console.error("Error:", err.message);
		}
	}, []);

	useEffect(() => {
		getData();
	}, [getData]);

	
	return (
		<Card border="0" className="shadow">
			<Card.Body>
				<Row className="d-block d-xxl-flex align-items-center">
					<Col xs={12} xxl={6} className="px-xxl-0 mb-3 mb-xxl-0">
						<div
							style={{ width: "100%", height: "140px" }}
							className="mb-xxl-0 d-flex justify-content-center align-items-center"
						>
							<h3 className="fw-extrabold mb-1 fs-1">{projectTotal}</h3>
						</div>
					</Col>
					<Col xs={12} xxl={6} className="ps-xxl-4 pe-xxl-0">
						<h2 className="fs-5 fw-normal mb-1">Total Project</h2>
						<div className="small d-flex mt-1">
							<span className={`text-success fw-bolder me-1`}>{projectTotal}</span> So
							far
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default OrgProjectWidget;
