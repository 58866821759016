import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HomeIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getUser } from "redux/actions/userActions";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";
import TeamPerformanceValueWidget from "components/TeamPerformanceWidget";
import TeamActivityWidget from "components/TeamActivitWidget";
import TeamProjectWidget from "components/TeamProjectWidget";
import TeamTaskWidget from "components/TeamTaskWidget";
import TeamFiveTeamMembers from "components/TeamFiveTeamMembers";
import TeamProgressTrack from "components/TeamProgressTrack";
import { getSingleTeam } from "services/teamService";

const TeamStats = () => {
	const dispatch = useDispatch();
	const { teamId } = useParams();

	const { authId } = useSelector((state) => state.authId);
	const { message } = useSelector((state) => state.message);
	const [isMounted, setIsMounted] = useState(false);
	const [teamName, setTeamName] = useState("__");

	const getTeamName = useCallback(async () => {
		const response = await getSingleTeam(teamId);
		const { data } = response;
		setTeamName(data.name);
	}, [teamId]);

	useEffect(() => {
		getTeamName();
	}, [getTeamName]);

	useEffect(() => {
		setIsMounted(true);
		dispatch({ type: CLEAR_MESSAGE });
	}, [dispatch]);

	useEffect(() => {
		if (authId) {
			dispatch(getUser());
		}
	}, [dispatch, authId]);

	useEffect(() => {
		if (!isMounted) {
			return; // Skip displaying toasts on initial mount
		}

		if (message?.success === true) {
			toast(message?.text, { type: "success", autoClose: 3000 });
		}

		if (message?.success === false) {
			console.log("from Home: line 49", message);
			toast(message?.text, { type: "error", autoClose: 3000 });
		}
	}, [isMounted, message]);

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb
						className="d-none d-md-inline-block"
						listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
					>
						<Breadcrumb.Item>
							<HomeIcon className="icon icon-xs" />
						</Breadcrumb.Item>
						<Breadcrumb.Item>Amebo Board</Breadcrumb.Item>
						<Breadcrumb.Item active></Breadcrumb.Item>
					</Breadcrumb>
					<h4>{teamName}</h4>
				</div>
			</div>{" "}
			<div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
				<Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
					<div className="me-lg-3"></div>
				</Col>
				<div></div>
			</div>
			<Row className="justify-content-lg-center">
				<Col xs={12} className="mb-4">
					<TeamPerformanceValueWidget teamId={teamId} />
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={6} xl={4} className="mb-4">
					<TeamActivityWidget teamId={teamId} />
				</Col>
				<Col xs={12} sm={6} xl={4} className="mb-4">
					<TeamProjectWidget teamId={teamId} />
				</Col>

				<Col xs={12} sm={12} xl={4} className="mb-4">
					<TeamTaskWidget teamId={teamId} />
				</Col>
			</Row>
			<Row>
				<Col xs={12} xxl={6} className="mb-4">
					<TeamFiveTeamMembers teamId={teamId} />
				</Col>

				<Col xs={12} xxl={6} className="mb-4">
					<TeamProgressTrack teamId={teamId} />
				</Col>
			</Row>
		</>
	);
};

export default TeamStats;
