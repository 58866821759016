
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from 'react-bootstrap';

const Footer = (props) => {
  const currentYear = moment().get("year");

  return (
			<footer className="bg-white rounded shadow p-5 mb-4 mt-4">
				<Row className="justify-content-center">
					<Col xs={12} md={4} xl={6} className="mb-4 mb-md-0">
						<p className="mb-0 text-center text-lg-start">
							© 2022-{`${currentYear} `}
							<Card.Link href="/" target="_blank" className="text-primary fw-normal">
								Amebo Board
							</Card.Link>
						</p>
					</Col>
					{/* <Col xs={12} md={8} xl={6} className="text-center text-lg-start">
						<ul className="list-inline list-group-flush list-group-borderless text-md-end mb-0">
							<li className="list-inline-item px-0 px-sm-2">
								<Card.Link href="/about" target="_blank">
									About
								</Card.Link>
							</li>
							<li className="list-inline-item px-0 px-sm-2">
								<Card.Link href="/blog" target="_blank">
									Blog
								</Card.Link>
							</li>
							<li className="list-inline-item px-0 px-sm-2">
								<Card.Link href="/contact" target="_blank">
									Contact
								</Card.Link>
							</li>
						</ul>
					</Col> */}
				</Row>
			</footer>
		);
};

export default Footer;