import React, { useCallback, useEffect, useState } from "react";
import {
	ClockIcon,
	CreditCardIcon,
	PencilAltIcon,
	SelectorIcon,
	TagIcon,
	TrashIcon,
	UserIcon,
	PaperClipIcon,
} from "@heroicons/react/solid";
import { Card, Button, Dropdown } from "react-bootstrap";

import KanbanAvatar from "components/KanbanAvatar";
import { getSingleTask } from "services/taskService";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { makeTextClickable } from "utils";

const KanbanCard = (props) => {
	const {
		cardRef,
		title,
		description,
		style = {},
		extraProps = {},
		isUpdated,
		queryId,
	} = props;
	const [task, setTask] = useState(null);
	const [assignees, setAssignees] = useState(task?.assignees || []);
	const [taskData, setTaskData] = useState(task);

	const taskId = props?.id;
	const getAssignees = useCallback(async () => {
		try {
			const { success, message, data } = await getSingleTask(taskId);
			if (success === true) {
				setTask(data);
			} else {
				toast(message, { type: "error", autoClose: 5000 });
			}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 5000 });
		}
	}, [taskId]);

	useEffect(() => {
		getAssignees();
	}, [getAssignees, taskId, isUpdated]);

	useEffect(() => {
		setTaskData(task);
		setAssignees(task?.assignees);
	}, [task, isUpdated]);

	const membersCountDescription = !assignees?.length
		? "Unassigned"
		: assignees?.length === 1
		? "1 Assignee"
		: `${assignees?.length} Assignees`;

	const onEdit = () => {
		props.onEdit && props.onEdit();
	};


	const autoOpen = useCallback(() => {
		if (queryId === taskId){
			props.autoOpen()
		}
	}, [props, queryId, taskId])

	useEffect(() => {
		autoOpen()
	}, [])

	const onMove = () => {
		props.onMove && props.onMove();
	};

	const onChangeLabels = () => {
		props.onChangeLabels && props.onChangeLabels();
	};

	const onChangeKpis = () => {
		props.onChangeKpis && props.onChangeKpis();
	};

	const onChangeMembers = () => {
		props.onChangeMembers && props.onChangeMembers();
	};

	const onDelete = () => {
		props.onDelete && props.onDelete();
	};

	const onCardClick = (e) => {
		if (e.defaultPrevented) return;

		props.onClick && props.onClick();
	};

	const onDropdownClick = (e) => {
		e.preventDefault();
	};

	function getCardColor(status) {
		switch (status) {
			case "todo":
				return "#f8d7da";
			case "inprogress":
				return "#d4edda";
			case "inreview":
				return "#fff3cd";
			case "done":
				return "#d1ecf1";
			default:
				return "#fff";
		}
	}
	const isDue = taskData?.due_date && new Date(taskData?.due_date) < new Date();
	const isNotDone = taskData?.status !== "done";

	return (
		<Card
			border={1}
			className="p-1"
			ref={cardRef}
			{...extraProps}
			style={{
				...style,
				// width: "350px",
				backgroundColor:
					isDue && isNotDone ? "#ff0000" : getCardColor(taskData?.status),
			}}
			onClick={onCardClick}
		>
			<Card.Header className="d-flex align-items-center justify-content-between border-0 p-0 mb-3">
				<div>
					<span style={{ fontSize: "0.8rem" }}>
						<ClockIcon
							className="icon icon-xs text-gray-500 me-1"
							style={{ fontSize: "0.8rem" }}
						/>
						Updated at: {moment(props?.updatedAt).format("MMMM Do YYYY")}
					</span>
				</div>

				<div>
					<Dropdown onClick={onDropdownClick}>
						<Dropdown.Toggle
							as={Button}
							variant="link"
							size="sm"
							className="fs-6 px-1 py-0"
						>
							<PencilAltIcon
								className="icon icon-xs text-gray-500"
								style={{ fontSize: "0.8rem" }}
							/>
						</Dropdown.Toggle>

						<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
							<Dropdown.Item className="d-flex align-items-center" onClick={onEdit}>
								<CreditCardIcon className="icon icon-xs text-gray-400 me-2" /> Open Card
							</Dropdown.Item>

							<Dropdown.Item className="d-flex align-items-center" onClick={onMove}>
								<SelectorIcon className="icon icon-xs text-gray-400 me-2" /> Move Card
							</Dropdown.Item>
							<Dropdown.Item
								className="d-flex align-items-center"
								onClick={onChangeKpis}
							>
								<PaperClipIcon className="icon icon-xs text-gray-400 me-2" /> Set KPI
							</Dropdown.Item>
							<Dropdown.Item
								className="d-flex align-items-center"
								onClick={onChangeLabels}
							>
								<TagIcon className="icon icon-xs text-gray-400 me-2" /> Set priority
							</Dropdown.Item>
							<Dropdown.Item
								className="d-flex align-items-center"
								onClick={onChangeMembers}
							>
								<UserIcon className="icon icon-xs text-gray-400 me-2" /> Add assignees
							</Dropdown.Item>

							{/* <Dropdown.Divider as="div" className="my-1" /> */}

							<Dropdown.Item className="d-flex align-items-center" onClick={onDelete}>
								<TrashIcon className="icon icon-xs text-danger me-2" /> Remove
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</Card.Header>
			<Card.Body className="p-2">
				<h5
					className="mb-0"
					style={{
						wordWrap: "break-word",
						wordBreak: "break-word",
						fontSize: "1.125em",
					}}
				>
					{makeTextClickable(title)}
				</h5>
				<p
					style={{
						wordWrap: "break-word",
						wordBreak: "break-word",
						fontSize: "1.125em",
					}}
				>
					{makeTextClickable(description)}
				</p>

				<span style={{ fontSize: "0.8rem" }}>{membersCountDescription}</span>
				<div className="avatar-group d-flex flex-row">
					{assignees?.map((member) => (
						<KanbanAvatar key={`card-member-${member?._id}`} {...member} />
					))}
				</div>
			</Card.Body>
		</Card>
	);
};

export default KanbanCard;
