import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HomeIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";
import OrgPerformanceValueWidget from "components/OrgPerformanceWidget";
import OrgActivityWidget from "components/OrgActivitWidget";
import OrgProjectWidget from "components/OrgProjectWidget";
import OrgTaskWidget from "components/OrgTaskWidget";
import OrgFiveTeamMembers from "components/OrgFiveTeamMembers";
import OrgProgressTrack from "components/OrgProgressTrack";
import Timeline from "components/Timeline";
import ReportOptionModal from "components/ReportOptionModal";

const OrgStats = () => {
	const dispatch = useDispatch();
	const { authId } = useSelector((state) => state.authId);
	const { message } = useSelector((state) => state.message);
	const [isMounted, setIsMounted] = useState(false);
	const [show, setShow] = useState(false);

	useEffect(() => {
		setIsMounted(true);
		dispatch({ type: CLEAR_MESSAGE });
	}, [dispatch]);

	useEffect(() => {
		if (authId) {
			dispatch(getUser());
		}
	}, [dispatch, authId]);

	useEffect(() => {
		if (!isMounted) {
			return; // Skip displaying toasts on initial mount
		}

		if (message?.success === true) {
			toast(message?.text, { type: "success", autoClose: 3000 });
		}

		if (message?.success === false) {
			toast(message?.text, { type: "error", autoClose: 3000 });
		}
	}, [isMounted, message]);

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb
						className="d-none d-md-inline-block"
						listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
					>
						<Breadcrumb.Item>
							<HomeIcon className="icon icon-xs" />
						</Breadcrumb.Item>
						<Breadcrumb.Item>Amebo Board</Breadcrumb.Item>
						<Breadcrumb.Item active></Breadcrumb.Item>
					</Breadcrumb>
				</div>
			</div>{" "}
			<div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
				<Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
					<div className="me-lg-3">
						<h4>{"Organisation"}</h4>
					</div>
				</Col>
				<ButtonGroup className="ms-auto">
					<Button variant="secondary" onClick={() => setShow(!show)}>
						Download Report
					</Button>
				</ButtonGroup>
			</div>
			<Row className="justify-content-lg-center">
				<Col xs={12} className="mb-4">
					<OrgPerformanceValueWidget />
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={6} xl={4} className="mb-4">
					<OrgActivityWidget />
				</Col>
				<Col xs={12} sm={6} xl={4} className="mb-4">
					<OrgProjectWidget />
				</Col>

				<Col xs={12} sm={12} xl={4} className="mb-4">
					<OrgTaskWidget />
				</Col>
			</Row>
			<Row>
				<Col xs={12} xxl={4} className="mb-4">
					<OrgFiveTeamMembers />
				</Col>

				<Col xs={12} xxl={4} className="mb-4">
					<OrgProgressTrack />
				</Col>

				<Col xs={12} xxl={4} className="mb-4">
					<Timeline />
				</Col>
			</Row>
			<ReportOptionModal setShow={setShow} show={show} />
		</>
	);
};

export default OrgStats;
