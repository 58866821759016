import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/messageActions";

const INITIAL_STATE = {
	message: null,
};

export const messageReducer = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_MESSAGE:
			return {
				...state,
				message: payload,
			};
		case CLEAR_MESSAGE:
			return {
				...state,
				message: null
			};
		default:
			break;
	}
	return state;
};
