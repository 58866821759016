import React, { useEffect } from "react";
import { Card, Form, ListGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";
import { getUser } from "redux/actions/userActions";
import { accountActivitySetting, newsSetting } from "services/settingService";

const NotificationSettings = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);
	const { message } = useSelector((state) => state.message);

	const toggleNewsNotification = () => {
		newsSetting()
			.then((res) => {
				if (res.success === true) {
					dispatch(getUser());
				} else {
					toast(res.message, { type: "error", autoClose: 5000 });
				}
			})
			.catch((e) => {
				toast(e.message, { type: "error", autoClose: 5000 });
			});
	};

	const toggleActivityNotification = () => {
		accountActivitySetting()
			.then((res) => {
				if (res.success === true) {
					dispatch(getUser());
				} else {
					toast(res.message, { type: "error", autoClose: 5000 });
				}
			})
			.catch((e) => {
				toast(e.message, { type: "error", autoClose: 5000 });
			});
	};

	const handleNewsToggle = () => {
		toggleNewsNotification();
	};
	const handleActivityToggle = () => {
		toggleActivityNotification();
	};

	useEffect(() => {
		if (message) {
			const timeout = setTimeout(() => {
				dispatch({ type: CLEAR_MESSAGE });
			}, 3000);

			return () => clearTimeout(timeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message]);

	return (
		<Card border="0" className="shadow mb-4 mb-xl-0">
			<Card.Body>
				<h5 className="mb-4">Alerts &amp; Notifications</h5>

				<ListGroup className="list-group-flush">
					<ListGroup.Item
						className={`d-flex align-items-center justify-content-between px-0 border-bottom`}
					>
						<div>
							<Card.Text className="h6 mb-1">Company News</Card.Text>
							<p className="small pe-4">News and announcements updates</p>
						</div>
						<div>
							<Form.Check className="form-switch">
								<Form.Check.Input
									defaultChecked={user?.company_news === "on" ? true : false}
									onChange={handleNewsToggle}
									type="checkbox"
								/>
							</Form.Check>
						</div>
					</ListGroup.Item>
					<ListGroup.Item
						className={`d-flex align-items-center justify-content-between px-0`}
					>
						<div>
							<Card.Text className="h6 mb-1">Account Activity</Card.Text>
							<p className="small pe-4">
								Get notifications about you or activity you've missed
							</p>
						</div>
						<div>
							<Form.Check className="form-switch">
								<Form.Check.Input
									defaultChecked={user?.account_activities === "on" ? true : false}
									onChange={handleActivityToggle}
									type="checkbox"
								/>
							</Form.Check>
						</div>
					</ListGroup.Item>
				</ListGroup>
			</Card.Body>
		</Card>
	);
};

export default NotificationSettings;