import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Button, Spinner } from "react-bootstrap";
import { changePassword } from "services/authService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";

const PasswordChangeForm = () => {
	const dispatch = useDispatch();
	const { message } = useSelector((state) => state.message);
	const [loading, setLoading] = useState(false);

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	
	const userData = {
		old_password: oldPassword,
		new_password: newPassword,
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!oldPassword || !newPassword) {
			toast("Please fill in password fields.", { type: "error", autoClose: 5000 });
			return;
		}
		setLoading(true);

		try {
			const response = await changePassword(userData);
		if (response.success === true) {
			setLoading(false);
			dispatch(getUser());
			toast(response.message, { type: "success", autoClose: 5000 });
		} else {
			toast(response.message, { type: "error", autoClose: 5000 });
			setLoading(false);
		}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 5000 });
			setLoading(false);
		}
	};

		useEffect(() => {
			if (message) {
				const timeout = setTimeout(() => {
					dispatch({ type: CLEAR_MESSAGE });
				}, 5000);

				return () => clearTimeout(timeout);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [message]);

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<h5 className="my-4">Change Password</h5>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="email">
								<Form.Label>Old Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="••••••••"
									style={{ font: "16px/24px monospace" }}
									value={oldPassword}
									onChange={(e) => setOldPassword(e.target.value)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="phone">
								<Form.Label>New Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="••••••••"
									style={{ font: "16px/24px monospace" }}
									value={newPassword}
									onChange={(e) => setNewPassword(e.target.value)}
								/>{" "}
							</Form.Group>
						</Col>
					</Row>
					<div className="mt-3">
						<Button
							variant="gray-800"
							type="submit"
							className="mt-2 animate-up-2"
							onClick={handleSubmit}
							disabled={newPassword && loading}
						>
							{newPassword && loading ? (
								<Spinner animation="border" size="sm" className="mr-2" />
							) : null}
							Submit & Change
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export default PasswordChangeForm;