// // export const serverUrl = process.env.REACT_APP_API_URL;
// // export const serverUrl = "http://localhost:5150";

let serverUrl;
let socketUrl;

if (process.env.NODE_ENV === "development") {
	serverUrl = "http://localhost:5150";
	socketUrl = "http://localhost:5150";
}

if (process.env.NODE_ENV === "production") {
// amebo-api.onrender.com
// amebo-api.vercel.app
// serverUrl = "https://amebo-api.onrender.com";
serverUrl = "https://amebo-api.vercel.app"; 
socketUrl = "wss://amebo-api.onrender.com";
}

const baseURL = `${serverUrl}/api/v2`;

export { serverUrl, baseURL, socketUrl };
