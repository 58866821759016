import React, { useState, useCallback, useEffect } from "react";
import { CalendarIcon } from "@heroicons/react/solid";
import { Col, Row, Card } from "react-bootstrap";
import ApexChart from "react-apexcharts";
// import { Link, useNavigate } from "react-router-dom";
// import { URLs } from "routes";
import { getUserActivities } from "services/analyticsService";

const MemberActivityWidget = (props) => {
	const [activityData, setActivityData] = useState([]);
	const [dataLabels, setDataLabels] = useState([]);
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	const getData = useCallback(async () => {
		try {
			const { data } = await getUserActivities();
				setActivityData(data.activities.data);
				setDataLabels(data.activities.labels);
				setFromDate(data.activities.labels[0]);
				setToDate(data.activities.labels[data.activities.labels.length - 1]);
			
		} catch (err) {
			console.error("Error:", err.message);
		}
	}, []);

	useEffect(() => {
		getData();
	}, [getData]);

	const chartSeries = [
		{
			name: "Activity",
			data: activityData,
		},
	];

	const chartOptions = {
		chart: {
			sparkline: {
				enabled: true,
			},
		},
		theme: {
			monochrome: {
				enabled: true,
				color: "#31316A",
			},
		},
		plotOptions: {
			bar: {
				columnWidth: "25%",
				borderRadius: 5,
				radiusOnLastStackedBar: true,
				colors: {
					backgroundBarColors: ["#F2F4F6", "#F2F4F6", "#F2F4F6", "#F2F4F6"],
					backgroundBarRadius: 5,
				},
			},
		},
		xaxis: {
			categories: dataLabels,
			crosshairs: {
				width: 1,
			},
		},
		tooltip: {
			fillSeriesColor: false,
			onDatasetHover: {
				highlightDataSeries: false,
			},
			theme: "light",
			style: {
				fontSize: "12px",
				fontFamily: "Inter",
			},
			y: {
				formatter: function (val) {
					return val + " tasks";
				},
			},
		},
	};
	return (
		<Card border="0" className="shadow">
			<Card.Body>
				<Row className="d-block d-xxl-flex align-items-center">
					<Col xs={12} xxl={6} className="px-xxl-0 mb-3 mb-xxl-0">
						<ApexChart
							type="bar"
							height={140}
							width="100%"
							series={chartSeries}
							options={chartOptions}
						/>
					</Col>
					<Col xs={12} xxl={6} className="ps-xxl-4 pe-xxl-0">
						<h2 className="fs-5 fw-normal mb-1">Weekly Activities</h2>
						<small className="d-flex align-items-center">
							<CalendarIcon className="icon icon-xxs text-gray-400 me-1" />
							{fromDate} - {toDate}
						</small>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default MemberActivityWidget;
