import React, { useEffect, useState, useCallback } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";
import { logout } from "redux/actions/authActions";
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";
import Footer from "components/Footer";

const SwalWithBootstrapButtons = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary me-3",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

const RouteWithSidebar = ({ children, ...rest }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const resize = () => {
		var resize = setInterval(() => {
			window.dispatchEvent(new Event("resize"));
		}, 10);
		setTimeout(function () {
			clearInterval(resize);
		}, 301);
	};

	const localStorageIsContracted = () => {
		return localStorage.getItem("sidebarContracted") === "false" ? false : true;
	};

	const localStorageIsSettingsVisible = () => {
		return localStorage.getItem("settingsVisible") === "false" ? false : true;
	};

	const [contracted, setContracted] = useState(localStorageIsContracted());
	const [contractSidebar, setContractSidebar] = useState(
		localStorageIsContracted(),
	);
	const [showSettings, setShowSettings] = useState(
		localStorageIsSettingsVisible(),
	);

	const toggleMouseOver = () => {
		if (contracted) {
			setContractSidebar(!contractSidebar);
		}
		resize();
	};

	const toggleContracted = () => {
		setContracted(!contracted);
		setContractSidebar(!contracted);
		localStorage.setItem("sidebarContracted", !contracted);
		resize();
	};

	const toggleSettings = () => {
		setShowSettings(!showSettings);
		localStorage.setItem("settingsVisible", !showSettings);
	};

	const isAuthenticated = useCallback(async () => {
		const access_token = localStorage.getItem("accessToken");

		if (access_token) {
			const decodedToken = jwt_decode(access_token);
			const expirationTime = decodedToken.exp * 1000; // convert to milliseconds
			const isExpired = Date.now() > expirationTime;
			if (isExpired) {
				dispatch({ type: CLEAR_MESSAGE });
				SwalWithBootstrapButtons.fire({
					title: "Session Expired",
					text: "Please log in again",
					icon: "info",
					showCancelButton: true,
					confirmButtonText: "Login",
					cancelButtonText: "Cancel",
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						dispatch(logout());
						dispatch({ type: CLEAR_MESSAGE });
						navigate("/auth/sign-in", { state: { from: location }, replace: true });
					} else if (result.dismiss === Swal.DismissReason.cancel) {
						dispatch(logout());
						dispatch({ type: CLEAR_MESSAGE });
						navigate("/", { state: { from: location }, replace: true });
					}
				});
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}, [dispatch, navigate, location]);

	useEffect(() => {
		const checkAuth = setTimeout(isAuthenticated, 6000);

		return () => {
			clearTimeout(checkAuth);
		};
	}, [isAuthenticated]);

	const isKanbanRoute = location && location.pathname.includes("kanban");
	const isMessageRoute = location && location.pathname.includes("message");

	if (!isAuthenticated) {
		return <Navigate to="/" state={{ from: location.pathname }} replace />;
	}

	return (
		<>
			<Sidebar
				contracted={contractSidebar}
				onMouseEnter={toggleMouseOver}
				onMouseLeave={toggleMouseOver}
			/>

			<main className="content">
				<div id="sub-main">
					<Topbar toggleContracted={toggleContracted} />
					{React.Children.map(children, (child) =>
						React.cloneElement(child, { ...rest, location }),
					)}
				</div>
				<div id="sub-footer">
					{!isKanbanRoute && !isMessageRoute && (
						<Footer toggleSettings={toggleSettings} showSettings={showSettings} />
					)}
				</div>
			</main>
		</>
	);
};

export default RouteWithSidebar;
