import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Button, ListGroup, Card, Image } from "react-bootstrap";
import { EyeIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { URLs } from "routes";
import { getAllTeams } from "services/teamService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getUser } from "redux/actions/userActions";
import { useSelector } from "react-redux";
import { socket, realtimeUpdate } from "shared/socket";

const capitalizeFirstLetter = (string) =>
	string[0].toUpperCase() + string.slice(1);

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

const ViewOrgTeamMembers = () => {
	const dispatch = useDispatch();
	const [teams, setTeams] = useState([]);
	const { user } = useSelector((state) => state.user);
	const [userData, setUserData] = useState(user);
	const [onlineUsers, setOnlineUsers] = useState([]);

	useEffect(() => {
		setUserData(user);
	}, [user]);

	const getTeams = useCallback(async () => {
		try {
			const { success, message, data } = await getAllTeams();
			if (success === true) {
				setTeams(data?.map((t) => ({ ...t, show: true })));
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, []);

	useEffect(() => {
		getTeams();
	}, [getTeams]);

	// Connect to socket
	useEffect(() => {
		socket.emit("new-user-add", userData?._id);
		socket.on("get-users-online", (data) => {
			setOnlineUsers(data);
		});
	}, [userData]);

	// Check if the user is online
	const checkOnlineStatus = (data) => {
		const online = onlineUsers?.find((user) => user.userId === data?._id);
		return online ? true : false;
	};

	useEffect(() => {
		realtimeUpdate.on("team-updated", (data) => {
			if (data) {
				getTeams();
				dispatch(getUser());
			}
		});
	}, [dispatch, getTeams]);

	const TeamMember = (props) => {
		const { profile_image, first_name, last_name } = props;

		const name = `${first_name.charAt(0).toUpperCase()}${first_name.slice(
			1,
		)} ${last_name.charAt(0).toUpperCase()}${last_name.slice(1)}`;

		return (
			<ListGroup.Item className="px-0">
				<Row className="align-items-center">
					<Col xs="auto">
						<Card.Link href={name} className="avatar">
							{profile_image ? (
								<Image src={profile_image} className="avatar rounded m-0" />
							) : (
								<div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary m-0">
									<span>{getInitialsOfFirstTwoWords(name)}</span>
								</div>
							)}
						</Card.Link>
					</Col>
					<Col xs="auto" className="ms--2">
						<h4 className="h6 mb-0">
							<Card.Link href={`#${name}`}>{capitalizeFirstLetter(name)}</Card.Link>
						</h4>
						<div className="d-flex align-items-center" style={{ fontSize: "0.8rem" }}>
							<div
								className={`bg-${
									checkOnlineStatus(props) ? "success" : "gray-200"
								} dot rounded-circle me-1`}
							/>
							<small>{checkOnlineStatus(props) ? "Online" : "Offline"}</small>
						</div>
					</Col>
					<Col className="text-end">
						<Button
							variant="secondary"
							size="sm"
							className="d-inline-flex align-items-center"
							as={Link}
							to={URLs.Profile.path + "/" + props?._id}
						>
							<EyeIcon className="icon icon-xs me-2" />
							View
						</Button>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	};

	return (
		<div className="mt-5 d-flex flex-wrap">
			{teams.length > 0 ? (
				teams.map((t) => (
					<Card border="0" className="shadow me-3 mb-3" key={t?._id}>
						<Card.Header className="border-bottom d-flex align-items-center justify-content-between">
							<h2 className="fs-5 fw-bold mb-0">{t.name}</h2>
							<div></div>
						</Card.Header>
						<Card.Body>
							<ListGroup className="list-group-flush list my--3">
								{t.members.length > 0 ? (
									t.members.map((tm) => (
										<TeamMember key={`team-member-${tm?._id}`} {...tm} />
									))
								) : (
									<div className="d-flex justify-content-center align-items-center w-100 h-100">
										It's lonely here
										{/* smiley face emoji */}
									</div>
								)}
							</ListGroup>
						</Card.Body>
					</Card>
				))
			) : (
				<div className="d-flex justify-content-center align-items-center w-100 h-100">
					No teams yet
				</div>
			)}
		</div>
	);
};

export default ViewOrgTeamMembers;
