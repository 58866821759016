import httpService from "./httpService";

export const getOrganisationReport = async (timeFrame, format) => {
	let url = `/report/organisation-report`;
	const params = new URLSearchParams();

	if (timeFrame) params.append("timeFrame", timeFrame);
	if (format) params.append("format", format);

	if (params.toString()) {
		url += "?" + params.toString();
	}

	try {
		const res = await httpService.download(url);
		return res;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getTeamReport = async (teamId, timeFrame, format) => {
	let url = `/report/team-report/${teamId}`;
	const params = new URLSearchParams();

	if (timeFrame) params.append("timeFrame", timeFrame);
	if (format) params.append("format", format);

	if (params.toString()) {
		url += "?" + params.toString();
	}

	try {
		const res = await httpService.download(url);
		return res;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getUserReport = async (userId, timeFrame, format) => {
	let url = `/report/user-report/${userId}`;
	const params = new URLSearchParams();

	if (timeFrame) params.append("timeFrame", timeFrame);
	if (format) params.append("format", format);

	if (params.toString()) {
		url += "?" + params.toString();
	}

	try {
		const res = await httpService.download(url);
		return res;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
