import React, { useEffect, useState, useCallback } from "react";
import moment from "moment-timezone";
import {
	Col,
	Row,
	Card,
	Image,
	Button,
	Container,
	Navbar,
	Nav,
} from "react-bootstrap";
import { MdArrowForward } from 'react-icons/md';
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { URLs } from "routes";
import AmeboLogo from "assets/img/Amebo-Logo.png"
import featureData from "data/features";
import jwt_decode from "jwt-decode";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { logout } from "redux/actions/authActions";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";

const SwalWithBootstrapButtons = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary me-3",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

const Presentation = () => {
	const currentYear = moment().get("year");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { features } = featureData;

	const { message } = useSelector((state) => state.message);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
		dispatch({ type: CLEAR_MESSAGE });
	}, [dispatch]);

	useEffect(() => {
		if (!isMounted) {
			return; // Skip displaying toasts on initial mount
		}

		if (message?.success === true) {
			SwalWithBootstrapButtons.fire("Logged out", message, "success");
			dispatch({ type: CLEAR_MESSAGE });
		}
	}, [isMounted, message, dispatch]);

	const isAuthenticated = useCallback(async () => {
		const access_token = localStorage.getItem("accessToken");

		if (access_token) {
			const decodedToken = jwt_decode(access_token);
			const expirationTime = decodedToken.exp * 1000; // convert to milliseconds
			const isExpired = Date.now() > expirationTime;
			if (isExpired) {
				dispatch({ type: CLEAR_MESSAGE });
				SwalWithBootstrapButtons.fire({
					title: "Session Expired",
					text: "Please log in again",
					icon: "info",
					showCancelButton: true,
					confirmButtonText: "Login",
					cancelButtonText: "Cancel",
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						dispatch(logout());
						dispatch({ type: CLEAR_MESSAGE });
						navigate("/auth/sign-in", { state: { from: location }, replace: true });
					} else if (result.dismiss === Swal.DismissReason.cancel) {
						dispatch(logout());
						dispatch({ type: CLEAR_MESSAGE });
						navigate("/", { state: { from: location }, replace: true });
					}
				});
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}, [dispatch, navigate, location]);

	useEffect(() => {
		const checkAuth = setTimeout(isAuthenticated, 6000);

		return () => {
			clearTimeout(checkAuth);
		};
	}, [isAuthenticated]);

	const Feature = (props) => {
		const { title, description, icon } = props;

		return (
			<Col xs={12} sm={6} lg={3}>
				<Card
					className="bg-white shadow-soft text-primary rounded mb-4"
					style={{ height: "100%" }}
				>
					<div className="px-3 px-lg-4 py-5 text-center">
						{icon}
						<h5 className="fw-bold text-primary">{title}</h5>
						<p>{description}</p>
					</div>
				</Card>
			</Col>
		);
	};

	return (
		<>
			<Navbar
				variant="dark"
				expand="lg"
				bg="dark"
				className="navbar-transparent navbar-theme-primary sticky-top"
			>
				<Container className="position-relative justify-content-between px-3">
					<Navbar.Brand
						as={Link}
						to="/"
						className="me-lg-3 d-flex align-items-center"
					>
						<Image src={AmeboLogo} />
						<span className="ms-2 brand-text d-none d-md-inline">Amebo Board</span>
					</Navbar.Brand>

					<div className="d-flex align-items-center">
						<Navbar.Collapse id="navbar-default-primary">
							<Nav className="navbar-nav-hover align-items-lg-center"></Nav>
						</Navbar.Collapse>
						<Button
							as={Link}
							to={URLs.Signin.path}
							variant="outline-white"
							className="ms-3"
						>
							Sign In
						</Button>
					</div>
				</Container>
			</Navbar>
			<section
				className="section-header pt-5 pt-lg-6 pb-lg-8 bg-primary text-white overflow-hidden"
				id="home"
			>
				<Container>
					<Row>
						<Col xs={12} className="text-center">
							<h1 className="fw-bolder">
								Amebo
								<span className="d-none d-sm-inline"> Board</span>
							</h1>
							<p className="text-muted fw-light h5">
								Organize your team and keep track of your work with a single tool.
							</p>
							<p className="text-muted fw-light h5 px-lg-8 pb-3">
								Its simple. To remove all the hassle of managing teams and projects with
								multiple people aboard.
							</p>
							<div className="d-flex justify-content-center mb-5">
								<Button
									variant="outline-secondary"
									as={Link}
									to={URLs.Signin.path}
									className="d-flex align-items-center"
									style={{ marginLeft: "10px" }}
								>
									Continue <MdArrowForward className="ms-2" />
								</Button>
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center mb-5 mb-lg-6">
						<Col xs={12} className="text-center">
							<h2 className="px-lg-5">Awesome Features</h2>
						</Col>
					</Row>
					<Row>
						{features.map((feature) => (
							<Feature key={`features-${feature.id}`} {...feature} />
						))}
					</Row>
				</Container>
			</section>

			<footer className="footer pb-6 bg-primary text-white">
				<Container>
					<Row>
						<Col md={4}>
							<Navbar.Brand
								as={Link}
								to={URLs.Presentation.path}
								className="me-lg-3 mb-3 d-flex align-items-center"
							>
								<Image src={AmeboLogo} />
								<span className="ms-2 brand-text">Amebo Board</span>
							</Navbar.Brand>
							<p>
								Amebo Board is a product of Public and Private Development Centre for
								project and task management
							</p>
						</Col>
						<Col xs={6} md={2} className="mb-5 mb-lg-0">
							<span className="h5">Amebo Board</span>
							<ul className="links-vertical mt-2">
								<li>
									<Card.Link target="_blank" href="/products">
										Products
									</Card.Link>
								</li>
								<li>
									<Card.Link target="_blank" href="/about">
										About Us
									</Card.Link>
								</li>
								<li>
									<Card.Link target="_blank" href="/contact">
										Contact Us
									</Card.Link>
								</li>
							</ul>
						</Col>
						<Col xs={6} md={2} className="mb-5 mb-lg-0">
							<span className="h5">Other</span>
							<ul className="links-vertical mt-2">
								<li>
									<Card.Link
										as={Link}
										to={"https://youtu.be/LoBcoOryzKk"}
										target="_blank"
									>
										Video Guide
									</Card.Link>
								</li>
								<li>
									<Card.Link target="_blank" as={Link} to={"https://www.ppdc.org/"}>
										Blog
									</Card.Link>
								</li>
								<li>
									<Card.Link target="_blank" href="/licensing">
										License
									</Card.Link>
								</li>
							</ul>
						</Col>
						<Col xs={12} md={4} className="mb-5 mb-lg-0">
							<span className="h5 mb-3 d-block">Subscribe</span>
							<form action="#">
								<div className="form-row mb-2">
									<div className="col-12">
										<input
											type="email"
											className="form-control mb-2"
											placeholder="example@company.com"
											name="email"
											aria-label="Subscribe form"
											readOnly
										/>
									</div>
									<div className="col-12 d-grid gap-2">
										<button
											type="submit"
											className="btn btn-secondary text-dark shadow-soft"
											data-loading-text="Sending"
										>
											<span>Subscribe</span>
										</button>
									</div>
								</div>
							</form>
							<p className="text-muted font-small m-0">
								We’ll never share your details. See our{" "}
								<Card.Link className="text-white" href="#">
									Privacy Policy
								</Card.Link>
							</p>
						</Col>
					</Row>
					<hr className="bg-gray-600 my-5" />
					<Row>
						<Col className="mb-md-2">
							<div
								className="d-flex text-center justify-content-center align-items-center"
								role="contentinfo"
							>
								<p className="font-weight-normal font-small mb-0">
									Copyright © PPDC <span className="current-year">{currentYear}</span>.
									All rights reserved.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</footer>
		</>
	);
};

export default Presentation;
