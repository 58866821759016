import React, { useEffect, useState } from "react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import {
	Col,
	Row,
	Form,
	Card,
	Button,
	Container,
	InputGroup,
	Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { URLs } from "routes";
import { requestResetPassword } from "../../services/authService";
import { toast } from "react-toastify";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";
import { useSelector, useDispatch } from "react-redux";

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const { message } = useSelector((state) => state.message);
	const [isMounted, setIsMounted] = useState(false);

	const userData = {
		email: email,
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!email) {
			toast("Please fill in email", {
				type: "error",
				autoClose: 3000,
			});
			return;
		}
		setLoading(true);
		try {
			const response = await requestResetPassword(userData);
			if (response.success === true) {
				toast(response.message, {
					type: "success",
					autoClose: 3000,
				});
				navigate("/auth/verify-token");
			} else {
				toast(response.message, {
					type: "error",
					autoClose: 3000,
				});
				setLoading(false);
			}
		} catch (err) {
			toast(err.message, {
				type: "error",
				autoClose: 3000,
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		setIsMounted(true);
		dispatch({ type: CLEAR_MESSAGE });
	}, [dispatch]);

	useEffect(() => {
		if (!isMounted) {
			return; // Skip displaying toasts on initial mount
		}

		if (message?.success === true) {
			toast(message?.text, { type: "success", autoClose: 3000 });
		}

		if (message?.success === false) {
			toast(message?.text, { type: "error", autoClose: 3000 });
		}
	}, [isMounted, message]);

	return (
		<main>
			<section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
				<Container>
					<Row className="justify-content-center">
						<p className="text-center">
							<Card.Link
								as={Link}
								to={URLs.Signin.path}
								className="d-flex align-items-center justify-content-center"
							>
								<ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to sign in
							</Card.Link>
						</p>
						<Col xs={12} className="d-flex align-items-center justify-content-center">
							<div className="signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
								<h3>Forgot your password?</h3>
								<p className="mb-4">
									Don't fret! Just type in your email and we will send you a code to
									reset your password!
								</p>
								<Form>
									<div className="mb-4">
										<Form.Label htmlFor="email">Your Email</Form.Label>
										<InputGroup id="email">
											<Form.Control
												autoFocus
												type="email"
												placeholder="email: example@company.com"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</InputGroup>
									</div>
									<div className="d-grid">
										<Button
											variant="gray-800"
											type="submit"
											onClick={handleSubmit}
											disabled={loading}
										>
											{loading ? (
												<Spinner animation="border" size="sm" className="mr-2" />
											) : null}
											Recover password
										</Button>
									</div>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};

export default ForgotPassword;
