import { SET_MESSAGE } from "./messageActions";
import { getAllTeams } from "services/teamService";

export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAIL = "GET_TEAMS_FAIL";
export const CLEAR_TEAMS = "CLEAR_TEAMS";

// action creators
export const getTeams = () => async (dispatch) => {
	dispatch({ type: GET_TEAMS_REQUEST });
	try {
		const { success, message, data } = await getAllTeams();
		if (success === true) {
			dispatch({
				type: GET_TEAMS_SUCCESS,
				payload: data,
			});
		} else {
			dispatch({ type: GET_TEAMS_FAIL });
			dispatch({
				type: SET_MESSAGE,
				payload: { text: message, success: success },
			});
		}
	} catch (err) {
		dispatch({ type: GET_TEAMS_FAIL });
		dispatch({
			type: SET_MESSAGE,
			payload: { text: err.message, success: false },
		});
	}
};

