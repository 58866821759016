const makeTextClickable = (text) => {
	// Regex for URLs (including those without protocol)
	const urlPattern =
		/(\b(?:https?:\/\/)?(?:www\.)?[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]\.(?:com|org|net|edu|gov|mil|io|co|uk|de|fr|jp|ru|br|in|au|us)(?:\/[-A-Z0-9+&@#/%=~_|]*)?)/gi;
	// Regex for email addresses
	const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

	const parts = text.split(/(\s+)/); // Split on spaces, but keep spaces

	return parts.map((part, index) => {
		if (urlPattern.test(part)) {
			const url = part.startsWith("http") ? part : `https://${part}`;
			return (
				<a
					key={index}
					href={url}
					target="_blank"
					rel="noopener noreferrer"
					style={{ color: "#0000FF", textDecoration: "underline" }}
				>
					{part}
				</a>
			);
		} else if (emailPattern.test(part)) {
			return (
				<a
					key={index}
					href={`mailto:${part}`}
					style={{ color: "#0000FF", textDecoration: "underline" }}
				>
					{part}
				</a>
			);
		} else {
			return part; // Just return the text if it’s not a URL or email
		}
	});
};


const elipsis = (text, length) => {
	return text.length > length ? text.substring(0, length) + "..." : text;
};

export { makeTextClickable, elipsis };
