import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HomeIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import Timeline from "components/Timeline";
import { getUser } from "redux/actions/userActions";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";
import MyFiveTeamMembers from "components/MyFiveTeamMembers";
import MyProgressTrack from "components/MyProgressTrack";
import MemberActivityWidget from "components/MemberActivitWidget";
import MemberProjectWidget from "components/MemberProjectWidget";
import MemberTaskWidget from "components/MemberTaskWidget";
import MemberPerformanceValueWidget from "components/MemberPerformanceWidget";
import ReportOptionModal from "components/ReportOptionModal";

const Home = () => {
	const dispatch = useDispatch();
	const { authId } = useSelector((state) => state.authId);
	const { user } = useSelector((state) => state);
	const { message } = useSelector((state) => state.message);
	const [isMounted, setIsMounted] = useState(false);
	const [show, setShow] = useState(false);

	useEffect(() => {
		setIsMounted(true);
		dispatch({ type: CLEAR_MESSAGE });
	}, [dispatch]);

	useEffect(() => {
		if (authId) {
			dispatch(getUser());
		}
	}, [dispatch, authId]);

	useEffect(() => {
		if (!isMounted) {
			return; // Skip displaying toasts on initial mount
		}
		if (message?.text) {
			const toastType = message.success ? "success" : "error";
			toast(message.text, { type: toastType, autoClose: 3000 });
		}
	}, [isMounted, message]);

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb
						className="d-none d-md-inline-block"
						listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
					>
						<Breadcrumb.Item>
							<HomeIcon className="icon icon-xs" />
						</Breadcrumb.Item>
						<Breadcrumb.Item>Amebo Board</Breadcrumb.Item>
						<Breadcrumb.Item active></Breadcrumb.Item>
					</Breadcrumb>
				</div>
			</div>{" "}
			<div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
				<Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
					<div className="me-lg-3">
						<h4>Activities and Timeline</h4>
					</div>
				</Col>
				<ButtonGroup className="ms-auto">
					<Button variant="secondary" onClick={() => setShow(!show)}>
						Download Report
					</Button>
				</ButtonGroup>
			</div>
			<div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
				<Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
					<div className="me-lg-3"></div>
				</Col>
				<div></div>
			</div>
			<Row className="justify-content-lg-center">
				<Col xs={12} className="mb-4">
					<MemberPerformanceValueWidget />
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={6} xl={4} className="mb-4">
					<MemberActivityWidget />
				</Col>
				<Col xs={12} sm={6} xl={4} className="mb-4">
					<MemberProjectWidget />
				</Col>

				<Col xs={12} sm={12} xl={4} className="mb-4">
					<MemberTaskWidget />
				</Col>
			</Row>
			<Row>
				<Col xs={12} xxl={4} className="mb-4">
					<MyFiveTeamMembers />
				</Col>

				<Col xs={12} xxl={4} className="mb-4">
					<MyProgressTrack />
				</Col>

				<Col xs={12} xxl={4} className="mb-4">
					<Timeline />
				</Col>
			</Row>
			<ReportOptionModal
				setShow={setShow}
				show={show}
				type={"user"}
				user={user.user}
			/>
		</>
	);
};

export default Home;
