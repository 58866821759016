import React, {} from "react";
import {
	Button,
	Form,
	Modal,
	ListGroup,
	Card,
} from "react-bootstrap";
import {
	archiveProject,
	doneProject,
	suspendProject,
} from "services/projectService";
import { useDispatch } from "react-redux";
import { fetchSingleProject } from "redux/actions/singleProjectActions";
import { toast } from "react-toastify";

const MoveProjectModal = (props) => {
	const {
		id,
		showModal,
		setShowModal,
		suspended,
		done,
		archived,
		getProjects,
	} = props;
	const dispatch = useDispatch();
	
	const handleSuspendToggle = async () => {
		const { message, success } = await suspendProject(id);
		if (success === true) {
			dispatch(fetchSingleProject(id));
			getProjects();
			setShowModal(!showModal);
			toast(message, { type: "success", autoClose: 5000 });
		} else {
			toast(message, { type: "error", autoClose: 5000 });
		}
	};

	const handleDoneToggle = async () => {
		const { message, success } = await doneProject(id);
		if (success === true) {
			dispatch(fetchSingleProject(id));
			getProjects();
			setShowModal(!showModal);
			toast(message, { type: "success", autoClose: 5000 });
		} else {
			toast(message, { type: "error", autoClose: 5000 });
		}
	};

	const handleArchiveToggle = async () => {
		const { message, success } = await archiveProject(id);
		if (success === true) {
			dispatch(fetchSingleProject(id));
			getProjects();
			setShowModal(!showModal);
			toast(message, { type: "success", autoClose: 5000 });
		} else {
			toast(message, { type: "error", autoClose: 5000 });
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Select Directory
					</h5>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<ListGroup className="list-group-flush">
						<ListGroup.Item
							className={`d-flex align-items-center justify-content-between px-0 border-bottom`}
						>
							<div>
								<Card.Text className="h6 mb-1">Suspend</Card.Text>
								<p className="small pe-4">
									This will move the project to Suspended Directory
								</p>
							</div>
							<div>
								<Form.Check className="form-switch">
									<Form.Check.Input
										defaultChecked={suspended}
										onChange={handleSuspendToggle}
										type="checkbox"
									/>
								</Form.Check>
							</div>
						</ListGroup.Item>
						<ListGroup.Item
							className={`d-flex align-items-center justify-content-between px-0 border-bottom`}
						>
							<div>
								<Card.Text className="h6 mb-1">Done</Card.Text>
								<p className="small pe-4">
									This will move the project to Done Directory
								</p>
							</div>
							<div>
								<Form.Check className="form-switch">
									<Form.Check.Input
										defaultChecked={done}
										onChange={handleDoneToggle}
										type="checkbox"
									/>
								</Form.Check>
							</div>
						</ListGroup.Item>
						<ListGroup.Item
							className={`d-flex align-items-center justify-content-between px-0`}
						>
							<div>
								<Card.Text className="h6 mb-1">Archive</Card.Text>
								<p className="small pe-4">
									This will move the project to Archive Directory
								</p>
							</div>
							<div>
								<Form.Check className="form-switch">
									<Form.Check.Input
										defaultChecked={archived}
										onChange={handleArchiveToggle}
										type="checkbox"
									/>
								</Form.Check>
							</div>
						</ListGroup.Item>
					</ListGroup>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0"></Modal.Footer>
			</Form>
		</Modal>
	);
};

export default MoveProjectModal;
