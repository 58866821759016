import { loginUser } from "services/authService";
import {
	updateLocalAccessToken,
	updateLocalRefreshToken,
	removeToken,
} from "services/tokenService";
import { CLEAR_MESSAGE, SET_MESSAGE } from "./messageActions";
import { CLEAR_MEMBERS } from "./memberActions";
import { CLEAR_TEAMS } from "./teamActions";
import { CLEAR_MY_TEAMS } from "./myTeamActions";
import { CLEAR_EVENTS } from "./eventActions";
import { CLEAR_MY_PROJECTS } from "./myProjectActions";
import { CLEAR_PROJECTS } from "./projectActions";
import { CLEAR_SINGLE_PROJECTS } from "./singleProjectActions";
import { CLEAR_USER } from "./userActions";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

// action creators
export const login = (email, password, navigate) => async (dispatch) => {
	dispatch({ type: LOGIN_REQUEST });
	try {
		const { success, message, access_token, refresh_token, user } =
			await loginUser({ email, password });
		if (success === true) {
			updateLocalAccessToken(access_token);
			updateLocalRefreshToken(refresh_token);
			dispatch({
				type: LOGIN_SUCCESS,
				payload: user,
			});
			dispatch({
				type: SET_MESSAGE,
				payload: { text: message, success: success },
			});
			navigate("/home", { replace: true });
		} else {
			dispatch({ type: LOGIN_FAIL });
			dispatch({
				type: SET_MESSAGE,
				payload: { text: message, success: success },
			});
		}
	} catch (err) {
		dispatch({ type: LOGIN_FAIL });
		dispatch({
			type: SET_MESSAGE,
			payload: { text: err.message, success: false },
		});
	}
};

export const logout = (navigate) => async (dispatch) => {
	try {
			dispatch({ type: LOGOUT });
			dispatch({ type: CLEAR_MEMBERS });
			dispatch({ type: CLEAR_TEAMS });
			dispatch({ type: CLEAR_MY_TEAMS });
			dispatch({ type: CLEAR_EVENTS });
			dispatch({ type: CLEAR_MESSAGE });
			dispatch({ type: CLEAR_MY_PROJECTS });
			dispatch({ type: CLEAR_PROJECTS });
			dispatch({ type: CLEAR_SINGLE_PROJECTS });
			dispatch({ type: CLEAR_USER });
			dispatch({
				type: SET_MESSAGE,
				payload: { text: "Logout successful", success: true },
			});
			removeToken();
			navigate("/", { replace: true });
		
	} catch (error) {
		dispatch({
			type: SET_MESSAGE,
			payload: { text: error.message, success: false },
		});
	}
};

