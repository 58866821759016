import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAllTeams } from "services/teamService";

const SelectTeamModal = (props) => {
	const { showTeamModal, setShowTeamModal, setSelectedTeam } = props;
	const [teams, setTeams] = useState([]);
	const [teamData, setTeamData] = useState(
		teams?.map((t) => ({ ...t, show: true })),
	);
	const [searchValue, setSearchValue] = useState("");

	const getTeams = async () => {
		try {
			const { success, data, message } = await getAllTeams();
			if (success === true) {
				setTeams(data.map((t) => ({ ...t, show: true })));
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	};

	useEffect(() => {
		getTeams();
	}, []);

	useEffect(() => {
		const updatedTeams = teams?.map((t) => ({
			...t,
			show: true,
		}));
		setTeamData(updatedTeams);
	}, [teams]);

	const changeSearchValue = (e) => {
		const newSearchValue = e.target.value;
		const newTeam = teamData.map((t) => {
			const name = t.name.toLowerCase();
			const description = t.description.toLowerCase();

			const shouldShow =
				name.includes(newSearchValue) ||
				description.includes(newSearchValue) ||
				`${t.time}`.includes(newSearchValue);

			return {
				...t,
				show: shouldShow,
				isSelected: shouldShow && t.isSelected ? false : t.isSelected,
			};
		});

		setTeamData(newTeam);
		setSearchValue(newSearchValue);
	};

	const handleTeamSelect = (teamId) => {
		const selectedTeam = teamData.find((team) => team?._id === teamId);
		setSelectedTeam(selectedTeam);
		setShowTeamModal(false);
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			scrollable
			show={showTeamModal}
			onHide={() => setShowTeamModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Select Team</Modal.Title>
					<Button variant="close" onClick={() => setShowTeamModal(false)} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="search" className="mb-3">
						<InputGroup className="search-bar">
							<Form.Control
								type="text"
								placeholder="Search teams.."
								value={searchValue}
								onChange={changeSearchValue}
							/>
						</InputGroup>
					</Form.Group>

					<div className="px-3">
						{teamData.length === 0 ? (
							<p className="text-center">No teams found</p>
						) : (
							<div className="px-3">
								{teamData
									.map((team) => ({
										...team,
										show: team.name.toLowerCase().includes(searchValue.toLowerCase()),
									}))
									.filter((t) => t.show)
									.map((team) => (
										<Row
											key={`team-${team?._id}`}
											className="kanban-card-member border-bottom py-2"
											onClick={() => handleTeamSelect(team?._id)}
										>
											<Col xs={10}>
												<h4 className="fs-6 text-dark mb-0">{team.name}</h4>
											</Col>
										</Row>
									))}
							</div>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pb-0"></Modal.Footer>
			</Form>
		</Modal>
	);
};

export default SelectTeamModal;
