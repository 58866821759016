import axios from "axios";
import { baseURL } from "../shared/url";
import {
	getLocalAccessToken,
	getLocalRefreshToken,
	updateLocalAccessToken,
	removeToken,
	updateLocalRefreshToken,
} from "./tokenService";

let isRefreshing = false; // Flag to track token refresh process
let refreshSubscribers = []; // Array to hold pending request subscribers

const axiosInstance = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/json",
	},
});

// Function to refresh access token
const refreshAccessToken = async () => {
	try {
		isRefreshing = true;
		const refreshToken = getLocalRefreshToken();

		// Request new access token using refresh token
		const response = await axiosInstance.post("/auth/refresh-token", {
			refreshToken: refreshToken,
		});

		const { access_token, refresh_token } = response.data;
		updateLocalAccessToken(access_token);
		updateLocalRefreshToken(refresh_token);
		isRefreshing = false;

		// Resolve all pending requests with the new access token
		refreshSubscribers.forEach((subscriber) => subscriber(access_token));
		refreshSubscribers = [];
	} catch (error) {
		isRefreshing = false;
		return Promise.reject(error);
	}
};

axiosInstance.interceptors.request.use(
	async (config) => {
		const access_token = getLocalAccessToken();
		if (access_token !== null) {
			config.headers["Authorization"] = `Bearer ${access_token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalRequest = err.config;

		if (originalRequest.url !== "/auth/login" && err.response) {
			if (err.response.status === 401 && !originalRequest._retry) {
				if (!isRefreshing) {
					// Refresh token if it hasn't been refreshed already
					await refreshAccessToken();
				}

				// Create a new promise to return the access token
				const retryOriginalRequest = new Promise((resolve) => {
					refreshSubscribers.push((access_token) => {
						originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
						resolve(axiosInstance(originalRequest));
					});
				});

				return retryOriginalRequest;
			}
		}

		if (err.response && err.response.status === 401) {
			// Clear both access and refresh tokens if a 401 error occurs
			removeToken();
		}

		return Promise.reject(err);
	},
);

const httpServices = {
	get: axiosInstance.get,
	post: (url, data, config) => {
		if (data instanceof FormData) {
			// If data is FormData, set 'Content-Type' to 'multipart/form-data'
			return axiosInstance.post(url, data, {
				...config,
				headers: { ...config?.headers, "Content-Type": "multipart/form-data" },
			});
		} else {
			return axiosInstance.post(url, data, config);
		}
	},
	put: (url, data, config) => {
		if (data instanceof FormData) {
			// If data is FormData, set 'Content-Type' to 'multipart/form-data'
			return axiosInstance.put(url, data, {
				...config,
				headers: { ...config?.headers, "Content-Type": "multipart/form-data" },
			});
		} else {
			return axiosInstance.put(url, data, config);
		}
	},
	delete: axiosInstance.delete,
	patch: (url, data, config) => {
		if (data instanceof FormData) {
			// If data is FormData, set 'Content-Type' to 'multipart/form-data'
			return axiosInstance.patch(url, data, {
				...config,
				headers: { ...config?.headers, "Content-Type": "multipart/form-data" },
			});
		} else {
			return axiosInstance.patch(url, data, config);
		}
	},
	// New method for downloading files
	download: async (url, config) => {
		try {
			const response = await axiosInstance.get(url, {
				...config,
				responseType: "blob", // Important for file downloads
			});
			return response;
		} catch (error) {
			return Promise.reject(error);
		}
	},
};

export default httpServices;
