import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { URLs } from "routes";

const ProfileUserCard = (props) => {
	const { user, fullName, picture, coverPhoto, jobTitle, location } = props;
	const navigate = useNavigate();
	return (
		<Card border="0" className="shadow text-center p-0">
			<div
				style={{ backgroundImage: `url(${coverPhoto})` }}
				className="profile-cover rounded-top"
			/>
			<Card.Body className="pb-5">
				<Card.Img
					src={picture}
					className="avatar-xl rounded-circle mx-auto mt-n7 mb-4"
				/>
				<Card.Title>{fullName}</Card.Title>
				<Card.Subtitle className="fw-normal">{jobTitle}</Card.Subtitle>
				<Card.Text className="text-gray mb-4">{location}</Card.Text>

				<Button
					variant="secondary"
					size="sm"
					onClick={() => navigate(`${URLs.Message.path + "/" + user?._id}`)}
				>
					Send Message
				</Button>
			</Card.Body>
		</Card>
	);
};

export default ProfileUserCard;
