import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
} from "../actions/authActions";

const initialState = {
	authId: null,
	loading: false,
};

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_REQUEST:
			return { ...state, loading: true };
		case LOGIN_SUCCESS:
			return { ...state, loading: false, authId: action.payload };
		case LOGIN_FAIL:
			return { ...state, loading: false };
		case LOGOUT:
			return { ...state, authId: null };
		default:
			return state;
	}
};
