import React, { useState } from "react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import {
	Col,
	Row,
	Form,
	Card,
	Button,
	Container,
	Spinner,
	InputGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { URLs } from "routes";
import { verifyToken } from "services/authService";
import OtpInput from "components/OtpInput";
import { toast } from "react-toastify";

const VerifyToken = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [otp, setOtp] = useState("");

	const handleOtpChange = (value) => {
		setOtp(value);
	};

	const userData = { otp: otp };

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!otp) {
			toast("OTP is required", { type: "error", autoClose: 3000 });
			return;
		}
		setLoading(true);
		try {
			const response = await verifyToken(userData);
			const { success, message } = response;
			if (success === true) {
				toast(message, { type: "success", autoClose: 3000 });
				navigate(`/auth/reset-password/${otp}`);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
				setLoading(false);
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message)
			setLoading(false);
		}
	};

	return (
		<main>
			<section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
				<Container>
					<Row className="justify-content-center">
						<p className="text-center">
							<Card.Link
								as={Link}
								to={URLs.Signin.path}
								className="d-flex align-items-center justify-content-center"
							>
								<ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to sign in
							</Card.Link>
						</p>
						<Col xs={12} className="d-flex align-items-center justify-content-center">
							<div className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
								<div className="text-center">
									<h3 className="mb-4">Verify Token</h3>
								</div>
								<Form>
									<div className="mb-4 text-center">
										<Form.Label>Enter token sent to your email</Form.Label>
										<InputGroup id="otp">
											<OtpInput value={otp} length={6} onChange={handleOtpChange} />
										</InputGroup>
									</div>
									<div className="d-grid">
										<Button
											variant="gray-800"
											type="submit"
											onClick={handleSubmit}
											disabled={loading}
										>
											{loading ? (
												<Spinner animation="border" size="sm" className="mr-2" />
											) : null}
											Submit
										</Button>
									</div>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};

export default VerifyToken;
