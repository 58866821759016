import React, { useEffect, useState, useCallback } from "react";
import {
	Col,
	Row,
	Form,
	Button,
	InputGroup,
	Pagination,
	Breadcrumb,
} from "react-bootstrap";
import { realtimeUpdate } from "shared/socket";
import { HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import TeamCard from "components/TeamCard";
import CreateTeam from "components/CreateTeamModal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";
import { getAllTeams } from "services/teamService";

const DashboardTeams = () => {
	const dispatch = useDispatch();
	const [teams, setTeams] = useState([]);
	const [isUpdated, setIsUpdated] = useState(false);

	const [teamData, setTeamData] = useState(
		teams?.map((t) => ({ ...t, show: true })),
	);

	const getTeams = useCallback(async () => {
		try {
			const { success, data, message } = await getAllTeams();
			if (success === true) {
				setTeams(data.map((t) => ({ ...t, show: true })));
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, []);

	useEffect(() => {
		getTeams();
	}, [getTeams]);

	useEffect(() => {
		if (isUpdated) {
			const updateTeams = async () => {
				try {
					const { success, data, message } = await getAllTeams();
					if (success === true) {
						setTeams(data.map((t) => ({ ...t, show: true })));
					} else {
						toast(message, { type: "error", autoClose: 3000 });
					}
				} catch (err) {
					console.log("SERVER_ERROR::", err.message);
				}
			};
			updateTeams();
			setIsUpdated(false);
		}
	}, [isUpdated]);

	useEffect(() => {
		realtimeUpdate.on("team-updated", (data) => {
			if (data) {
				getTeams();
				dispatch(getUser());
			}
		});
	}, [dispatch, getTeams]);

	useEffect(() => {
		const updatedTeams = teams?.map((t) => ({
			...t,
			show: true,
		}));
		setTeamData(updatedTeams);
	}, [teams]);

	const [searchValue, setSearchValue] = useState("");
	const [showModal, setShowModal] = useState(false);
	
	const handleOpenNewTeamCard = () => {
		setShowModal(true);
	};

	const changeSearchValue = (e) => {
		const newSearchValue = e.target.value;
		const newTeam = teamData.map((t) => {
			const name = t.name.toLowerCase();
			const description = t.description.toLowerCase();

			const shouldShow =
				name.includes(newSearchValue) ||
				description.includes(newSearchValue) 

			return {
				...t,
				show: shouldShow,
				isSelected: shouldShow && t.isSelected ? false : t.isSelected,
			};
		})

		setTeamData(newTeam);
		setSearchValue(newSearchValue);
	};

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentTeams = teamData.slice(indexOfFirstItem, indexOfLastItem);
	// Change page
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb
						className="d-none d-md-inline-block"
						listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
					>
						<Breadcrumb.Item>
							<HomeIcon className="icon icon-xs" />
						</Breadcrumb.Item>
						<Breadcrumb.Item>Amebo Board</Breadcrumb.Item>
						<Breadcrumb.Item active>Team List</Breadcrumb.Item>
					</Breadcrumb>
					<h4>Team List</h4>
				</div>
				<div className="btn-toolbar mb-2 mb-md-0"></div>
			</div>

			<div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
					<div className="me-lg-3">
						<Button
							as={Button}
							variant="secondary"
							className="d-inline-flex align-items-center me-2"
							onClick={handleOpenNewTeamCard}
						>
							<PlusIcon className="icon icon-xs me-2" /> New Team
						</Button>
					</div>
				</Col>

				<Col xs={12} lg={6}>
					<InputGroup className="d-flex justify-content-lg-end input-group-merge search-bar">
						<InputGroup.Text>
							<SearchIcon className="icon icon-xs" />
						</InputGroup.Text>{" "}
						<Form.Control
							type="text"
							placeholder="Search Teams Here"
							className="w-100 fmxw-300"
							value={searchValue}
							onChange={changeSearchValue}
						/>
					</InputGroup>
				</Col>
			</div>

			<div className="task-wrapper border bg-white border-light shadow-sm py-1 rounded">
				{teamData.length === 0 ? (
					<>
						<div className="text-center mt-4">No teams found</div>
						<div className="text-center mt-4">Create one or contact your admin</div>
					</>
				) : (
					currentTeams
						.filter((t) => t.show)
						.map((t) => (
							<TeamCard
								{...t}
								key={`team-${t?._id}`}
								isUpdated={isUpdated}
								setIsUpdated={setIsUpdated}
								getTeams={getTeams}
							/>
						))
				)}

				<Row className="d-flex align-items-center p-4">
					<div className="col-md-4 d-flex align-items-center mb-3 mb-md-0">
						<span className="me-2">Page:</span>
						<Pagination className="mb-0">
							<Pagination.Prev
								disabled={currentPage === 1}
								onClick={() => handlePageChange(currentPage - 1)}
							>
								Prev
							</Pagination.Prev>
							{Array.from(
								{ length: Math.ceil(teamData.length / itemsPerPage) },
								(_, i) => (
									<Pagination.Item
										key={i}
										active={i + 1 === currentPage}
										onClick={() => handlePageChange(i + 1)}
									>
										{i + 1}
									</Pagination.Item>
								),
							)}
							<Pagination.Next
								disabled={currentPage === Math.ceil(teamData.length / itemsPerPage)}
								onClick={() => handlePageChange(currentPage + 1)}
							>
								Next
							</Pagination.Next>
						</Pagination>
					</div>
					<div className="col-md-4 d-flex align-items-center mb-3 mb-md-0">
						<span className="me-2">Show:</span>
						<select
							className="form-select form-select-sm"
							style={{ width: "80px", paddingRight: "1rem" }}
							onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
							value={itemsPerPage}
						>
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={15}>15</option>
						</select>
						<span className="mx-2">entries</span>
					</div>
					<div className="col-md-4 d-flex align-items-center">
						<span className="me-2">Showing:</span>
						<span className="fw-normal">
							{currentTeams.length} out of {teamData.length} entries
						</span>
					</div>
				</Row>
			</div>
			<CreateTeam
				updateTeams={getTeams}
				showModal={showModal}
				setShowModal={setShowModal}
			/>
		</>
	);
};

export default DashboardTeams;
