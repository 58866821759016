import { PlusIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { createTeam } from "services/teamService";

const CreateTeam = (props) => {
	const { showModal, setShowModal } = props;
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	const teamData = {
		name: name,
		description: description,
	};

	const handleSubmit = async (event) => {
		setLoading(true);
		event.preventDefault();
		if (!name || !description) {
			toast("Please fill in all fields.", { type: "error", autoClose: 2000 });
			return;
		}
		try {
			const response = await createTeam(teamData);
			const { success, message } = response;
			if (success === true) {
				toast(message, { type: "success", autoClose: 2000 });
				props.updateTeams();
				setLoading(false);
				setShowModal(false);
				setDescription("");
				setName("");
			} else {
				toast(message, { type: "error", autoClose: 2000 });
				setShowModal(false);
				setLoading(false);
			}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setShowModal(false);
			setLoading(false);
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Create a New Team
					</h5>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Control
							autoFocus
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder="Enter Team name"
						/>
					</Form.Group>

					<Form.Group id="description" className="mb-3">
						<Form.Control
							required
							autoFocus
							multiple
							as="textarea"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							placeholder={`Enter a description for the Team`}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0">
					<Button variant="outline-gray-500" onClick={() => setShowModal(false)}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						<PlusIcon className="icon icon-xs me-2" />
						Add Team
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default CreateTeam;
