import React, { useState, useCallback } from "react";
import { PaperClipIcon } from "@heroicons/react/solid";
import { Card, Image, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { uploadProfilePhoto } from "services/userService";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";

export const ChooseProfilePhoto = (props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const { title, picture } = props;
	const MAX_FILE_SIZE = 800000; // 800KB

	const [profilePhoto, setProfilePhoto] = useState(null);

	const handleFileUpload = useCallback(
		(event) => {
			const file = event.target.files && event.target.files[0];
			if (file) {
				if (file.size > MAX_FILE_SIZE) {
					toast(`The file is too big. Max size is ${MAX_FILE_SIZE / 1000} KB.`, {
						type: "error",
						autoClose: 3000,
					});
					setProfilePhoto(null);
				} else if (!file.type.includes("image/")) {
					toast("The file must be an image.", { type: "error", autoClose: 3000 });
					setProfilePhoto(null);
				} else {
					setProfilePhoto(file);
				}
			} else {
				toast("Please upload a file", { type: "error", autoClose: 3000 });
				setProfilePhoto(null);
			}
		},
		[setProfilePhoto],
	);

	const formData = new FormData();
	formData.append("profile_image", profilePhoto);
	const handleSubmit = async () => {
		setLoading(true);
		try {
			const response = await uploadProfilePhoto(formData);
			const { success, message } = response;
			if (success === true) {
				dispatch(getUser());
				setLoading(false);
				toast(message, { type: "success", autoClose: 2000 });
			} else {
				setLoading(false);
				toast(message, { type: "error", autoClose: 2000 });
			}
		} catch (err) {
			setLoading(false);
			toast(err.message, { type: "error", autoClose: 2000 });
		}
	};

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<h5 className="mb-4">{title}</h5>
				<div className="d-flex align-items-center">
					<div className="me-3">
						<Image
							fluid
							rounded
							src={profilePhoto ? URL.createObjectURL(profilePhoto) : picture}
						/>
					</div>
					<div className="file-field hover-pointer">
						<div className="d-flex justify-content-xl-center ms-xl-3 hover-pointer">
							<label htmlFor="profile-image-upload" className="d-flex">
								<PaperClipIcon className="icon text-gray-500 me-2 hover-pointer" />
								<input
									id="profile-image-upload"
									type="file"
									name="file"
									accept="image/png, image/jpeg"
									hidden
									onChange={handleFileUpload}
								/>
								<div className="d-md-block text-left">
									<div className="fw-normal text-dark mb-1">Change Profile Image</div>
								</div>
							</label>
						</div>
					</div>
				</div>
				<div className="mt-4">
					<Button
						variant="primary"
						onClick={handleSubmit}
						disabled={profilePhoto && loading}
					>
						{profilePhoto && loading ? "Submitting..." : "Submit"}
					</Button>
				</div>
			</Card.Body>
		</Card>
	);
};
