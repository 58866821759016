import httpService from "./httpService";

export const createEvent = async (form) => {
	try {
		const res = await httpService.post("/calendar/create-event", form);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllEvents = async () => {
	try {
		const res = await httpService.get("/calendar/all-events");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getHolidays = async () => {
	try {
		const res = await httpService.get("/calendar/holidays");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const updateEvent = async (eventId, form) => {
	try {
		const res = await httpService.patch(
			`/calendar/update-event/${eventId}`,
			form,
		);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getSingleEvent = async (eventId) => {
	try {
		const res = await httpService.get(`/calendar/single-event/${eventId}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const deleteEvent = async (eventId) => {
	try {
		const res = await httpService.delete(`/calendar/single-event/${eventId}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
