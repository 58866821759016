import React, { useState, useEffect } from "react";
import {
	CalendarIcon,
	ChevronDownIcon,
	ClipboardListIcon,
} from "@heroicons/react/solid";
import { Col, Row, Button, Dropdown } from "react-bootstrap";

import { Link, useParams } from "react-router-dom";
import { URLs } from "routes";
import ProfileInfoForm from "components/ProfileInfoForm";
import ProfileUserCard from "components/ProfileUserCard";
import { getUserProfile } from "services/userService";
import AvatarPlaceholder from "assets/img/avatar_placeholder.png";
import CoverPhotoPlaceHolder from "assets/img/cover_photo_placeholder.png";
import Preloader from "components/Preloader";
import { toast } from "react-toastify";

const Profile = () => {
	const { userId } = useParams();

	const [userData, setUserData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);

		const getUser = async () => {
			try {
				const response = await getUserProfile(userId);
				const { success, message, data } = response;
				if (success === true) {
					setIsLoading(false);
					setUserData(data);
				} else {
					toast(message, { type: "error", autoClose: 3000 });
					setIsLoading(false);
				}
			} catch (err) {
				console.log("SERVER_ERROR::", err.message)
				setIsLoading(false);
			}
		};
		getUser();
	}, [userId]);

	const fullName =
		(userData?.first_name
			? userData?.first_name.charAt(0).toUpperCase() +
			  userData?.first_name.slice(1)
			: "") +
		(userData?.last_name
			? " " +
			  userData?.last_name.charAt(0).toUpperCase() +
			  userData?.last_name.slice(1)
			: "");

	return (
		<>
			{isLoading && (
				<Preloader showPreloader={isLoading} setShowPreloader={setIsLoading} />
			)}
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div> </div>

				<div className="d-flex">
					<Button
						as={Link}
						to={URLs.Calendar.path}
						variant="gray-800"
						className="me-2"
					>
						<CalendarIcon className="icon icon-xs" />
					</Button>
					<Dropdown>
						<Dropdown.Toggle as={Button} variant="gray-800">
							<ClipboardListIcon className="icon icon-xs me-2" />
							Reports
							<ChevronDownIcon className="icon icon-xs ms-1" />
						</Dropdown.Toggle>
						<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
							<Dropdown.Divider as="div" className="my-1" />

							<Dropdown.Item className="d-flex align-items-center">
								<ClipboardListIcon className="dropdown-icon text-gray-800 me-2" /> All
								Reports
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>

			<Row>
				<Col xs={12} xl={8}>
					<ProfileInfoForm user={userData} />
				</Col>

				<Col xs={12} xl={4}>
					<Row>
						<Col xs={12} className="mb-4">
							<ProfileUserCard
								user={userData}
								fullName={fullName}
								coverPhoto={
									userData?.cover_image ? userData?.cover_image : CoverPhotoPlaceHolder
								}
								picture={
									userData?.profile_image ? userData?.profile_image : AvatarPlaceholder
								}
								jobTitle={userData?.job_title ? userData?.job_title : ""}
								location={userData?.address ? userData?.address : ""}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default Profile;
