import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const Preloader = (props) => {
	const { showPreloader, setShowPreloader } = props;

	return (
		<>
			<Modal
				as={Modal.Dialog}
				centered
				show={showPreloader}
				onHide={() => setShowPreloader(!showPreloader)}
			>
				<Modal.Body className="d-flex flex-column align-items-center justify-content-center pb-4">
					<Spinner
						animation="border"
						role="status"
						variant="primary"
						className="mb-2"
					/>
					<span className="text-center text-success">
						{props.info ? props.info : "Fetching data ..."}
					</span>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Preloader;
