import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { CalendarIcon } from "@heroicons/react/solid";
import {
	Col,
	Row,
	Card,
	Form,
	Button,
	InputGroup,
	Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { updateUserData } from "services/userService";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";

export const SettingsForm = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);
	const { message } = useSelector((state) => state.message);

	const [userData, setUserData] = useState(user);
	
	useEffect(() => { 
		setUserData(user);
	 }, [user]);

	const [firstName, setFirstName] = useState(userData?.first_name || "");
	const [lastName, setLastName] = useState(userData?.last_name || "");
	const [dob, setDob] = useState(userData?.dob || "");
	const [gender, setGender] = useState(userData?.gender || "");
	const [phone, setPhone] = useState(userData?.phone || "");
	const [email, setEmail] = useState(userData?.email || "");
	const [houseNumber, setHouseNumber] = useState(userData?.house_number || "");
	const [address, setAddress] = useState(userData?.address || "");
	const [city, setCity] = useState(userData?.city || "");
	const [userState, setUserState] = useState(userData?.state || "");
	const [zip, setZip] = useState(userData?.zip || "");
	const [title, setTitle] = useState(userData?.title || "");
	const [jobTitle, setJobTitle] = useState(userData?.job_title || "");

	function capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	const formData = {
		first_name: firstName,
		last_name: lastName,
		dob: dob,
		gender: gender,
		phone: phone,
		address: address,
		house_number: houseNumber,
		city: city,
		state: userState,
		zip: zip,
		job_title: jobTitle,
		title: title,
	};

	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
			const response = await updateUserData(formData);
			if (response.success === true) {
				setLoading(false);
				dispatch(getUser());
				toast(response.message, { type: "success", autoClose: 3000 });
			} else {
				toast(response.message, { type: "error", autoClose: 3000 });
				setLoading(false);
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message)
			setLoading(false);
		}
	};

	useEffect(() => {
		if (message) {
			const timeout = setTimeout(() => {
				dispatch({ type: CLEAR_MESSAGE });
			}, 1000);

			return () => clearTimeout(timeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message]);

	const titles = ["Mr", "Ms", "Mrs", "Dr", "Prof"]; // List of title options

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<h5 className="mb-4">General information</h5>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="firstName">
								<Form.Label>First Name</Form.Label>
								<Form.Control
									type="text"
									value={capitalize(firstName)}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="lastName">
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									type="text"
									value={capitalize(lastName)}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						{/* <Col md={6} className="mb-3">
							<Form.Group id="title">
								<Form.Label>Title</Form.Label>
								<Form.Control
									type="text"
									value={capitalize(title)}
									placeholder="Enter your title"
									onChange={(e) => setTitle(e.target.value)}
								/>
							</Form.Group>
						</Col> */}
						<Col md={6} className="mb-3">
							<Form.Group id="title">
								<Form.Label>Title</Form.Label>
								<Form.Select value={title} onChange={(e) => setTitle(e.target.value)}>
									<option value={title}>{title ? capitalize(title) : "Select title"  }</option>{" "}
									{/* Add an empty default option */}
									<option disabled>──────────</option>
									{titles.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="jobTitle">
								<Form.Label>Job Title</Form.Label>
								<Form.Control
									type="text"
									value={capitalize(jobTitle)}
									placeholder="Enter job title"
									onChange={(e) => setJobTitle(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col md={6} className="mb-3">
							<Form.Group id="birthday">
								<Form.Label>Birthday</Form.Label>
								<Datetime
									timeFormat={false}
									onChange={setDob}
									renderInput={(props, openCalendar) => (
										<InputGroup>
											<InputGroup.Text>
												<CalendarIcon className="icon icon-xs" />
											</InputGroup.Text>
											<Form.Control
												type="text"
												value={dob ? moment(dob).format("DD/MM/YYYY") : ""}
												placeholder="dd/mm/yyyy"
												onFocus={openCalendar}
												onChange={(e) => setDob(e.target.value)}
											/>
										</InputGroup>
									)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="gender">
								<Form.Label>Gender</Form.Label>
								<Form.Select
									value={gender}
									placeholder="0"
									className="mb-0"
									onChange={(e) => setGender(e.target.value)}
								>
									<option value="0">Gender</option>
									<option value="Male">Female</option>
									<option value="Female">Male</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="email">
								<Form.Label>Email</Form.Label>
								<Form.Control
									readOnly
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="phone">
								<Form.Label>Phone</Form.Label>
								<Form.Control
									type="number"
									value={phone}
									placeholder="+234 0000 0000"
									onChange={(e) => setPhone(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>

					<h5 className="my-4">Location</h5>
					<Row>
						<Col sm={9} className="mb-3">
							<Form.Group id="address">
								<Form.Label>Address</Form.Label>
								<Form.Control
									value={address}
									type="text"
									placeholder="Enter your home address"
									onChange={(e) => setAddress(e.target.value)}
								/>
							</Form.Group>
						</Col>
						<Col sm={3} className="mb-3">
							<Form.Group id="addressNumber">
								<Form.Label>House Number</Form.Label>
								<Form.Control
									type="number"
									value={houseNumber}
									placeholder="No."
									onChange={(e) => setHouseNumber(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={4} className="mb-3">
							<Form.Group id="city">
								<Form.Label>City</Form.Label>
								<Form.Control
									type="text"
									value={city}
									placeholder="City"
									onChange={(e) => setCity(e.target.value)}
								/>
							</Form.Group>
						</Col>
						<Col sm={4} className="mb-3">
							<Form.Group className="mb-2">
								<Form.Label>Select state</Form.Label>
								<Form.Select
									id="state"
									value={userState}
									placeholder="0"
									onChange={(e) => setUserState(e.target.value)}
								>
									<option value="0">State</option>
									<option value="Abia">Abia</option>
									<option value="Adamawa">Adamawa</option>
									<option value="Akwa Ibom">Akwa Ibom</option>
									<option value="Anambra">Anambra</option>
									<option value="Bauchi">Bauchi</option>
									<option value="Bayelsa">Bayelsa</option>
									<option value="Benue">Benue</option>
									<option value="Borno">Borno</option>
									<option value="Cross River">Cross River</option>
									<option value="Delta">Delta</option>
									<option value="Ebonyi">Ebonyi</option>
									<option value="Edo">Edo</option>
									<option value="Ekiti">Ekiti</option>
									<option value="Enugu">Enugu</option>
									<option value="Gombe">Gombe</option>
									<option value="Imo">Imo</option>
									<option value="Jigawa">Jigawa</option>
									<option value="Kaduna">Kaduna</option>
									<option value="Kano">Kano</option>
									<option value="Katsina">Katsina</option>
									<option value="Kebbi">Kebbi</option>
									<option value="Kogi">Kogi</option>
									<option value="Kwara">Kwara</option>
									<option value="Lagos">Lagos</option>
									<option value="Nasarawa">Nasarawa</option>
									<option value="Niger">Niger</option>
									<option value="Ogun">Ogun</option>
									<option value="Ondo">Ondo</option>
									<option value="Osun">Osun</option>
									<option value="Oyo">Oyo</option>
									<option value="Plateau">Plateau</option>
									<option value="Rivers">Rivers</option>
									<option value="Sokoto">Sokoto</option>
									<option value="Taraba">Taraba</option>
									<option value="Yobe">Yobe</option>
									<option value="Zamfara">Zamfara</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col sm={4}>
							<Form.Group id="zip">
								<Form.Label>ZIP</Form.Label>
								<Form.Control
									type="tel"
									value={zip}
									placeholder="ZIP"
									onChange={(e) => setZip(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<div className="mt-3">
						<Button
							variant="gray-800"
							type="submit"
							className="mt-2 animate-up-2"
							onClick={handleSubmit}
							disabled={loading}
						>
							{loading ? (
								<Spinner animation="border" size="sm" className="mr-2" />
							) : null}
							Save All
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};
