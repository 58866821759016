import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { CSSTransition } from "react-transition-group";
import {
	CalendarIcon,
	ChartPieIcon,
	ChevronRightIcon,
	ClipboardListIcon,
	CogIcon,
	FolderAddIcon,
	HomeIcon,
	InboxIcon,
	OfficeBuildingIcon,
	UserGroupIcon,
	ViewGridIcon,
	XIcon,
} from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";

import {
	Nav,
	Badge,
	Image,
	Button,
	Dropdown,
	Navbar,
	Collapse,
	Col,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { URLs } from "routes";
import AmeboLogo from "assets/img/Amebo-Logo.png";
import AvatarPlaceholder from "assets/img/avatar_placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/authActions";
import { realtimeUpdate } from "shared/socket";

const Sidebar = (props = {}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = location;
	const { user } = useSelector((state) => state.user);

	const [show, setShow] = useState(false);
	const [collapsedItems, setCollapsedItems] = useState([pathname]);
	const contracted = props.contracted ? "contracted" : "";
	const showClass = show ? "show" : "";

	const logoutHandler = () => {
		dispatch(logout(navigate));
	};

	const onCollapse = () => setShow(!show);
	// const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
	// const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

	const onNavItemCollapse = (itemKey) => {
		const isCollapsed = collapsedItems.some((item) => item.includes(itemKey));
		const newCollapsedItems = isCollapsed
			? collapsedItems.filter((item) => !item.includes(itemKey))
			: [...collapsedItems, itemKey];
		setCollapsedItems(newCollapsedItems);
	};

	// const events = isMobile ? {} : { onMouseEnter, onMouseLeave };
	const events = isMobile ? {} : {};

	const CollapsableNavItem = (props) => {
		const { eventKey, title, icon: NavItemIcon, children = null } = props;
		const isOpened = collapsedItems.some((item) => item.includes(eventKey));

		return (
			<Nav.Item>
				<Nav.Link
					onClick={() => onNavItemCollapse(eventKey)}
					aria-expanded={isOpened}
					aria-controls={eventKey}
					className="d-flex justify-content-between align-items-center"
				>
					<span>
						<span className="sidebar-icon">
							<NavItemIcon className="icon icon-xs me-2" />
						</span>
						<span className="sidebar-text">{title}</span>
					</span>
					<span className="link-arrow">
						<ChevronRightIcon className="icon icon-sm" />
					</span>
				</Nav.Link>
				<Collapse in={isOpened} className="multi-level">
					<div id={eventKey}>{children}</div>
				</Collapse>
			</Nav.Item>
		);
	};

	const NavItem = (props) => {
		const {
			title,
			link,
			target,
			icon: NavItemIcon,
			image,
			badgeText,
			badgeBg,
			badgeColor = "white",
		} = props;
		const classNames = badgeText
			? "d-flex align-items-center justify-content-between"
			: "";
		const navItemClassName = link === pathname ? "active" : "";

		return (
			<Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
				<Nav.Link as={Link} to={link} target={target} className={classNames}>
					<span>
						{NavItemIcon && (
							<span className="sidebar-icon">
								<NavItemIcon className="icon icon-xs me-2" />
							</span>
						)}

						{image ? (
							<Image
								src={image}
								width={20}
								height={20}
								className="sidebar-icon svg-icon"
							/>
						) : null}

						{!show && contracted && !NavItemIcon && !image ? (
							<span className="sidebar-text-contracted">{title[0]}</span>
						) : null}

						<span className="sidebar-text">{title}</span>
					</span>

					{badgeText ? (
						<Badge
							pill
							bg={badgeBg}
							text={badgeColor}
							className="badge-sm notification-count"
						>
							{badgeText}
						</Badge>
					) : null}
				</Nav.Link>
			</Nav.Item>
		);
	};

	const [newMessageCount, setNewMessageCount] = useState(0);

	useEffect(() => {

		realtimeUpdate.on("new-message", (data) => {
			if (data) {
				setNewMessageCount(newMessageCount + 1);
			}
		})

	}, [newMessageCount]);

	return (
		<>
			<Navbar
				as={Col}
				xs={12}
				expand={false}
				collapseOnSelect
				variant="dark"
				className="navbar-theme-primary px-4 d-lg-none"
			>
				<Navbar.Brand as={Link} to={URLs.Home.path} className="me-lg-5">
					<Image src={AmeboLogo} className="navbar-brand-dark" />
				</Navbar.Brand>
				<div className="d-flex align-items-center">
					<Navbar.Toggle as={Button} onClick={onCollapse}>
						<span className="navbar-toggler-icon" />
					</Navbar.Toggle>
				</div>
			</Navbar>
			<CSSTransition timeout={300} in={show} classNames="sidebar-transition">
				<SimpleBar
					{...events}
					className={`${contracted} ${showClass} sidebar d-lg-block bg-gray-800 text-white collapse`}
				>
					<div className="sidebar-inner px-4 pt-3">
						<div className="user-card d-flex d-md-none justify-content-between justify-content-md-center pb-4">
							<div className="d-flex align-items-center">
								<div className="avatar-lg me-4">
									{user?.profile_image ? (
										<Image
											src={user?.profile_image}
											className="card-img-top rounded-circle border-white"
										/>
									) : (
										<Image
											src={AvatarPlaceholder}
											className="card-img-top rounded-circle border-white"
										/>
									)}{" "}
								</div>
								<div className="d-block">
									<h5 className="mb-3">
										{user?.first_name &&
											user?.first_name.charAt(0).toUpperCase() +
												user?.first_name.slice(1)}{" "}
										{user?.last_name &&
											user?.last_name.charAt(0).toUpperCase() +
												user?.last_name.slice(1)}{" "}
									</h5>
									<Button
										as={Link}
										variant="secondary"
										size="sm"
										to={URLs.Signin.path}
										className="d-inline-flex align-items-center"
										onClick={() => logoutHandler()}
									>
										<LogoutIcon className="icon icon-xxs me-1" /> Logout
									</Button>
								</div>
							</div>
							<Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
								<XIcon className="icon icon-xs" />
							</Nav.Link>
						</div>
						<Nav className="flex-column pt-3 pt-md-0">
							<NavItem title="Amebo Board" link={URLs.Home.path} image={AmeboLogo} />
							{user?.role === "admin" || user?.role === "master" || user?.role === "tech" ? (
								<CollapsableNavItem
									eventKey="dashboard/"
									title="Dashboard"
									icon={ViewGridIcon}
								>
									<NavItem
										title="Members"
										icon={OfficeBuildingIcon}
										link={URLs.DashboardMembers.path}
									/>
									<NavItem
										title="Teams"
										icon={UserGroupIcon}
										link={URLs.DashboardTeams.path}
									/>
									<NavItem
										title="Projects"
										icon={FolderAddIcon}
										link={URLs.DashboardProjects.path}
									/>
									{user?.role === "master" || user?.role === "tech" ? (
										<NavItem
											title="Analytics"
											icon={ChartPieIcon}
											link={URLs.DashboardAnalytics.path}
										/>
									) : null}
								</CollapsableNavItem>
							) : null}

							<NavItem title="Home" icon={HomeIcon} link={URLs.Home.path} />
							<NavItem
								title="My Task"
								icon={ClipboardListIcon}
								link={URLs.Projects.path}
							/>
							<NavItem
								title="My Team"
								icon={UserGroupIcon}
								link={URLs.TeamMembers.path}
							/>
							<NavItem
								title="Messages"
								icon={InboxIcon}
								// badgeText={`${newMessageCount}`}
								// badgeBg="danger"
								link={URLs.Messages.path}
							/>
							<NavItem title="Settings" icon={CogIcon} link={URLs.Settings.path} />
							<NavItem
								title="Calendar"
								icon={CalendarIcon}
								link={URLs.Calendar.path}
							/>
							<Nav.Item >
								<Nav.Link
									onClick={() => logoutHandler()}
									className="d-flex justify-content-between align-items-center"
								>
									<span>
										<span className="sidebar-icon">
											<LogoutIcon className="icon icon-xs me-2" />
										</span>
										<span className="sidebar-text">Logout</span>
									</span>
								</Nav.Link>
							</Nav.Item>
							<Dropdown.Divider className="my-3 border-indigo" />
						</Nav>
					</div>
				</SimpleBar>
			</CSSTransition>
		</>
	);
};

export default Sidebar;
