import React, { useState } from "react";
import {
	ArchiveIcon,
	DotsHorizontalIcon,
	EyeIcon,
	PencilAltIcon,
	TrashIcon,
	UserGroupIcon,
} from "@heroicons/react/solid";
import { 
	Col,
	Card,
	Button,
	Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { URLs } from "routes";
import MoveProjectModal from "./MoveProjectModal";
import EditProjectModal from "./EditProjectModal";
import AddTeamToProjectModal from "./AddTeamtoProjectModal";

const ProjectCard = (props) => {
	const { _id, name, suspended, done, archived, description, getProjects } =
		props;
	const [showMove, setShowMove] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showAddTeam, setShowAddTeam] = useState(false);

	const projectDelete = () => {
		props.projectDelete && props.projectDelete(_id);
	};

	return (
		<>
			<Card border="bottom" className="hover-state rounded-0 rounded-top py-3">
				<Card.Body className="d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0">
					<Col xs={11} lg={10} className="px-0 mb-4 mb-md-0">
						<div className="mb-2">
							<h5 className="">{name}</h5>
						</div>
						<div className="fw-bold text-dark">
							<span className={`fw-normal text-gray`}>{description}</span>
						</div>
					</Col>
					<Col
						xs={10}
						sm={1}
						lg={1}
						xl={1}
						className="d-flex align-items-center ms-lg-auto text-right justify-content-center px-md-0"
					>
						<Dropdown className="d-flex justify-content-end">
							<Dropdown.Toggle
								as={Button}
								variant="link"
								className="text-dark dropdown-toggle-split m-0 p-0"
							>
								<DotsHorizontalIcon className="icon icon-xs icon-dark" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
								<Dropdown.Item
									variant="link"
									className="d-flex align-items-center text-purple"
									as={Link}
									to={URLs.Kanban.path + "/" + _id}
								>
									<EyeIcon className="dropdown-icon  me-2" /> View Project
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center text-success"
									onClick={() => setShowEdit(!showEdit)}
								>
									<PencilAltIcon className="dropdown-icon me-2" /> Edit
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center text-success"
									onClick={() => setShowAddTeam(!showAddTeam)}
								>
									<UserGroupIcon className="dropdown-icon me-2" /> Add Team
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center text-gray-500"
									onClick={() => setShowMove(!showMove)}
								>
									<ArchiveIcon className="dropdown-icon me-2" /> Move
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center text-danger"
									onClick={projectDelete}
								>
									<TrashIcon className="dropdown-icon me-2" /> Delete
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Card.Body>
			</Card>
			<MoveProjectModal
				id={_id}
				showModal={showMove}
				setShowModal={setShowMove}
				suspended={suspended}
				done={done}
				archived={archived}
				getProjects={getProjects}
			/>

			<EditProjectModal
				{...props}
				showModal={showEdit}
				setShowModal={setShowEdit}
			/>
			<AddTeamToProjectModal
				{...props}
				showModal={showAddTeam}
				setShowModal={setShowAddTeam}
			/>
		</>
	);
};

export default ProjectCard;
