export const URLs = {
	// pages
	Presentation: { path: "/" },
	DashboardMembers: { path: "/dashboard/members" },
	DashboardTeams: { path: "/dashboard/teams" },
	DashboardProjects: { path: "/dashboard/projects" },
	DashboardTraffic: { path: "/dashboard/traffic-and-engagement" },
	DashboardAnalytics: { path: "/dashboard/analytics" },
	Kanban: { path: "/project-board/kanban" },
	Home: { path: "/home" },
	Repositories: { path: "/project/repositories" },
	Messages: { path: "/messages" },
	Message: { path: "/message" },
	GroupChat: { path: "/chats" },
	Users: { path: "/users" },
	Profile: { path: "/user/profile" },
	Tasks: { path: "/tasks" },
	Projects: { path: "/projects" },
	TeamMembers: { path: "/team-members" },
	ViewOrgTeamMembers: { path: "/all-team-members" },
	Settings: { path: "/settings" },
	Calendar: { path: "/calendar" },
	Map: { path: "/map" },
	Datatables: { path: "/tables/datatables" },
	BootstrapTables: { path: "/tables/bootstrap-tables" },
	Signin: { path: "/auth/sign-in" },
	Signup: { path: "/auth/sign-up" },
	ForgotPassword: { path: "/auth/forgot-password" },
	VerifyToken: { path: "/auth/verify-token" },
	VerifyAccount: { path: "/auth/verify-account" },
	ResetPassword: { path: "/auth/reset-password" },
	Lock: { path: "/examples/lock" },
	NotFound: { path: "/error/404" },
	ServerError: { path: "/error/500" },

	// docs
	DocsOverview: { path: "/documentation/overview" },
	DocsDownload: { path: "/documentation/download" },
	DocsQuickStart: { path: "/documentation/quick-start" },
	DocsLicense: { path: "/documentation/license" },
	DocsFolderStructure: { path: "/documentation/folder-structure" },
	DocsBuild: { path: "/documentation/build-tools" },
	DocsChangelog: { path: "/documentation/changelog" },

	// plugins
	PluginCharts: { path: "/plugins/charts" },
	PluginCalendar: { path: "/plugins/calendar" },
	PluginDatatable: { path: "/plugins/datatable" },
	PluginMap: { path: "/plugins/map" },
	PluginDropzone: { path: "/plugins/dropzone" },
	PluginSweetAlert: { path: "/plugins/sweet-alert" },

	// components
	Accordions: { path: "/components/accordions" },
	Alerts: { path: "/components/alerts" },
	Badges: { path: "/components/badges" },
	Widgets: { path: "/widgets" },
	Breadcrumbs: { path: "/components/breadcrumbs" },
	Buttons: { path: "/components/buttons" },
	Forms: { path: "/components/forms" },
	Modals: { path: "/components/modals" },
	Navs: { path: "/components/navs" },
	Navbars: { path: "/components/navbars" },
	Pagination: { path: "/components/pagination" },
	Popovers: { path: "/components/popovers" },
	Progress: { path: "/components/progress" },
	Tables: { path: "/components/tables" },
	Tabs: { path: "/components/tabs" },
	Tooltips: { path: "/components/tooltips" },
	Toasts: { path: "/components/toasts" },
	WidgetsComponent: { path: "/components/widgets" },
};
