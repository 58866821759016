import React, { useState, useEffect, useCallback } from "react";
import { ChatAlt2Icon, UserGroupIcon } from "@heroicons/react/solid";
import { Col, Row, Button, Pagination } from "react-bootstrap";
import MessageCard from "components/MessageCard";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "services/userService";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";
import { socket, realtimeUpdate } from "shared/socket";

const Messages = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);
	const [chats, setChats] = useState([]);
	const [chatData, setChatData] = useState([]);
	const [members, setMembers] = useState([]);
	const [showActiveChatUI, setShowActiveChatUI] = useState(false);

	useEffect(() => {
		socket.emit("fetch-active-users", user?._id);
		socket.on("active-users", (users) => {
			console.log("check_1", users);
			setChats(users);
		});

		return () => {
			socket.off("active-users");
		};
	}, [user?._id]);

	useEffect(() => {
		realtimeUpdate.on("user-updated", (data) => {
			if (data) {
				socket.emit("fetch-active-users", user?._id);
				socket.on("active-users", (users) => {
					setChats(users);
				});
			}
		});

		return () => {
			socket.off("active-users");
		};
	}, [user?._id]);

	const getMembers = useCallback(async () => {
		try {
			const { success, data, message } = await getAllUsers();
			if (success === true) {
				// Removing the current user from the array of users
				const fixedMembers = data?.filter((m) => m?._id !== user?._id);
				setMembers(fixedMembers?.map((m) => ({ ...m, show: true })));
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, [user?._id]);

	useEffect(() => {
		getMembers();
	}, [getMembers]);

	useEffect(() => {
		realtimeUpdate.on("user-updated", (data) => {
			if (data) {
				getMembers();
				dispatch(getUser());
			}
		});
	}, [dispatch, getMembers]);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = chatData?.slice(indexOfFirstItem, indexOfLastItem);

	const [currentMemberPage, setCurrentMemberPage] = useState(1);
	const [membersPerPage, setMembersPerPage] = useState(10);

	const indexOfLastMember = currentMemberPage * membersPerPage;
	const indexOfFirstMember = indexOfLastMember - membersPerPage;
	const currentMembers = members?.slice(indexOfFirstMember, indexOfLastMember);

	// Change page
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	const handleMemberPageChange = (pageNumber) => {
		setCurrentMemberPage(pageNumber);
	};
	const toggleUI = () => {
		setShowActiveChatUI(!showActiveChatUI);
	};

	// ========================== GET Chats =====================================
	useEffect(() => {
		// Removing the current user from the array of chats
		const fixedChats = chats?.filter((u) => u?._id !== user?._id);
		console.log("check_3", fixedChats);
		setChatData(fixedChats.map((c) => ({ ...c, show: true })));
	}, [chats, user]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center pb-2 pt-4 pb-md-4">
				<div>
					<Button
						disabled={!showActiveChatUI}
						variant="secondary"
						className="d-inline-flex align-items-center mb-3 mb-md-0"
						onClick={toggleUI}
					>
						<UserGroupIcon className="icon icon-xs me-2" />
						Members
					</Button>
					<Button
						disabled={showActiveChatUI}
						variant="secondary"
						className="d-inline-flex align-items-center ms-2 mb-3 mb-md-0"
						onClick={toggleUI}
					>
						<ChatAlt2Icon className="icon icon-xs me-2" />
						Active Chat(s)
					</Button>
				</div>
			</div>

			{showActiveChatUI && (
				<div className="message-wrapper border-0 bg-white shadow rounded mb-4">
					{currentItems?.map((chatData) => (
						<MessageCard
							chatData={chatData}
							key={`message-${chatData?._id}`}
							chatId={chatData?._id}
							showActiveChatUI={showActiveChatUI}
						/>
					))}
					<Row className="p-4">
						<Col className="d-flex align-items-center mb-3 mb-md-0">
							<span className="me-2">Page:</span>
							<Pagination className="mb-0">
								<Pagination.Prev
									disabled={currentPage === 1}
									onClick={() => handlePageChange(currentPage - 1)}
								>
									Prev
								</Pagination.Prev>
								{Array.from(
									{ length: Math.ceil(chatData.length / itemsPerPage) },
									(_, i) => (
										<Pagination.Item
											key={i}
											active={i + 1 === currentPage}
											onClick={() => handlePageChange(i + 1)}
										>
											{i + 1}
										</Pagination.Item>
									),
								)}
								<Pagination.Next
									disabled={currentPage === Math.ceil(chatData.length / itemsPerPage)}
									onClick={() => handlePageChange(currentPage + 1)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Col>
						<Col className="d-flex align-items-center mb-3 mb-md-0">
							<span className="me-2">Show:</span>
							<select
								className="form-select form-select-sm"
								style={{ width: "80px", paddingRight: "1rem" }}
								onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
								value={itemsPerPage}
							>
								<option value={5}>5</option>
								<option value={10}>10</option>
								<option value={15}>15</option>
							</select>
							<span className="mx-2">entries</span>
						</Col>
						<Col className="d-flex align-items-center">
							<span className="me-2">Showing:</span>
							<span className="fw-normal">
								{currentItems.length} out of {chatData.length} entries
							</span>
						</Col>
					</Row>
				</div>
			)}
			{!showActiveChatUI && (
				<div className="message-wrapper border-0 bg-white shadow rounded mb-4">
					{currentMembers?.map((memberData) => (
						<MessageCard
							chatData={memberData}
							key={`message-${memberData?._id}`}
							chatId={memberData?._id}
							showActiveChatUI={showActiveChatUI}
						/>
					))}

					<Row className="p-4">
						<Col className="d-flex align-items-center mb-3 mb-md-0">
							<span className="me-2">Page:</span>
							<Pagination className="mb-0">
								<Pagination.Prev
									disabled={currentMemberPage === 1}
									onClick={() => handleMemberPageChange(currentMemberPage - 1)}
								>
									Prev
								</Pagination.Prev>
								{Array.from(
									{ length: Math.ceil(members?.length / membersPerPage) },
									(_, i) => (
										<Pagination.Item
											key={i}
											active={i + 1 === currentMemberPage}
											onClick={() => handleMemberPageChange(i + 1)}
										>
											{i + 1}
										</Pagination.Item>
									),
								)}
								<Pagination.Next
									disabled={
										currentMemberPage === Math.ceil(members.length / membersPerPage)
									}
									onClick={() => handleMemberPageChange(currentMemberPage + 1)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Col>
						<Col className="d-flex align-items-center mb-3 mb-md-0">
							<span className="me-2">Show:</span>
							<select
								className="form-select form-select-sm"
								style={{ width: "80px", paddingRight: "1rem" }}
								onChange={(e) => setMembersPerPage(parseInt(e.target.value))}
								value={membersPerPage}
							>
								<option value={5}>5</option>
								<option value={10}>10</option>
								<option value={15}>15</option>
							</select>
							<span className="mx-2">entries</span>
						</Col>
						<Col className="d-flex align-items-center">
							<span className="me-2">Showing:</span>
							<span className="fw-normal">
								{currentMembers.length} out of {members.length} entries
							</span>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

export default Messages;
