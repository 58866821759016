import { ClockIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import {
	Button,
	Col,
	Form,
	InputGroup,
	Modal,
	Row,
	Spinner,
} from "react-bootstrap";
import moment from "moment-timezone";
import { CalendarIcon } from "@heroicons/react/solid";
import Datetime from "react-datetime";
import { toast } from "react-toastify";
import { updateEvent } from "services/calendarService";

const EditEventModal = (props) => {
	const { showEdit, setShowEdit, data } = props;
	const [organizer, setOrganizer] = useState(data?.organizer);
	const [description, setDescription] = useState(data?.description);
	const [location, setLocation] = useState(data?.location);
	const [address, setAddress] = useState(data?.address);
	const [startTime, setStartTime] = useState(data?.startTime);
	const [stopTime, setStopTime] = useState(data?.stopTime);
	const [start, setStart] = useState(data?.start);
	const [stop, setStop] = useState(data?.end);
	const [title, setTitle] = useState(data?.title);
	const [allDay, setAllDay] = useState(data?.allDay);
	const [colorClassNames, setColorClassNames] = useState(data?.classNames.join(" "));
	const [loading, setLoading] = useState(false);

	const eventData = {
		organizer: organizer,
		location: location,
		address: address,
		startTime: startTime,
		stopTime: stopTime,
		start: start,
		end: stop,
		title: title,
		description: description,
		allDay: allDay,
		classNames: colorClassNames,
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
			const response = await updateEvent(props.id, eventData);
			const { success, message } = response;
			if (success === true) {
				toast(message, { type: "success", autoClose: 2000 });
				setShowEdit(false);
				setLoading(false);
			} else {
				toast(message, { type: "error", autoClose: 2000 });
				setShowEdit(false);
				setLoading(false);
			}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setShowEdit(false);
			setLoading(false);
		}
	};

	return (
		<>
			<Modal
				as={Modal.Dialog}
				centered
				scrollable
				show={showEdit}
				onHide={() => setShowEdit(false)}
			>
				<Form className="modal-content p-3">
					<Modal.Header className="pb-0 border-0">
						<h5 as={Modal.Title} className="fw-normal">
							Edit Event
						</h5>
						<Button variant="close" onClick={() => setShowEdit(false)} />
					</Modal.Header>
					<Modal.Body className="pb-0">
						<Form.Group id="title" className="mb-3">
							<Form.Control
								autoFocus
								type="text"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								placeholder={`Enter a title for this`}
							/>
						</Form.Group>

						<Form.Group id="description" className="mb-3">
							<Form.Control
								required
								multiple
								as="textarea"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								placeholder={`Enter a description for this`}
							/>
						</Form.Group>
						<Form.Group id="organizer" className="mb-3">
							<Form.Control
								required
								multiple
								as="textarea"
								value={organizer}
								onChange={(e) => setOrganizer(e.target.value)}
								placeholder={`Enter name of organizer`}
							/>
						</Form.Group>
						<Form.Group id="location" className="mb-3">
							<Form.Control
								required
								multiple
								as="textarea"
								value={location}
								onChange={(e) => setLocation(e.target.value)}
								placeholder={`Enter location`}
							/>
						</Form.Group>
						<Form.Group id="address" className="mb-3">
							<Form.Control
								required
								multiple
								as="textarea"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								placeholder={`Enter address`}
							/>
						</Form.Group>
						<Row className="align-items-center">
							<Col md={6} className="mb-3">
								<Form.Group id="date1">
									<Form.Label>Start Date</Form.Label>
									<Datetime
										timeFormat={false}
										onChange={(date) => {
											if (date) {
												setStart(moment(date));
											} else {
												toast("Invalid date selected", { type: "error", autoClose: 3000 });
											}
										}}
										renderInput={(props, openCalendar) => (
											<InputGroup>
												<InputGroup.Text>
													<CalendarIcon className="icon icon-xs" />
												</InputGroup.Text>
												<Form.Control
													type="text"
													value={start ? moment(start).format("DD/MM/YYYY") : ""}
													placeholder="dd/mm/yyyy"
													onFocus={openCalendar}
													onChange={(e) => setStart(e.target.value)}
												/>
											</InputGroup>
										)}
									/>
								</Form.Group>
							</Col>
							<Col md={6} className="mb-3">
								<Form.Group id="date2">
									<Form.Label>End Date</Form.Label>
									<Datetime
										timeFormat={false}
										onChange={(date) => {
											if (date) {
												setStop(moment(date));
											} else {
												// Handle error or display a message indicating invalid date
												toast("Invalid date selected", { type: "error", autoClose: 3000 });
											}
										}}
										renderInput={(props, openCalendar) => (
											<InputGroup>
												<InputGroup.Text>
													<CalendarIcon className="icon icon-xs" />
												</InputGroup.Text>
												<Form.Control
													type="text"
													value={stop ? moment(stop).format("DD/MM/YYYY") : ""}
													placeholder="dd/mm/yyyy"
													onFocus={openCalendar}
													onChange={(e) => setStop(e.target.value)}
												/>
											</InputGroup>
										)}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row className="align-items-center">
							<Col md={6} className="mb-3">
								<Form.Group id="time" className="mb-3">
									<Form.Label>Start Time</Form.Label>
									<InputGroup>
										<InputGroup.Text>
											<ClockIcon className="icon icon-xs" />
										</InputGroup.Text>
										<Form.Control
											type="time"
											placeholder="Select time"
											value={startTime}
											onChange={(e) => setStartTime(e.target.value)}
										/>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={6} className="mb-3">
								<Form.Group id="time" className="mb-3">
									<Form.Label>Stop Time</Form.Label>
									<InputGroup>
										<InputGroup.Text>
											<ClockIcon className="icon icon-xs" />
										</InputGroup.Text>
										<Form.Control
											type="time"
											placeholder="Select time"
											value={stopTime}
											onChange={(e) => setStopTime(e.target.value)}
										/>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>

						<Row className="align-items-center">
							<Col md={6} className="mb-3">
								<Form.Group id="color" className="mb-3">
									<Form.Label>Color</Form.Label>
									<Form.Select
										onChange={(e) => setColorClassNames(e.target.value)}
										value={colorClassNames}
									>
										<option value="" disabled>
											Select a color
										</option>
										<option value="bg-blue border-0 shadow">Blue</option>
										<option value="bg-danger border-0 shadow">Red</option>
										<option value="bg-info border-0 shadow">Green</option>
										<option value="bg-yellow border-0 shadow">Yellow</option>
										<option value="bg-purple border-0 shadow">Purple</option>
									</Form.Select>
								</Form.Group>
							</Col>
							<Col md={6} className="mb-3">
								<Form.Group id="allDay" className="mb-3">
									<Form.Label>All Day</Form.Label>
									<Form.Select
										onChange={(e) => setAllDay(e.target.value)}
										value={allDay}
									>
										<option value="" disabled>
											Select Yes or No
										</option>
										<option value="true">Yes</option>
										<option value="false">No</option>
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className="justify-content-start border-0 pt-0">
						<Button variant="danger" onClick={() => data?.onDelete(data?.id)}>
							Delete event
						</Button>
						<Button
							variant="secondary"
							className="d-inline-flex align-items-center"
							onClick={handleSubmit}
							disabled={loading}
						>
							{loading ? (
								<Spinner animation="border" size="sm" className="mr-2" />
							) : null}
							Update Event
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default EditEventModal;
