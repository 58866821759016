import React from "react";
import {
	CalendarIcon,
	ChevronDownIcon,
	ClipboardListIcon,
} from "@heroicons/react/solid";
import { Col, Row, Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import AvatarPlaceholder from "assets/img/avatar_placeholder.png";
import CoverPhotoPlaceHolder from "assets/img/cover_photo_placeholder.png";

import { useSelector } from "react-redux";

import { URLs } from "routes";
import { SettingsForm } from "components/SettingsForm";
import PasswordChangeForm from "components/PasswordChangeForm ";
import SettingsUserCard from "components/SettingsUserCard";
import { ChooseProfilePhoto } from "components/ChooseProfilePhoto";
import { ChooseCoverPhoto } from "components/ChooseCoverPhoto";
import NotificationSettings from "components/NotificationSettings";

const Settings = () => {
	const { user } = useSelector((state) => state.user);
	const fullName =
		(user?.first_name
			? user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
			: "") +
		(user?.last_name
			? " " + user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1)
			: "");

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div></div>
				<div className="d-flex">
					<Button
						as={Link}
						to={URLs.Calendar.path}
						variant="gray-800"
						className="me-2"
					>
						<CalendarIcon className="icon icon-xs" />
					</Button>
					<Dropdown>
						<Dropdown.Toggle as={Button} variant="gray-800">
							<ClipboardListIcon className="icon icon-xs me-2" />
							Reports
							<ChevronDownIcon className="icon icon-xs ms-1" />
						</Dropdown.Toggle>
						<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
							<Dropdown.Divider as="div" className="my-1" />

							<Dropdown.Item className="d-flex align-items-center">
								<ClipboardListIcon className="dropdown-icon text-gray-800 me-2" /> All
								Reports
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>

			<Row>
				<Col xs={12} xl={8}>
					<SettingsForm />
					<PasswordChangeForm />
					<NotificationSettings />
				</Col>

				<Col xs={12} xl={4}>
					<Row>
						<Col xs={12} className="mb-4">
							<SettingsUserCard
								fullName={fullName}
								picture={user?.profile_image ? user.profile_image : AvatarPlaceholder}
								coverPhoto={
									user?.cover_image ? user.cover_image : CoverPhotoPlaceHolder
								}
								jobTitle={user?.job_title ? user.job_title : ""}
								location={user?.address ? user.address : ""}
							/>
						</Col>
						<Col xs={12}>
							<ChooseProfilePhoto
								title="Select profile photo"
								picture={user?.profile_image ? user.profile_image : AvatarPlaceholder}
							/>
						</Col>

						<Col xs={12} sm={6} xl={12}>
							<ChooseCoverPhoto
								title="Select cover photo"
								picture={user?.cover_image ? user.cover_image : CoverPhotoPlaceHolder}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default Settings;
