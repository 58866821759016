import httpService from "./httpService";

export const markAsRead = async (data) => {
	try {
		const response = await httpService.patch(
			`/notifications/${data}/mark-as-read`,
		);
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getMyNotifications = async (userId) => {
	let url = `/notifications/my-notifications`;
	if (userId) {
		url += `?id=${userId}`;
	}
	try {
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getUserNotifications = async (userId) => {
	let url = `/notifications/user-notifications/${userId}`;

	try {
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getTeamNotifications = async (id) => {
	try {
		const response = await httpService.get(`/notifications/team-notifications/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllNotifications = async () => {
	try {
		const response = await httpService.get(`/notifications/all-notifications`);
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
