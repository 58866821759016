import React from "react";
import { Routes, Route } from "react-router-dom";
import RouteWithSidebar from "RouteWithSidebar";
import { URLs } from "routes";
// pages
import Presentation from "pages/Presentation";
import DashboardMembers from "pages/dashboard/DashboardMembers";
import DashboardTeams from "pages/dashboard/DashboardTeams";
import DashboardProjects from "pages/dashboard/DashboardProjects";
import Kanban from "pages/Kanban";
import Projects from "./pages/Projects";
import TeamMembers from "./pages/TeamMembers";
import Messages from "pages/Messages";
import Message from "pages/Message";
import Profile from "pages/Profile";
import Settings from "pages/Settings";
import Calendar from "pages/Calendar";
import Signin from "pages/Auth/Signin";
// import Signup from "pages/Auth/Signup";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import Lock from "pages/Auth/Lock";
import NotFound from "pages/Error/NotFound";
import ServerError from "pages/Error/ServerError";
import VerifyToken from "./pages/Auth/VerifyToken";
import VerifyAccount from "./pages/Auth/VerifyAccount";
import Home from "pages/Home";
import GroupChat from "pages/GroupChat";
import Repositories from "pages/Repositories";
import TeamStats from "pages/TeamStats";
import StaffStats from "pages/StaffStats";
import OrgStats from "pages/dashboard/OrgStats";
import ViewTeamMembers from "pages/ViewTeamMembers";
import ViewOrgTeamMembers from "pages/ViewOrgTeamMembers";
import MemberProjects from "pages/MemberProjects";

const RouterEntry = () => {
	return (
		<Routes>
			<Route path={URLs.Presentation.path} element={<Presentation />} />
			<Route path={URLs.Signin.path} element={<Signin />} />
			{/* <Route path={URLs.Signup.path} element={<Signup />} /> */}
			<Route path={URLs.ForgotPassword.path} element={<ForgotPassword />} />
			<Route path={URLs.VerifyToken.path} element={<VerifyToken />} />
			<Route path={URLs.VerifyAccount.path} element={<VerifyAccount />} />
			<Route
				path={URLs.ResetPassword.path + "/:token"}
				element={<ResetPassword />}
			/>
			<Route path={URLs.Lock.path} element={<Lock />} />
			<Route path={URLs.NotFound.path} element={<NotFound />} />
			<Route path={URLs.ServerError.path} element={<ServerError />} />

			{/* pages */}
			<Route
				path={URLs.Home.path}
				element={
					<RouteWithSidebar>
						<Home />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.DashboardMembers.path}
				element={
					<RouteWithSidebar>
						<DashboardMembers />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.DashboardTeams.path}
				element={
					<RouteWithSidebar>
						<DashboardTeams />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.DashboardProjects.path}
				element={
					<RouteWithSidebar>
						<DashboardProjects />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.DashboardTeams.path + "/:teamId/stats"}
				element={
					<RouteWithSidebar>
						<TeamStats />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.DashboardMembers.path + "/:userId/stats"}
				element={
					<RouteWithSidebar>
						<StaffStats />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.DashboardAnalytics.path}
				element={
					<RouteWithSidebar>
						<OrgStats />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Kanban.path + "/:projectId"}
				element={
					<RouteWithSidebar>
						<Kanban />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Messages.path}
				element={
					<RouteWithSidebar>
						<Messages />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Message.path + "/:chatId"}
				element={
					<RouteWithSidebar>
						<Message />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.GroupChat.path}
				element={
					<RouteWithSidebar>
						<GroupChat />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Profile.path + "/:userId"}
				element={
					<RouteWithSidebar>
						<Profile />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Projects.path}
				element={
					<RouteWithSidebar>
						<Projects />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Projects.path + "/:memberId"}
				element={
					<RouteWithSidebar>
						<MemberProjects />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.TeamMembers.path}
				element={
					<RouteWithSidebar>
						<TeamMembers />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.TeamMembers.path + "/:teamId"}
				element={
					<RouteWithSidebar>
						<ViewTeamMembers />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.ViewOrgTeamMembers.path}
				element={
					<RouteWithSidebar>
						<ViewOrgTeamMembers />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Settings.path}
				element={
					<RouteWithSidebar>
						<Settings />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Repositories.path}
				element={
					<RouteWithSidebar>
						<Repositories />
					</RouteWithSidebar>
				}
			/>
			<Route
				path={URLs.Calendar.path}
				element={
					<RouteWithSidebar>
						<Calendar />
					</RouteWithSidebar>
				}
			/>
		</Routes>
	);
};

export default RouterEntry;