import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import {
	Button,
	Col,
	Form,
	Image,
	InputGroup,
	Modal,
	Row,
} from "react-bootstrap";
import { addTeamMember } from "services/teamService";
import { toast } from "react-toastify";
import { getAllUsers } from "services/userService";

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

const AddTeamMemberModal = (props) => {
	const [members, setMembers] = useState([]);
	const {
		showModal,
		setShowModal,
		teamID,
		membersData,
		setIsUpdated,
		isUpdated,
	} = props;
	const [searchValue, setSearchValue] = useState("");
	const [users, setUsers] = useState(
		members?.map((u) => ({
			...u,
			show: true,
			isAssignedToTeam: membersData?.some((m) => m?._id === u?._id),
		})),
	);

	const getMembers = async () => {
		try {
			const { success, data, message } = await getAllUsers();
			if (success === true) {
				setMembers(
					data?.map((u) => ({
						...u,
						show: true,
					})),
				);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	};

	useEffect(() => {
		getMembers();
	}, []);

	useEffect(() => {
		// Update the users state based on the members state
		const updatedUsers = members?.map((u) => ({
			...u,
			show: true,
			isAssignedToTeam: membersData?.some((m) => m?._id === u?._id),
		}));
		setUsers(updatedUsers);
	}, [members, membersData]);

	const onSearchValueChange = (e) => {
		const newSearchValue = e.target.value;
		const searchResults = users.map((u) => ({
			...u,
			show:
				u.first_name.toLowerCase().includes(newSearchValue.toLowerCase()) ||
				u.last_name.toLowerCase().includes(newSearchValue.toLowerCase()),
		}));

		setSearchValue(newSearchValue);
		setUsers(searchResults);
	};

	const onMemberClick = async (id) => {
		const boardMembersUpdated = users.map((m) =>
			m?._id === id ? { ...m, isAssignedToTeam: !m.isAssignedToTeam } : m,
		);
		setUsers(boardMembersUpdated);
		const payload = { teamId: teamID, userId: id };
		try {
			const response = await addTeamMember(payload);
			const { success, message } = response;
			if (success === true) {
				toast(message, {
					type: "success",
					autoClose: 3000,
				});
				getMembers();
				setShowModal(false);
				setIsUpdated(!isUpdated);
			} else {
				toast(message, {
					type: "error",
					autoClose: 3000,
				});
			}
		} catch (err) {
			toast(err.message, {
				type: "error",
				autoClose: 3000,
			});
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			scrollable
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Select the Team Members</Modal.Title>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="search" className="mb-3">
						<InputGroup className="search-bar">
							<Form.Control
								type="text"
								placeholder="Search team members..."
								value={searchValue}
								onChange={onSearchValueChange}
							/>
						</InputGroup>
					</Form.Group>

					<div className="px-3" style={{ overflowY: "scroll", maxHeight: "300px" }}>
						{users
							.filter((m) => m.show)
							.map((m) => (
								<Row
									key={`board-member-${m?._id}`}
									className="kanban-card-member border-bottom py-2"
									onClick={() => onMemberClick(m?._id)}
								>
									<Col xs={2}>
										{m?.profile_image ? (
											<Image src={m?.profile_image} className="avatar-md rounded-circle" />
										) : (
											<div className="avatar-md rounded-circle d-flex align-items-center justify-content-center fw-bold bg-secondary">
												<span>
													{getInitialsOfFirstTwoWords(
														`${m?.first_name.charAt(0).toUpperCase()}${m?.first_name.slice(
															1,
														)} ${m?.last_name.charAt(0).toUpperCase()}${m?.last_name.slice(
															1,
														)}`,
													)}
												</span>
											</div>
										)}
									</Col>
									<Col
										xs={8}
										className="d-flex align-items-center justify-content-start"
									>
										<h4 className="fs-6 text-dark mb-0">
											{m?.first_name &&
												m.first_name.charAt(0).toUpperCase() + m.first_name.slice(1)}{" "}
											{m?.last_name &&
												m.last_name.charAt(0).toUpperCase() + m.last_name.slice(1)}
										</h4>
									</Col>
									{m.isAssignedToTeam && (
										<Col xs={2} className="d-flex align-items-center">
											<CheckIcon className="icon icon-sm text-success" />
										</Col>
									)}
								</Row>
							))}
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pb-0"></Modal.Footer>
			</Form>
		</Modal>
	);
};

export default AddTeamMemberModal;
