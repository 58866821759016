import { getAllEvents } from "services/calendarService";
import { SET_MESSAGE } from "./messageActions";

export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";
export const CLEAR_EVENTS = "CLEAR_EVENTS";

// action creators
export const getEvents = () => async (dispatch) => {
	dispatch({ type: GET_EVENTS_REQUEST });
	try {
		const { success, message, data } = await getAllEvents();
		if (success === true) {
			dispatch({
				type: GET_EVENTS_SUCCESS,
				payload: data,
			});
		} else {
			dispatch({ type: GET_EVENTS_FAIL });
			dispatch({
				type: SET_MESSAGE,
				payload: { text: message, success: success },
			});
		}
	} catch (err) {
		dispatch({ type: GET_EVENTS_FAIL });
		dispatch({
			type: SET_MESSAGE,
			payload: { text: err.message, success: false },
		});
	}
};

