import React from "react";
import { Card, Image, Tooltip, OverlayTrigger } from "react-bootstrap";

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

const KanbanAvatar = (props) => {
	const { profile_image, first_name, last_name } = props;
	const name = `${first_name?.charAt(0).toUpperCase()}${first_name?.slice(
		1,
	)} ${last_name?.charAt(0).toUpperCase()}${last_name?.slice(1)}`;

	return (
		<OverlayTrigger
			placement="top"
			overlay={<Tooltip className="m-0">{name}</Tooltip>}
		>
			<Card.Link href={`#${name}`} className="avatar ms-0">
				{profile_image ? (
					<Image rounded src={profile_image} />
				) : (
					<div
						className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3"
						style={{ fontSize: "0.8rem" }}
					>
						<span>{getInitialsOfFirstTwoWords(name)}</span>
					</div>
				)}
			</Card.Link>
		</OverlayTrigger>
	);
};

export default KanbanAvatar;