import React, { useCallback, useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import { MentionsInput, Mention } from "react-mentions";
import {
	CalendarIcon,
	CheckIcon,
	ClipboardListIcon,
	ClockIcon,
	PaperClipIcon,
	PlusIcon,
	TagIcon,
	TrashIcon,
	UserAddIcon,
	UserGroupIcon,
	UserRemoveIcon,
	VolumeUpIcon,
} from "@heroicons/react/solid";
import {
	DocumentIcon,
	PhotographIcon,
	VideoCameraIcon,
	LinkIcon,
	PaperAirplaneIcon,
} from "@heroicons/react/outline";

import {
	Col,
	Row,
	Form,
	Modal,
	Button,
	InputGroup,
	Image,
	Badge,
	FloatingLabel,
	Spinner,
	Card,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";

import KanbanAvatar from "components/KanbanAvatar";
import { useSelector } from "react-redux";
import {
	assignSubTask,
	assignTask,
	changeDueDate,
	createSubTask,
	createTask,
	deleteComment,
	getSingleTask,
	postComment,
	unassignSubTask,
	unassignTask,
	updateTaskKpi,
	updateTaskPriority,
	updateTaskStatus,
	uploadAttachment,
} from "services/taskService";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import { useDispatch } from "react-redux";
import { getUser } from "redux/actions/userActions";
import Preloader from "./Preloader";
import { realtimeUpdate } from "shared/socket";
import { makeTextClickable } from "utils";

export const priorityTag = [
	{ id: 1, name: "Urgent", value: "urgent", color: "primary" },
	{ id: 2, name: "High Priority", value: "high", color: "danger" },
	{ id: 3, name: "Medium Priority", value: "medium", color: "warning" },
	{ id: 4, name: "Low Priority", value: "low", color: "yellow-200" },
];

export const KPITag = [
	{ id: 1, name: "Critical(20%)", value: "critical(20%)", color: "danger" },
	{ id: 2, name: "Important(15%)", value: "important(15%)", color: "warning" },
	{
		id: 3,
		name: "Suplementary(10%)",
		value: "suplementary(10%)",
		color: "primary",
	},
	{
		id: 4,
		name: "Suplementary(5%)",
		value: "suplementary(5%)",
		color: "yellow-200",
	},
];

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

export const KanbanCreateModal = (props) => {
	const {
		projectId,
		modalTitle = "Add new Task",
		type = "task",
		show = false,
		updateProject,
	} = props;
	const dispatch = useDispatch();
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [loading, setLoading] = useState(false);

	const onTitleChange = (e) => setTitle(e.target.value);
	const onDescriptionChange = (e) => setDescription(e.target.value);
	const onHide = () => props.onHide && props.onHide();

	useEffect(() => {
		realtimeUpdate.on("project-updated", (data) => {
			if (data) {
				updateProject();
			}
		});
	}, [updateProject]);

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!title) {
			toast("Please fill in task title", { type: "error", autoClose: 3000 });
			return;
		}

		const payload = { name: title, description };
		setLoading(true);
		try {
			const response = await createTask(projectId, payload);
			const { message } = response;
			toast(message, { type: "success", autoClose: 3000 });
			setLoading(false);
			updateProject();
			setTimeout(() => {
				setTitle("");
				setDescription("");
				onHide();
				dispatch(getUser());
			}, 2000);
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 3000 });
			setLoading(false);
			setTimeout(() => {
				onHide();
			}, 2500);
		}
	};

	return (
		<Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						{modalTitle}
					</h5>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Control
							autoFocus
							type="text"
							value={title}
							onChange={onTitleChange}
							placeholder={`Enter a title for this task…`}
						/>
					</Form.Group>
					{type === "task" && (
						<Form.Group id="description" className="mb-3">
							<Form.Control
								required
								autoFocus
								multiple
								as="textarea"
								value={description}
								onChange={onDescriptionChange}
								placeholder={`Enter a description for this task…`}
							/>
						</Form.Group>
					)}
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0">
					<Button variant="outline-gray-500" onClick={onHide}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={onSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						<PlusIcon className="icon icon-xs me-2" />
						Add Task
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanCopyModal = (props) => {
	const { type = "card", show = false, lists = [], ...otherProps } = props;
	const [title, setTitle] = useState(props.title ?? "");
	const [listId, setListId] = useState(props.listId ?? "");

	const onHide = () => {
		props.onHide && props.onHide();
	};

	const onSubmit = () => {
		const payload = { ...otherProps, title, listId };
		return props.onSubmit && props.onSubmit(payload);
	};

	return (
		<Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<Modal.Title className="fw-normal">Copy {type}</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Control
							autoFocus
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							onFocus={(e) => e.target.select()}
						/>
					</Form.Group>
					{type === "card" && (
						<FloatingLabel id="list" label="Copy to list.." className="mb-3">
							<Form.Select value={listId} onChange={(e) => setListId(e.target.value)}>
								{lists.map((l) => (
									<option value={l.id} key={`copy-card-option-${l.id}`}>
										{l.title}
									</option>
								))}
							</Form.Select>
						</FloatingLabel>
					)}
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0">
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={onSubmit}
					>
						<PlusIcon className="icon icon-xs me-2" />
						Create {type}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanMoveModal = (props) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { type = "card", show = false, lists = [] } = props;
	const [listId, setListId] = useState(props.listId ?? "");
	const [index, setIndex] = useState(props.listIndex ?? 0);
	const cardList = lists.find((l) => l.id === listId);

	const [task, setTask] = useState(null);
	useEffect(() => {
		setTask(cardList.tasks[index]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	const onHide = () => {
		props.onHide && props.onHide();
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const source = { droppableId: props.listId, index: props.listIndex };
		const destination = { droppableId: listId, index };

		let taskStatus;
		switch (destination.droppableId) {
			case "1":
				taskStatus = "todo";
				break;
			case "2":
				taskStatus = "inprogress";
				break;
			case "3":
				taskStatus = "inreview";
				break;
			case "4":
				taskStatus = "done";
				break;
			default:
				taskStatus = "";
		}
		setLoading(true);

		try {
			if (task) {
				const response = await updateTaskStatus(task?.id, {
					task_status: taskStatus,
				});
				const { message } = response;
				toast(message, { type: "success", autoClose: 3000 });

				dispatch(getUser());

				props.onSubmit && props.onSubmit({ source, destination });

				setLoading(false);
				setTimeout(() => {
					onHide();
				}, 3000);
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
			setLoading(false);
			onHide();
		}
	};

	return (
		<Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<Modal.Title className="fw-normal">Move {type}</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					{type === "card" ? (
						<>
							<FloatingLabel id="listId" label="List" className="mb-3">
								<Form.Select value={listId} onChange={(e) => setListId(e.target.value)}>
									{lists.map((l) => (
										<option value={l.id} key={`move-list-id-${l.id}`}>
											{l.id === props.listId ? `${l.title} (current)` : l.title}
										</option>
									))}
								</Form.Select>
							</FloatingLabel>
							{cardList && (
								<FloatingLabel id="listIndex" label="Position" className="mb-3">
									<Form.Select value={index} onChange={(e) => setIndex(e.target.value)}>
										{cardList.tasks.map((_, ind) => (
											<option value={ind} key={`move-list-index-${ind}`}>
												{ind + 1}
											</option>
										))}
									</Form.Select>
								</FloatingLabel>
							)}
						</>
					) : (
						<FloatingLabel id="listIndex" label="Position" className="mb-3">
							<Form.Select value={index} onChange={(e) => setIndex(e.target.value)}>
								{lists.map((_, ind) => (
									<option value={ind} key={`move-list-index-${ind}`}>
										{ind === props.index ? `${ind + 1} (current)` : ind + 1}
									</option>
								))}
							</Form.Select>
						</FloatingLabel>
					)}
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0">
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={onSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						Move {type}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanEditModal = (props) => {
	const dispatch = useDispatch();
	const {
		id: cardId,
		listId,
		show = false,
		updateProject,
		project,
		projectId,
	} = props;
	const { teams } = project;
	const [task, setTask] = useState(null);
	const [taskData, setTaskData] = useState(task);
	const [assignees, setAssignees] = useState([]);
	const [comments, setComments] = useState([]);
	const [author, setAuthor] = useState(null);
	const [subtasksCount, setSubtasksCount] = useState(0);
	const [members, setMembers] = useState([]);
	const [taggedMembers, setTaggedMembers] = useState([]);

	const getTeam = useCallback(() => {
		const allMembers = teams
			? teams
					.map((tm) => tm.members)
					.flat()
					.map((user) => ({
						id: user.email,
						display: user.email,
					}))
			: [];
		setMembers(
			allMembers?.map((tm) => ({
				...tm,
				show: true,
			})),
		);
	}, [teams]);

	useEffect(() => {
		getTeam();
	}, [getTeam]);

	const taskId = props?.id;
	const getTaskData = useCallback(async () => {
		try {
			const { data } = await getSingleTask(taskId);
			setTask(data);
			setAssignees(data?.assignees);
			const sortedComments = data?.comments.sort(
				(a, b) => new Date(b.createdAt) - new Date(a.createdAt),
			);
			setComments(sortedComments);
			setComments(data.comments);
			setAuthor(data?.author);
			setSubtasksCount(data?.sub_tasks?.length);

			dispatch(getUser());
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, [taskId, dispatch]);

	useEffect(() => {
		getTaskData();
	}, [getTaskData, taskId]);

	useEffect(() => {
		setTaskData(task);
	}, [task]);

	const [title, setTitle] = useState("");

	useEffect(() => {
		setTitle(props.title);
	}, [props.title]);

	const [comment, setComment] = useState("");
	// const [isTitleEditable, setIsTitleEditable] = useState(false);

	const author_name = `${author?.first_name
		.charAt(0)
		.toUpperCase()}${author?.first_name.slice(1)} ${author?.last_name
		.charAt(0)
		.toUpperCase()}${author?.last_name.slice(1)}`;

	// const toggleIsTitleEditable = () => {
	// 	setIsTitleEditable(!isTitleEditable);
	// };

	const onHide = () => {
		props.onHide && props.onHide();
	};

	const handleTag = (id) => {
		if (!taggedMembers.includes(id)) {
			setTaggedMembers((prev) => [...prev, id]);
		}
	};

	// const onChange = () => {
	// 	const payload = { listId, cardId, title };

	// 	if (title !== props.title) {
	// 		props.onChange && props.onChange(payload);
	// 	}

	// 	toggleIsTitleEditable();
	// };

	const onEditMembers = () => {
		props.onEditMembers &&
			props.onEditMembers({
				...props,
				updateTs: getTaskData,
				updateProject,
			});
	};

	const onEditLabels = () => {
		props.onEditLabels &&
			props.onEditLabels({
				...props,
				updateTs: getTaskData,
				updateProject,
			});
	};
	const onSetKPI = () => {
		props.onEditKpis &&
			props.onEditKpis({
				...props,
				updateTs: getTaskData,
				updateProject,
			});
	};

	// const onViewAttachments = () => {
	// 	props.onViewAttachments && props.onViewAttachments(props);
	// };

	const onSubTask = () => {
		props.onSubTask && props.onSubTask(props);
	};

	const onDueDate = () => {
		props.onDueDate &&
			props.onDueDate({ ...props, updateTs: getTaskData, updateProject });
	};

	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		if (comment === "") {
			toast("You can not post a empty comment!", {
				type: "error",
				autoClose: 3000,
			});
			setLoading(false);
			return;
		}
		const payload = { listId, cardId, text: comment };

		const getCleanedComment = () => {
			return comment.replace(/{{(.*?)}}/g, (_, match) => {
				const member = members.find((user) => user._id === match);
				return member ? member.email : match; // Return email if found, else return the original match
			});
		};

		try {
			const response = await postComment(props.id, projectId, {
				text: getCleanedComment(),
				emails: taggedMembers,
			});
			const { message } = response;
			toast(message, { type: "success", autoClose: 3000 });
			getTaskData();
			updateProject();
			props.onSubmit && props.onSubmit(payload);
			setLoading(false);
			setComment("");
			setTaggedMembers([]);
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
			toast(err.message, {
				type: "error",
				autoClose: 3000,
			});
			setLoading(false);
		}
	};

	const handleCommentDelete = async (commentId) => {
		try {
			const response = await deleteComment(props.id, commentId);
			const { message } = response;
			toast(message, { type: "success", autoClose: 3000 });
			getTaskData();
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 3000 });
			console.log("SERVER_ERROR::", err.message);
		}
	};

	const clickableStyle = {
		cursor: "pointer",
	};

	return (
		<Modal as={Modal.Dialog} centered size="lg" show={show} onHide={onHide}>
			<Form className="modal-content p-lg-3">
				<Modal.Header className="align-items-start border-bottom">
					<div className="d-block">
						<h5 className="text-gray-900 fs-5 fw-bold py-1 ps-1 mb-3">{title}</h5>
						<div
							className="d-flex justify-content-end align-items-center"
							style={{ fontSize: "0.9em" }}
						>
							{/* Priority Section */}
							<div className="d-flex align-items-center me-3 me-sm-4">
								<h5
									className="fs-6 fw-bold text-gray-500 me-2 mb-0"
									style={{ fontSize: "0.85em" }}
								>
									Priority:
								</h5>
								<div className="d-flex align-items-center">
									{taskData?.priority &&
									priorityTag.find((tag) => tag.value === taskData.priority) ? (
										<Badge
											text="white"
											bg={priorityTag.find((tag) => tag.value === taskData.priority).color}
											key={`kanban-label-${taskData.priority}`}
											className="rounded p-2"
										>
											{priorityTag.find((tag) => tag.value === taskData.priority).name}
										</Badge>
									) : (
										<div>None</div>
									)}
								</div>
							</div>

							{/* KPI Section */}
							<div className="d-flex align-items-center me-3 me-sm-4">
								<h5
									className="fs-6 fw-bold text-gray-500 me-2 mb-0"
									style={{ fontSize: "0.85em" }}
								>
									KPI:
								</h5>
								<div className="d-flex align-items-center">
									{taskData?.kpi_indicator &&
									KPITag.find((tag) => tag.value === taskData.kpi_indicator) ? (
										<Badge
											text="white"
											bg={KPITag.find((tag) => tag.value === taskData.kpi_indicator).color}
											key={`kanban-label-${taskData.kpi_indicator}`}
											className="rounded p-2"
										>
											{KPITag.find((tag) => tag.value === taskData.kpi_indicator).name}
										</Badge>
									) : (
										<div>None</div>
									)}
								</div>
							</div>

							{/* Due Date Section */}
							<div className="d-flex align-items-center me-3 me-sm-4">
								<h5
									className="fs-6 fw-bold text-gray-500 me-2 mb-0"
									style={{ fontSize: "0.85em" }}
								>
									Due Date:
								</h5>
								<div className="d-flex align-items-center">
									{moment(taskData?.due_date).format("MM/DD/YYYY")}
								</div>
							</div>
						</div>
					</div>

					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="py-4">
					<Row>
						<Col xs={12} lg={9}>
							<Row className="mb-4">
								<Col xs="auto">
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip className="m-0">{author_name}</Tooltip>}
									>
										<div className="border border-3 rounded mb-2">
											{author?.profile_image ? (
												<Image rounded src={author?.profile_image} className="image-sm" />
											) : (
												<div
													className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary"
													style={{ width: "100%" }}
												>
													<span>{getInitialsOfFirstTwoWords(author_name)}</span>
												</div>
											)}
										</div>
									</OverlayTrigger>
									<div className="text-center">Author</div>
								</Col>
								<Col>
									<div className="d-flex align-items-start">
										<MentionsInput
											value={comment}
											onChange={(e, newValue) => setComment(newValue)}
											placeholder="use @ to mention and tag members in the project"
											className="form-control"
											style={{
												padding: "10px",
												border: "1px solid #ced4da",
												borderRadius: "4px",
												minHeight: "100px",
												flex: 1, // Allow the input to grow
												marginRight: "10px", // Add some space between the input and button
												maxWidth: "calc(100% - 40px)", // Ensure it doesn't overlap with the button
												control: {
													backgroundColor: "#fff",
													fontSize: 12,
													fontWeight: "normal",
												},

												"&multiLine": {
													control: {
														fontFamily: "monospace",
														minHeight: 63,
													},
													highlighter: {
														padding: 9,
														border: "1px solid transparent",
													},
													input: {
														padding: 9,
														border: "1px solid silver",
													},
												},

												"&singleLine": {
													display: "inline-block",
													width: 180,

													highlighter: {
														padding: 1,
														border: "2px inset transparent",
													},
													input: {
														padding: 1,
														border: "2px inset",
													},
												},

												suggestions: {
													list: {
														backgroundColor: "white",
														border: "1px solid rgba(0,0,0,0.15)",
														fontSize: 12,
													},
													item: {
														padding: "5px 15px",
														borderBottom: "1px solid rgba(0,0,0,0.15)",
														"&focused": {
															backgroundColor: "#cee4e5",
														},
													},
												},
											}}
											a11ySuggestionsListLabel={"Suggested mentions"}
											allowSuggestionsAboveCursor={true}
											customSuggestionsContainer={(children) => <div>{children}</div>}
										>
											<Mention
												trigger="@"
												data={members}
												onAdd={handleTag}
												markup="{{__id__}}"
												displayTransform={(id) => `@${id}`}
												renderSuggestion={(suggestion, search, highlightedDisplay) => (
													<div className="user">{highlightedDisplay}</div>
												)}
											/>
										</MentionsInput>

										<OverlayTrigger
											placement="bottom-start"
											overlay={<Tooltip className="m-0">Post comment</Tooltip>}
										>
											<PaperAirplaneIcon
												onClick={handleSubmit}
												disabled={loading}
												className="icon icon-s text-success"
												style={{
													cursor: "pointer",
													...clickableStyle,
												}}
											/>
										</OverlayTrigger>
									</div>
								</Col>
							</Row>
							<Row
								className="mb-4 mb-lg-0"
								style={{ height: "300px", overflow: "scroll" }}
							>
								{comments.map((c) => (
									<Col xs={12} key={`kanban-comment-${c?._id}`} className="mb-4">
										<div
											className="bg-gray-50 border border-gray-100 rounded p-3"
											style={{ fontSize: "0.75em" }}
										>
											<div className="d-flex align-items-center mb-2">
												<h5 className="fs-6 mb-0">
													{c.author?.first_name &&
														c.author?.first_name.charAt(0).toUpperCase() +
															c.author?.first_name.slice(1)}{" "}
													{c.author?.last_name &&
														c.author?.last_name.charAt(0).toUpperCase() +
															c.author?.last_name.slice(1)}{" "}
												</h5>
												<small>&nbsp; {moment(c.createdAt).fromNow()}</small>
											</div>
											<p
												className="text-dark mb-1 me-3"
												style={{
													wordWrap: "break-word",
													wordBreak: "break-word",
													fontSize: "1.125em",
												}}
											>
												{makeTextClickable(c.text)}
											</p>

											<div className="d-flex justify-content-end">
												<span className="text-danger" style={{ cursor: "pointer" }}>
													&middot;{" "}
													<OverlayTrigger
														placement="bottom-start"
														overlay={<Tooltip className="m-0">Delete comment</Tooltip>}
													>
														<TrashIcon
															className="icon icon-xs me-2"
															onClick={() => handleCommentDelete(c?._id)}
														/>
													</OverlayTrigger>
												</span>
											</div>
										</div>
									</Col>
								))}
							</Row>
						</Col>
						<Col xs={12} lg={3}>
							<div className="d-grid gap-2">
								<OverlayTrigger
									placement="top"
									overlay={<Tooltip className="m-0">View/Add Member</Tooltip>}
								>
									<Button
										variant="gray-200"
										size="sm"
										className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
										onClick={onEditMembers}
									>
										<UserGroupIcon className="icon icon-xs text-gray-500 me-2" />
										Team
									</Button>
								</OverlayTrigger>
								<OverlayTrigger
									placement="left"
									overlay={<Tooltip className="m-0">Set Priority</Tooltip>}
								>
									<Button
										variant="gray-200"
										size="sm"
										className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
										onClick={onEditLabels}
									>
										<TagIcon className="icon icon-xs text-gray-500 me-2" />
										Priority
									</Button>
								</OverlayTrigger>
								<OverlayTrigger
									placement="left"
									overlay={<Tooltip className="m-0">Set KPI</Tooltip>}
								>
									<Button
										variant="gray-200"
										size="sm"
										className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
										onClick={onSetKPI}
									>
										<PaperClipIcon className="icon icon-xs text-gray-500 me-2" />
										KPI
									</Button>
								</OverlayTrigger>

								<OverlayTrigger
									placement="left"
									overlay={<Tooltip className="m-0">Click to add subtask</Tooltip>}
								>
									<Button
										variant="gray-200"
										size="sm"
										className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
										onClick={onSubTask}
									>
										<ClipboardListIcon className="icon icon-xs text-gray-500 me-2" />
										Sub Task(s)
									</Button>
								</OverlayTrigger>
								<OverlayTrigger
									placement="left"
									overlay={<Tooltip className="m-0">Click to edit due date</Tooltip>}
								>
									<Button
										variant="gray-200"
										size="sm"
										className="d-inline-flex align-items-center rounded py-2 ps-3 text-start"
										onClick={onDueDate}
									>
										<ClockIcon className="icon icon-xs text-gray-500 me-2" />
										Due Date
									</Button>
								</OverlayTrigger>
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="border-top d-flex justify-content-between">
					<div className="d-flex align-items-start me-3 me-sm-4">
						<h5 className="fs-6 fw-bold text-gray-500 me-2 mt-1">Assignee(s):</h5>
						<div className="d-flex flex-wrap align-items-center">
							{assignees.map((m) => (
								<KanbanAvatar key={`kanban-avatar-${m?._id}`} {...m} />
							))}
						</div>
					</div>
					<div className="d-flex align-items-start">
						<h5 className="fs-6 fw-bold d-flex align-items-center mt-1">
							{subtasksCount} &nbsp;
						</h5>
						<h5 className="fs-6 fw-bold text-gray-500 me-2 mt-1">Subtask(s)</h5>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanEditMembersModal = (props) => {
	const { user } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const { listId, id: cardId, show = false, project, projectId } = props;
	const { teams } = project;
	const [searchValue, setSearchValue] = useState("");
	const [isLoading, setIsLoading] = useState(false); // Set default to false
	const [assignees, setAssignees] = useState([]);
	const [taskAuthor, setTaskAuthor] = useState(null);
	const [teamMembers, setTeamMembers] = useState([]);
	const taskId = props?.id;

	// Fetch Assignees
	const fetchAssignees = useCallback(async () => {
		try {
			const { data } = await getSingleTask(taskId);
			setAssignees(data?.assignees || []);
			setTaskAuthor(data?.author);
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, [taskId]);

	// teams(array): access each team members and combine into one member array
	const fetchTeamMembers = useCallback(() => {
		const allMembers = teams ? teams.map((tm) => tm.members).flat() : [];
		setTeamMembers(
			allMembers?.map((tm) => ({
				...tm,
				show: true,
				isAssignedToCard: assignees.some((m) => m?._id === tm?._id),
			})),
		);
	}, [teams, assignees]);

	useEffect(() => {
		fetchAssignees();
	}, [fetchAssignees]);

	useEffect(() => {
		fetchTeamMembers();
	}, [fetchTeamMembers, assignees]);

	// Search Filter
	const onSearchValueChange = (e) => {
		const newSearchValue = e.target.value.toLowerCase();
		setSearchValue(newSearchValue);
		setTeamMembers((prevMembers) =>
			prevMembers.map((tm) => ({
				...tm,
				show:
					tm.first_name.toLowerCase().includes(newSearchValue) ||
					tm.last_name.toLowerCase().includes(newSearchValue),
			})),
		);
	};

	// Member Click Handler with Preloader
	const onMemberClick = async (id) => {
		if (!ownerOrAdminHandler()) return;
		setIsLoading(true);

		const updatedMembers = teamMembers.map((m) =>
			m?._id === id ? { ...m, isAssignedToCard: !m.isAssignedToCard } : m,
		);
		setTeamMembers(updatedMembers);

		const isAssigned = updatedMembers.find(
			(m) => m?._id === id,
		)?.isAssignedToCard;
		const membersSelected = updatedMembers.filter((m) => m.isAssignedToCard);
		const payload = { listId, cardId, members: membersSelected };

		try {
			const response = isAssigned
				? await assignTask({ taskId, projectId, userId: id })
				: await unassignTask({ taskId, userId: id });
			toast(response.message, { type: "success", autoClose: 3000 });
			props.onHide();
			props.updateTs();
			props.updateProject();
			props.setIsUpdated((prev) => !prev);
			dispatch(getUser());
			props.onSubmit && props.onSubmit(payload);
			setIsLoading(false);
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
			setIsLoading(false);
		}
	};

	const onHide = () => {
		props.onHide && props.onHide();
	};

	const isAdminOrMaster = ["admin", "master", "tech"].includes(user?.role);
	const isOwner = taskAuthor?._id === user?._id;

	const ownerOrAdminHandler = () => {
		if (!isOwner && !isAdminOrMaster) {
			toast("Only owner or admin can edit members!", {
				type: "error",
				autoClose: 3000,
			});
			return false;
		}
		return true;
	};

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Assign this Task</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="search" className="mb-3">
						<InputGroup className="search-bar">
							<Form.Control
								type="text"
								placeholder="Search team members.."
								value={searchValue}
								onChange={onSearchValueChange}
							/>
						</InputGroup>
					</Form.Group>

					{isLoading ? (
						<Preloader
							showPreloader={isLoading}
							setShowPreloader={setIsLoading}
							info={"Please wait, sending task information to assignee ..."}
						/>
					) : (
						<div className="px-3" style={{ overflowY: "scroll", maxHeight: "300px" }}>
							{teamMembers
								.filter((m) => m.show)
								.map((m) => (
									<Row
										key={`board-member-${m?._id}`}
										className="kanban-card-member border-bottom py-2"
										onClick={() => onMemberClick(m?._id)}
									>
										<Col xs={2}>
											{m?.profile_image ? (
												<Image
													src={m?.profile_image}
													className="avatar-md rounded-circle"
												/>
											) : (
												<div className="avatar-md rounded-circle d-flex align-items-center justify-content-center fw-bold bg-secondary me-3">
													<span>
														{getInitialsOfFirstTwoWords(
															`${m?.first_name.charAt(0).toUpperCase()}${m?.first_name.slice(
																1,
															)} ${m?.last_name.charAt(0).toUpperCase()}${m?.last_name.slice(
																1,
															)}`,
														)}
													</span>
												</div>
											)}
										</Col>
										<Col
											xs={8}
											className="d-flex align-items-center justify-content-start"
										>
											<h4 className="fs-6 text-dark mb-0">{`${m?.first_name
												.charAt(0)
												.toUpperCase()}${m?.first_name.slice(1)} ${m?.last_name
												.charAt(0)
												.toUpperCase()}${m?.last_name.slice(1)}`}</h4>
										</Col>
										{m.isAssignedToCard && (
											<Col xs={2} className="d-flex align-items-center">
												<CheckIcon className="icon icon-sm text-success" />
											</Col>
										)}
									</Row>
								))}
						</div>
					)}
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pb-0"></Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanTagMembersModal = (props) => {
	const { listId, id: cardId, show = false, project } = props;
	const { teams } = project;
	const [searchValue, setSearchValue] = useState("");
	const [members, setMembers] = useState([]);

	const getTeam = useCallback(() => {
		const allMembers = teams ? teams.map((tm) => tm.members).flat() : [];
		setMembers(
			allMembers?.map((tm) => ({
				...tm,
				show: true,
			})),
		);
	}, [teams]);

	useEffect(() => {
		getTeam();
	}, [getTeam]);

	const onSearchValueChange = (e) => {
		const newSearchValue = e.target.value;
		const searchResults = members.map((tm) => ({
			...tm,
			show:
				tm.first_name.toLowerCase().includes(newSearchValue.toLowerCase()) ||
				tm.last_name.toLowerCase().includes(newSearchValue.toLowerCase()),
		}));

		setSearchValue(newSearchValue);
		setMembers(searchResults);
	};

	const onMemberClick = async (m) => {
		props.onHide();
		const payload = { listId, cardId, selectedMember: m };
		props.onSubmit && props.onSubmit(payload);
		props.onEditSubMembers && props.onEditSubMembers(payload);
	};

	const onHide = () => {
		props.onHide && props.onHide();
	};

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Tag Member</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="search" className="mb-3">
						<InputGroup className="search-bar">
							<Form.Control
								type="text"
								placeholder="Search team members.."
								value={searchValue}
								onChange={onSearchValueChange}
							/>
						</InputGroup>
					</Form.Group>

					<div className="px-3" style={{ overflowY: "scroll", maxHeight: "300px" }}>
						{members &&
							members
								.filter((m) => m.show)
								.map((m) => (
									<Row
										key={`board-member-${m?._id}`}
										className="kanban-card-member border-bottom py-2"
										onClick={() => onMemberClick(m)}
									>
										<Col xs={2}>
											{m?.profile_image ? (
												<Image
													src={m?.profile_image}
													className="avatar-md rounded-circle"
												/>
											) : (
												<div className="avatar-md rounded-circle d-flex align-items-center justify-content-center fw-bold bg-secondary me-3">
													<span>
														{getInitialsOfFirstTwoWords(
															`${m?.first_name.charAt(0).toUpperCase()}${m?.first_name.slice(
																1,
															)} ${m?.last_name.charAt(0).toUpperCase()}${m?.last_name.slice(
																1,
															)}`,
														)}
													</span>
												</div>
											)}
										</Col>
										<Col
											xs={8}
											className="d-flex align-items-center justify-content-start"
										>
											<h4 className="fs-6 text-dark mb-0">{`${m?.first_name
												.charAt(0)
												.toUpperCase()}${m?.first_name.slice(1)} ${m?.last_name
												.charAt(0)
												.toUpperCase()}${m?.last_name.slice(1)}`}</h4>
										</Col>
										{m.isAssignedToCard && (
											<Col xs={2} className="d-flex align-items-center">
												<CheckIcon className="icon icon-sm text-success" />
											</Col>
										)}
									</Row>
								))}
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pb-0"></Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanEditLabelsModal = (props) => {
	const { listId, id: cardId, show = false } = props;
	const dispatch = useDispatch();
	const [taskData, setTaskData] = useState(null);
	const taskId = props?.id;
	const getTaskData = useCallback(async () => {
		try {
			const { data } = await getSingleTask(taskId);
			setTaskData(data);
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, [taskId]);

	useEffect(() => {
		getTaskData();
	}, [getTaskData, taskId]);
	const [searchValue, setSearchValue] = useState("");
	const [taskLabels, setTaskLabels] = useState(
		priorityTag.map((pg) => ({
			...pg,
			show: true,
			isAssignedToCard: taskData?.priority === pg.value,
		})),
	);

	useEffect(() => {
		setTaskLabels((prevTaskLabels) =>
			prevTaskLabels.map((tl) => {
				if (tl.value === taskData?.priority) {
					return {
						...tl,
						isAssignedToCard: true,
					};
				}
				return tl;
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData?.priority]);

	const onSearchValueChange = (e) => {
		const newSearchValue = e.target.value;
		const searchResults = taskLabels.map((tl) => ({
			...tl,
			show: tl.name.toLowerCase().includes(newSearchValue.toLowerCase()),
		}));

		setSearchValue(newSearchValue);
		setTaskLabels(searchResults);
	};

	const onLabelClick = async (id) => {
		const boardLabelsUpdated = taskLabels.map((m) =>
			m.id === id
				? { ...m, isAssignedToCard: !m.isAssignedToCard }
				: { ...m, isAssignedToCard: false },
		);
		setTaskLabels(boardLabelsUpdated);
		const labelsSelected = boardLabelsUpdated.filter((l) => l.isAssignedToCard);

		const payload = { listId, cardId, labels: labelsSelected };
		try {
			const response = await updateTaskPriority({
				taskId: taskId,
				priority: labelsSelected[0].value,
			});
			const { message } = response;
			toast(message, { type: "success", autoClose: 3000 });
			onHide();
			props.updateTs();
			props.updateProject();
			dispatch(getUser());
			props.onSubmit && props.onSubmit(payload);
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	};

	const onHide = () => {
		props.onHide && props.onHide();
	};

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Priorities</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="search" className="mb-3">
						<InputGroup className="search-bar">
							<Form.Control
								type="text"
								placeholder="Search priorities.."
								value={searchValue}
								onChange={onSearchValueChange}
							/>
						</InputGroup>
					</Form.Group>

					<div className="px-3 py-2">
						{taskLabels
							.filter((l) => l.show)
							.map((l) => (
								<Row key={`label-${l.id}`} className="my-1">
									<Badge
										bg={l.color}
										className="kanban-card-label py-2 px-3"
										onClick={() => onLabelClick(l.id)}
									>
										<div className="d-flex align-items-center justify-content-between">
											<h4 className="fs-6 text-white mb-0">{l.name}</h4>
											{l.isAssignedToCard && <CheckIcon className="icon icon-sm" />}
										</div>
									</Badge>
								</Row>
							))}
					</div>
				</Modal.Body>
			</Form>
		</Modal>
	);
};

export const KanbanSetKpiModal = (props) => {
	const { listId, id: cardId, show = false } = props;
	const dispatch = useDispatch();
	const [taskData, setTaskData] = useState(null);
	const taskId = props?.id;
	const getTaskData = useCallback(async () => {
		try {
			const { data } = await getSingleTask(taskId);
			setTaskData(data);
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, [taskId]);

	useEffect(() => {
		getTaskData();
	}, [getTaskData]);
	const [searchValue, setSearchValue] = useState("");
	const [taskKPIs, setTaskKPIs] = useState(
		KPITag.map((pg) => ({
			...pg,
			show: true,
			isAssignedToCard: taskData?.kpi_indicator === pg.value,
		})),
	);

	useEffect(() => {
		setTaskKPIs((prevTaskInicators) =>
			prevTaskInicators.map((tl) => {
				if (tl.value === taskData?.kpi_indicator) {
					return {
						...tl,
						isAssignedToCard: true,
					};
				}
				return tl;
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData?.kpi_indicator]);

	const onSearchValueChange = (e) => {
		const newSearchValue = e.target.value;
		const searchResults = taskKPIs.map((tl) => ({
			...tl,
			show: tl.name.toLowerCase().includes(newSearchValue.toLowerCase()),
		}));

		setSearchValue(newSearchValue);
		setTaskKPIs(searchResults);
	};

	const onKpiClick = async (id) => {
		const boardKPIsUpdated = taskKPIs.map((m) =>
			m.id === id
				? { ...m, isAssignedToCard: !m.isAssignedToCard }
				: { ...m, isAssignedToCard: false },
		);
		setTaskKPIs(boardKPIsUpdated);
		const kpisSelected = boardKPIsUpdated.filter((l) => l.isAssignedToCard);

		const payload = { listId, cardId, labels: kpisSelected };
		try {
			const response = await updateTaskKpi({
				taskId: taskId,
				kpi: kpisSelected[0].value,
			});
			const { message } = response;
			toast(message, { type: "success", autoClose: 3000 });
			onHide();
			props.updateTs();
			props.updateProject();
			dispatch(getUser());
			props.onSubmit && props.onSubmit(payload);
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	};

	const onHide = () => {
		props.onHide && props.onHide();
	};

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Indicator(KPI)</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="search" className="mb-3">
						<InputGroup className="search-bar">
							<Form.Control
								type="text"
								placeholder="Search priorities.."
								value={searchValue}
								onChange={onSearchValueChange}
							/>
						</InputGroup>
					</Form.Group>

					<div className="px-3 py-2">
						{taskKPIs
							.filter((l) => l.show)
							.map((l) => (
								<Row key={`label-${l.id}`} className="my-1">
									<Badge
										bg={l.color}
										className="kanban-card-label py-2 px-3"
										onClick={() => onKpiClick(l.id)}
									>
										<div className="d-flex align-items-center justify-content-between">
											<h4 className="fs-6 text-white mb-0">{l.name}</h4>
											{l.isAssignedToCard && <CheckIcon className="icon icon-sm" />}
										</div>
									</Badge>
								</Row>
							))}
					</div>
				</Modal.Body>
			</Form>
		</Modal>
	);
};

export const KanbanAttachmentModal = (props) => {
	const { listId, id: cardId, show = false } = props;
	// const [taskData, setTaskData] = useState(null);
	const taskId = props?.id;
	const dispatch = useDispatch();

	// const getTaskData = useCallback(async () => {
	// 	try {
	// 		const { success, message, data } = await getSingleTask(taskId);
	// 		if (success === true) {
	// 			setTaskData(data);
	// 		} else {
	// 			toast(message, { type: "error", autoClose: 3000 });
	// 		}
	// 	} catch (err) {
	// 		console.log("SERVER_ERROR::", err.message);
	// 	}
	// }, [taskId]);

	// useEffect(() => {
	// 	getTaskData();
	// }, [getTaskData, taskId]);

	const [formData, setFormData] = useState({
		name: "",
		file_type: "",
		attachment_file: null,
		file_link: "",
	});

	const [loading, setLoading] = useState(false);
	const [isLink, setIsLink] = useState(false);

	const handleInputChange = (event) => {
		const { name, value, files } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: files ? files[0] : value,
		}));

		if (name === "file_type") {
			if (value === "link") {
				setIsLink(true);
			} else {
				setIsLink(false);
			}
		}
	};

	const onHide = () => {
		props.onHide && props.onHide();
	};

	const handleSubmit = async (event) => {
		setLoading(true);
		event.preventDefault();
		const fileType = formData.file_type;
		const allowedTypes = {
			document: [
				"application/pdf",
				"application/msword",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			],
			image: ["image/jpeg", "image/png"],
			video: ["video/mp4", "video/x-msvideo"],
			audio: ["audio/mpeg", "audio/wav", "audio/x-wav"],
			link: [],
		};

		const fileTypeLabel = {
			document: "DOCUMENT (doc, docx, pdf)",
			image: "Image",
			video: "Video",
			audio: "Audio",
			link: "Link",
		};

		const selectedFileTypes = allowedTypes[fileType];
		if (
			!selectedFileTypes ||
			(selectedFileTypes.length > 0 &&
				!selectedFileTypes.includes(formData.attachment_file?.type))
		) {
			toast.error(
				`Invalid file type for ${fileTypeLabel[fileType]} option. Please select a ${fileTypeLabel[fileType]} file.`,
			);
			return;
		}

		const data = new FormData();
		data.append("name", formData.name);
		data.append("file_type", formData.file_type);
		if (isLink) {
			data.append("file_link", formData.file_link);
		} else {
			data.append("attachment_file", formData.attachment_file);
		}

		const payload = { listId, cardId, attachments: data };
		try {
			const response = await uploadAttachment(taskId, data);
			const { success, message } = response;
			if (success === true) {
				toast(message, { type: "success", autoClose: 3000 });
				onHide();
				props.updateTs();
				props.updateProject();
				setLoading(false);
				dispatch(getUser());
				props.onSubmit && props.onSubmit(payload);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
				setLoading(false);
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
			setLoading(false);
		}
	};

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Attachment</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group controlId="name" className="mb-3">
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							name="name"
							value={formData.name}
							onChange={handleInputChange}
						/>
					</Form.Group>

					<Form.Group controlId="file_type" className="mb-3">
						<Form.Label>Attachment Type</Form.Label>
						<Form.Select
							name="file_type"
							value={formData.file_type}
							onChange={handleInputChange}
						>
							<option value="">Select a file type</option>
							<option value="document">DOCUMENT (doc, docx, pdf)</option>
							<option value="image">Image</option>
							<option value="video">Video</option>
							<option value="audio">Audio</option>
							<option value="link">Link</option>
						</Form.Select>
					</Form.Group>

					{isLink && (
						<Form.Group controlId="file_link" className="mb-3">
							<Form.Label>File Link</Form.Label>
							<Form.Control
								type="text"
								name="file_link"
								onChange={handleInputChange}
							/>
						</Form.Group>
					)}

					{!isLink && (
						<Form.Group controlId="attachment_file" className="mb-3">
							<Form.Label>Upload File</Form.Label>
							<Form.Control
								type="file"
								name="attachment_file"
								onChange={handleInputChange}
							/>
						</Form.Group>
					)}

					<Button
						variant="primary"
						type="submit"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						Add
					</Button>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-top"></Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanDueDateModal = (props) => {
	const { listId, id: cardId, show = false } = props;
	// const [taskData, setTaskData] = useState(null);
	const [dueDate, setDueDate] = useState(new Date());
	const isDateInPast = (currentDate) => {
		const now = new Date();
		return currentDate < now;
	};

	// const taskId = props?.id;
	// const getTaskData = useCallback(async () => {
	// 	try {
	// 		const { success, message, data } = await getSingleTask(taskId);
	// 		if (success === true) {
	// 			setTaskData(data);
	// 		} else {
	// 			toast(message, { type: "error", autoClose: 3000 });
	// 		}
	// 	} catch (err) {
	// 		console.log("SERVER_ERROR::", err.message);
	// 	}
	// }, [taskId]);

	// useEffect(() => {
	// 	getTaskData();
	// }, [getTaskData, taskId]);

	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		// Check if the due_date is a valid ISO string
		if (!moment(dueDate.toISOString(), moment.ISO_8601, true).isValid()) {
			toast("Invalid due date.", { type: "error", autoClose: 3000 });
			setLoading(false);
			return;
		}
		const payload = { listId, cardId, due_date: dueDate };

		try {
			const response = await changeDueDate(props.id, {
				due_date: dueDate.toISOString(),
			});
			const { message } = response;
			toast(message, { type: "success", autoClose: 3000 });
			onHide();
			props.updateTs();
			props.onSubmit && props.onSubmit(payload);
			setLoading(false);
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
			setLoading(false);
		}
	};
	const onHide = () => {
		props.onHide && props.onHide();
	};

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<Button variant="close" onClick={() => onHide()} />
				</Modal.Header>
				<Modal.Body className="pb-0" style={{ height: "300px" }}>
					<Row className="align-items-center">
						<Col md={6} className="mb-4">
							<Form.Group id="date1">
								<Form.Label>Due Date</Form.Label>
								<Datetime
									timeFormat={false}
									value={dueDate}
									onChange={setDueDate}
									isValidDate={(currentDate) => !isDateInPast(currentDate)}
									renderInput={(props, openCalendar) => (
										<InputGroup>
											<InputGroup.Text>
												<CalendarIcon className="icon icon-xs" />
											</InputGroup.Text>
											<Form.Control
												type="text"
												value={moment(dueDate).format("YYYY-MM-DD")}
												placeholder="dd/mm/yyyy"
												onFocus={openCalendar}
												onChange={() => {}}
											/>
										</InputGroup>
									)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mt-1">
							<Form.Group id="date2">
								<Button
									variant="secondary"
									className="d-inline-flex align-items-center"
									onClick={handleSubmit}
									disabled={loading}
								>
									{loading ? (
										<Spinner animation="border" size="sm" className="mr-2" />
									) : null}
									Update
								</Button>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
			</Form>
		</Modal>
	);
};

export const KanbanViewAttachmentModal = (props) => {
	const { show = false } = props;
	const [attachments, setAttachments] = useState([]);
	const [loading, setLoading] = useState(false);

	const taskId = props?.id;
	const getTaskData = useCallback(async () => {
		setLoading(true);
		try {
			const { success, data } = await getSingleTask(taskId);
			if (success === true) {
				setAttachments(data?.attachments || []);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
		}
	}, [taskId]);

	useEffect(() => {
		getTaskData();
	}, [getTaskData, taskId]);

	const onHide = () => {
		props.onHide && props.onHide();
	};

	function getFileType(fileLink) {
		const fileExt = fileLink.split(".").pop();
		const videoTypes = ["mp4", "webm", "ogg"];
		const imageTypes = ["png", "jpg", "jpeg", "gif"];
		const pdfTypes = ["pdf"];
		const docTypes = ["doc", "docx"];
		const audioTypes = ["mp3", "wav"]; // Added audio types

		if (videoTypes.includes(fileExt)) {
			return <VideoCameraIcon className="icon icon-xs text-gray-500 me-2" />;
		} else if (imageTypes.includes(fileExt)) {
			return <PhotographIcon className="icon icon-xs text-gray-500 me-2" />;
		} else if (pdfTypes.includes(fileExt)) {
			return <DocumentIcon className="icon icon-xs text-gray-500 me-2" />;
		} else if (docTypes.includes(fileExt)) {
			return <DocumentIcon className="icon icon-xs text-gray-500 me-2" />;
		} else if (audioTypes.includes(fileExt)) {
			// Added condition for audio types
			return <VolumeUpIcon className="icon icon-xs text-gray-500 me-2" />;
		} else {
			return <LinkIcon className="icon icon-xs text-gray-500 me-2" />;
		}
	}

	const attachmentItems = attachments?.map((attachment) => (
		<div key={attachment?._id} className="mb-3">
			<a
				href={attachment?.file_link}
				target="_blank"
				rel="noopener noreferrer"
				className="text-primary flex items-center"
				style={{ textDecoration: "underline", textDecorationColor: "blue" }}
			>
				{getFileType(attachment?.file_link)}
				<span className="ml-2">{attachment?.name}</span>
			</a>
		</div>
	));

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<div className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Task Attachments</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					{loading ? (
						<div className="d-flex justify-content-center">
							<Spinner animation="border" size="sm" className="mr-2" />
						</div>
					) : (
						<div
							className="mb-4 mb-lg-0"
							style={{ height: "500px", overflow: "scroll" }}
						>
							<>
								{attachmentItems?.length > 0 ? (
									attachmentItems
								) : (
									<div className="d-flex justify-content-center">No attachment yet</div>
								)}
							</>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-top"></Modal.Footer>
			</div>
		</Modal>
	);
};

export const KanbanSubTaskModal = (props) => {
	const { show = false } = props;
	const [tasks, setTasks] = useState(null);
	const [taskData, setTaskData] = useState(
		tasks?.map((task) => ({
			...task,
			assignees: task.assignees,
		})),
	);
	const [loading, setLoading] = useState(false);

	const taskId = props?.id;

	const getSubTaskData = useCallback(async () => {
		setLoading(true);
		try {
			const { data } = await getSingleTask(taskId);
			setTasks(data?.sub_tasks);
			setLoading(false);
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setLoading(false);
		}
	}, [taskId]);

	useEffect(() => {
		getSubTaskData();
	}, [getSubTaskData, taskId]);

	useEffect(() => {
		setTaskData(
			tasks?.map((task) => ({
				...task,
				assignees: task.assignees,
			})),
		);
	}, [tasks]);

	const onAddSubTask = () => {
		props.onAddSubTask &&
			props.onAddSubTask({
				...props,
				updateSubTs: getSubTaskData,
			});
	};

	const onHide = () => {
		props.onHide && props.onHide();
	};

	const onEditSubMembers = (id) => {
		props.onEditSubMembers &&
			props.onEditSubMembers({
				...props,
				subTid: id,
				updateSubTs: getSubTaskData,
			});
	};

	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const clickableStyle = {
		cursor: "pointer",
		textDecoration: isHovered ? "underline" : "none",
	};

	const markAsDone = async (id, status) => {
		try {
			let newStatus;
			if (status === "todo") {
				newStatus = "done";
			} else if (status === "done") {
				newStatus = "todo";
			}

			if (newStatus) {
				const response = await updateTaskStatus(id, {
					task_status: newStatus,
				});
				const { message } = response;
				toast(message, { type: "success", autoClose: 2000 });
				getSubTaskData();
			}
		} catch (err) {
			console.log("ERR::", err.message);
			// toast(err.message, { type: "error", autoClose: 2000 });
			setLoading(false);
			setTimeout(() => {
				onHide();
			}, 2000);
		}
	};

	const removeAssignee = async (subtaskId, id) => {
		try {
			const res = await unassignSubTask({ taskId: subtaskId, userId: id });
			const { message } = res;
			toast(message, { type: "success", autoClose: 2000 });
			getSubTaskData();
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setTimeout(() => {
				onHide();
			}, 3000);
		}
	};

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Sub Tasks</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<div
						className="flex-column mb-4 mb-lg-0"
						style={{ height: "500px", overflow: "scroll" }}
					>
						{loading ? (
							<div className="d-flex justify-content-center">
								<Spinner animation="border" size="sm" className="mr-2" />
							</div>
						) : (
							<div
								className="mb-4 mb-lg-0"
								style={{ height: "500px", overflow: "scroll" }}
							>
								<>
									{taskData?.length > 0 ? (
										taskData.map((td) => (
											<Card border={1} key={td?._id} className="p-1 mb-4">
												<Card.Header className="d-flex align-items-center justify-content-between border-0 p-0 mb-3">
													<div>
														<span style={{ fontSize: "0.8rem" }}>
															<ClockIcon
																className="icon icon-xs text-gray-500 me-1"
																style={{ fontSize: "0.8rem" }}
															/>
															Updated at: {moment(td?.updatedAt).format("MMMM Do YYYY")}
														</span>
													</div>
													{td?.assignees?.length === 0 ? (
														<span
															style={{ fontSize: "0.8rem", ...clickableStyle }}
															onMouseEnter={handleMouseEnter}
															onMouseLeave={handleMouseLeave}
															onClick={() => onEditSubMembers(td?._id)}
														>
															<OverlayTrigger
																placement="bottom-end"
																overlay={<Tooltip className="m-0">Add assignee</Tooltip>}
															>
																<UserAddIcon
																	className="icon icon-xs text-gray-500 me-2"
																	style={{ fontSize: "0.8rem" }}
																/>
															</OverlayTrigger>
														</span>
													) : (
														<span
															style={{ fontSize: "0.8rem", ...clickableStyle }}
															onMouseEnter={handleMouseEnter}
															onMouseLeave={handleMouseLeave}
															onClick={() => removeAssignee(td?._id, td?.assignees[0]?._id)}
														>
															<OverlayTrigger
																placement="bottom-end"
																overlay={<Tooltip className="m-0">Remove assignee</Tooltip>}
															>
																<UserRemoveIcon
																	className="icon icon-xs text-gray-500 me-2"
																	style={{ fontSize: "0.8rem" }}
																/>
															</OverlayTrigger>
														</span>
													)}
												</Card.Header>
												<Card.Body className="p-2">
													<h5 className="mb-0">{td?.name}</h5>
													<p>{td?.description}</p>

													<div style={{ display: "flex", alignItems: "center" }}>
														{!td?.assignees?.length ? (
															<h5 className="fs-6 fw-normal mt-1">Unassigned</h5>
														) : (
															<h5 className="fs-6 fw-normal mt-1">Assignee:</h5>
														)}
														<div className="avatar-group d-flex flex-row">
															{td?.assignees.length >= 1 && (
																<KanbanAvatar
																	key={`card-member-${td?.assignees[0]?._id}`}
																	{...td?.assignees[0]}
																/>
															)}
														</div>
													</div>
													<div
														className={`icon icon-xs position-absolute bottom-0 end-0 m-3 ${
															td?.status === "done" ? "text-success" : "text-light"
														}`}
														onClick={() => markAsDone(td?._id, td?.status)}
														style={{ fontSize: "0.8rem", ...clickableStyle }}
													>
														{td?.status === "done" ? (
															<OverlayTrigger
																placement="bottom-end"
																overlay={<Tooltip className="m-0">Unmark as done</Tooltip>}
															>
																<CheckIcon
																	className="icon icon-xs"
																	style={{ fontSize: "0.3rem" }}
																/>
															</OverlayTrigger>
														) : (
															<OverlayTrigger
																placement="bottom-end"
																overlay={<Tooltip className="m-0">Mark as done</Tooltip>}
															>
																<CheckIcon
																	className="icon icon-xs"
																	style={{ fontSize: "0.3rem" }}
																/>
															</OverlayTrigger>
														)}
													</div>
												</Card.Body>
											</Card>
										))
									) : (
										<div className="d-flex justify-content-center">
											No sub task(s) yet
										</div>
									)}
								</>
							</div>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-top">
					<Button variant="outline-gray-500" onClick={() => onHide()}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={onAddSubTask}
					>
						<PlusIcon className="icon icon-xs me-2" />
						Add Sub Task
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanAddSubTaskModal = (props) => {
	const { show = false } = props;
	const [taskName, setTaskName] = useState("");
	const [taskDescription, setTaskDescription] = useState(null);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const data = {
		name: taskName,
		description: taskDescription,
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		// Check if the due_date is a valid ISO string
		if (taskName === "") {
			toast("Please input a name for the task", {
				type: "error",
				autoClose: 2000,
			});
			setLoading(false);
			return;
		}

		try {
			const response = await createSubTask(props.id, data);
			const { message } = response;
			toast(message, { type: "success", autoClose: 2000 });
			props.updateSubTs();
			dispatch(getUser());
			setLoading(false);
			onHide();
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setLoading(false);
		}
	};

	const onHide = () => {
		props.onHide && props.onHide();
	};

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Create Sub Tasks</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Control
							autoFocus
							type="text"
							value={taskName}
							onChange={(e) => setTaskName(e.target.value)}
							placeholder={`Enter a title for this`}
						/>
					</Form.Group>

					<Form.Group id="description" className="mb-3">
						<Form.Control
							required
							autoFocus
							multiple
							as="textarea"
							value={taskDescription}
							onChange={(e) => setTaskDescription(e.target.value)}
							placeholder={`Enter a description for this`}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-top">
					<Button variant="outline-gray-500" onClick={() => onHide()}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						Submit
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export const KanbanEditSubMembersModal = (props) => {
	const dispatch = useDispatch();
	const { listId, id: cardId, show = false, project, projectId } = props;
	const { teams } = project;
	const [searchValue, setSearchValue] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [members, setMembers] = useState([]);
	const [teamMembers, setTeamMembers] = useState(
		members?.map((tm) => ({
			...tm,
			show: true,
		})),
	);
	const [assignees, setAssignees] = useState([]);
	const taskId = props?.subTid;
	const isMountedRef = useRef(true); // Use a ref to track component mount state

	const getAssignees = useCallback(async () => {
		setIsLoading(true);
		try {
			const { data } = await getSingleTask(taskId);
			if (isMountedRef.current) {
				// Check if component is still mounted before updating state
				setAssignees((prev) => [...prev, ...(data?.assignees || [])]);
				dispatch(getUser());
			}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
		} finally {
			if (isMountedRef.current) {
				setIsLoading(false);
			}
		}
	}, [taskId, dispatch]);

	useEffect(() => {
		getAssignees();
		// Cleanup function to update component mount state
		return () => {
			isMountedRef.current = false;
		};
	}, [taskId, getAssignees]);

	const getTeam = useCallback(() => {
		const allMembers = teams ? teams.map((tm) => tm.members).flat() : [];
		setMembers(allMembers);
	}, [teams]);

	useEffect(() => {
		getTeam();
		// Cleanup function to update component mount state
		return () => {
			isMountedRef.current = false;
		};
	}, [getTeam]);

	useEffect(() => {
		setTeamMembers(
			members?.map((tm) => ({
				...tm,
				show: true,
				isAssignedToCard: assignees?.find((a) => a?._id === tm?._id),
			})),
		);
	}, [members, assignees]);

	const onSearchValueChange = (e) => {
		const newSearchValue = e.target.value;
		const searchResults = teamMembers.map((tm) => ({
			...tm,
			show:
				tm.first_name.toLowerCase().includes(newSearchValue.toLowerCase()) ||
				tm.last_name.toLowerCase().includes(newSearchValue.toLowerCase()),
		}));

		setSearchValue(newSearchValue);
		setTeamMembers(searchResults);
	};

	const [isSending, setIsSending] = useState(false);

	const onMemberClick = async (id) => {
		setIsSending(true);
		const boardMembersUpdated = teamMembers.map((m) =>
			m?._id === id ? { ...m } : m,
		);
		setTeamMembers(boardMembersUpdated);

		// const isAssigned = boardMembersUpdated.find(
		// 	(m) => m?._id === id,
		// )?.isAssignedToCard;

		const membersSelected = teamMembers.filter((m) => m.isAssignedToCard);
		const payload = { listId, cardId, members: membersSelected };

		try {
			const response = await assignSubTask({
				taskId: taskId,
				projectId,
				userId: id,
			});

			const { message } = response;
			setIsSending(false);
			toast(message, { type: "success", autoClose: 2000 });
			onHide();
			getTeam();
			props.updateSubTs();
			props.onSubmit && props.onSubmit(payload);
		} catch (err) {
			setIsSending(false);
			toast(err.message, { type: "error", autoClose: 2000 });
			// reset the isAssignedToCard flag on error
			setTeamMembers(
				boardMembersUpdated.map((m) => (m?._id === id ? { ...m } : m)),
			);
		}
	};

	const onHide = () => {
		props.onHide && props.onHide();
	};

	if (isSending) {
		return (
			<Preloader
				showPreloader={isSending}
				setShowPreloader={setIsSending}
				info={"Please wait sending task information to assignee ..."}
			/>
		);
	}

	return (
		<Modal as={Modal.Dialog} centered scrollable show={show} onHide={onHide}>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Assign this Task</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group id="search" className="mb-3">
						<InputGroup className="search-bar">
							<Form.Control
								type="text"
								placeholder="Search team members.."
								value={searchValue}
								onChange={onSearchValueChange}
							/>
						</InputGroup>
					</Form.Group>

					<div className="px-3" style={{ overflowY: "scroll", maxHeight: "300px" }}>
						{isLoading ? (
							<Spinner animation="border" variant="primary" />
						) : (
							teamMembers
								.filter((m) => m.show)
								.map((m) => (
									<Row
										key={`board-member-${m?._id}`}
										className="kanban-card-member border-bottom py-2"
										onClick={() => onMemberClick(m?._id)}
									>
										<Col xs={2}>
											{m?.profile_image ? (
												<Image
													src={m?.profile_image}
													className="avatar-md rounded-circle"
												/>
											) : (
												<div className="avatar-md rounded-circle d-flex align-items-center justify-content-center fw-bold bg-secondary me-3">
													<span>
														{getInitialsOfFirstTwoWords(
															`${m?.first_name.charAt(0).toUpperCase()}${m?.first_name.slice(
																1,
															)} ${m?.last_name.charAt(0).toUpperCase()}${m?.last_name.slice(
																1,
															)}`,
														)}
													</span>
												</div>
											)}
										</Col>
										<Col
											xs={8}
											className="d-flex align-items-center justify-content-start"
										>
											<h4 className="fs-6 text-dark mb-0">{`${m?.first_name
												.charAt(0)
												.toUpperCase()}${m?.first_name.slice(1)} ${m?.last_name
												.charAt(0)
												.toUpperCase()}${m?.last_name.slice(1)}`}</h4>
										</Col>
									</Row>
								))
						)}
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pb-0"></Modal.Footer>
			</Form>
		</Modal>
	);
};
