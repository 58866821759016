import React, { useState, useEffect } from "react";
import {
	DocumentIcon,
	PhotographIcon,
	VideoCameraIcon,
	LinkIcon,
} from "@heroicons/react/outline";
import { Button, Modal } from "react-bootstrap";
import { VolumeUpIcon } from "@heroicons/react/solid";

export const ViewProjectAttachmentModal = (props) => {
	const { project, setShowView, showView } = props;
	const [attachments, setAttachments] = useState([]);

	useEffect(() => {
		// Update the attachments when the project changes
		setAttachments((prevAttachments) => [
			...prevAttachments,
			...(project?.attachments || []),
		]);
	}, [project]);

	const onHide = () => {
		setShowView && setShowView();
	};

	function getFileType(fileLink) {
		const fileExt = fileLink.split(".").pop();
		const videoTypes = ["mp4", "webm", "ogg"];
		const imageTypes = ["png", "jpg", "jpeg", "gif"];
		const pdfTypes = ["pdf"];
		const docTypes = ["doc", "docx"];
		const audioTypes = ["mp3", "wav"]; // Added audio types
		if (videoTypes.includes(fileExt)) {
			return <VideoCameraIcon className="icon icon-xs text-gray-500 me-2" />;
		} else if (imageTypes.includes(fileExt)) {
			return <PhotographIcon className="icon icon-xs text-gray-500 me-2" />;
		} else if (pdfTypes.includes(fileExt)) {
			return <DocumentIcon className="icon icon-xs text-gray-500 me-2" />;
		} else if (docTypes.includes(fileExt)) {
			return <DocumentIcon className="icon icon-xs text-gray-500 me-2" />;
		} else if (audioTypes.includes(fileExt)) {
			// Added condition for audio types
			return <VolumeUpIcon className="icon icon-xs text-gray-500 me-2" />;
		} else {
			return <LinkIcon className="icon icon-xs text-gray-500 me-2" />;
		}
	}

	const attachmentItems = attachments?.map((attachment) => (
		<div key={attachment?._id} className="mb-3">
			<a
				href={attachment.file_link}
				target="_blank"
				rel="noopener noreferrer"
				className="text-primary flex items-center"
				style={{ textDecoration: "underline", textDecorationColor: "blue" }}
			>
				{getFileType(attachment.file_link)}
				<span className="ml-2">{attachment.name}</span>
			</a>
		</div>
	));

	return (
		<Modal as={Modal.Dialog} centered scrollable show={showView} onHide={onHide}>
			<div className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Project Attachments</Modal.Title>
					<Button variant="close" onClick={onHide} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<div
						className="mb-4 mb-lg-0"
						style={{ height: "500px", overflow: "scroll" }}
					>
						<>
							{attachmentItems?.length > 0 ? (
								attachmentItems
							) : (
								<div className="d-flex justify-content-center">No attachment yet</div>
							)}
						</>
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-top"></Modal.Footer>
			</div>
		</Modal>
	);
};
