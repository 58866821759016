import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
	Modal,
	OverlayTrigger,
	Spinner,
	Tooltip,
} from "react-bootstrap";
import { RefreshIcon } from "@heroicons/react/outline";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { URLs } from "routes";

import {
	archiveProject,
	doneProject,
	getAllArchivedProjects,
	getAllDoneProjects,
	getAllSuspendedProjects,
	suspendProject,
} from "services/projectService";
import { fetchSingleProject } from "redux/actions/singleProjectActions";
import { useDispatch } from "react-redux";
import { getProjects } from "redux/actions/projectActions";

const RepositoryViewModal = (props) => {
	const { showModal, setShowModal, repoType } = props;
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const fetchProjects = useCallback(async () => {
		setLoading(true);
		try {
			let data, message, success;

			if (repoType === "done") {
				({ data, message, success } = await getAllDoneProjects());
			} else if (repoType === "suspended") {
				({ data, message, success } = await getAllSuspendedProjects());
			} else {
				({ data, message, success } = await getAllArchivedProjects());
			}

			if (success === true) {
				setProjects(data);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (error) {
			console.error(error);
			toast("An error occurred", { type: "error", autoClose: 3000 });
		} finally {
			setLoading(false);
		}
	}, [repoType]);

	useEffect(() => {
		fetchProjects();
	}, [fetchProjects]);

	const handleRestore = useCallback(
		async (id) => {
			let response;
			if (repoType === "done") {
				response = await doneProject(id);
			} else if (repoType === "suspended") {
				response = await suspendProject(id);
			} else {
				response = await archiveProject(id);
			}

			const { message, success } = response;
			if (success === true) {
				dispatch(fetchSingleProject(id));
				dispatch(getProjects());
				fetchProjects(); // Call fetchProjects to update the projects after restoring
				toast(message, { type: "success", autoClose: 5000 });
			} else {
				toast(message, { type: "error", autoClose: 5000 });
			}
		},
		[repoType, dispatch, fetchProjects],
	);

	const title =
		repoType === "done"
			? "Done Projects"
			: repoType === "suspended"
			? "Suspended Projects"
			: "Archived Projects";

	const repoList = projects?.map((project) => (
		<div
			key={project?._id}
			className="mb-5 text-primary"
			style={{
				display: "grid",
				gridTemplateColumns: "auto min-content",
				gap: "10px",
				alignItems: "center",
			}}
		>
			<span>
				&bull;{" "}
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip className="m-0">Click to view</Tooltip>}
				>
					<Link to={URLs.Kanban.path + "/" + project?._id}>{project.name}</Link>
				</OverlayTrigger>
			</span>
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip className="m-0">Restore</Tooltip>}
			>
				<RefreshIcon
					className="h-5 w-5 icon icon-xs text-success"
					style={{ cursor: "pointer" }}
					onClick={() => handleRestore(project?._id)}
				/>
			</OverlayTrigger>
		</div>
	));

	return (
		<Modal
			as={Modal.Dialog}
			centered
			scrollable
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<div className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0 mb-5">
					<Modal.Title className="fw-normal">{title}</Modal.Title>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					{loading ? (
						<div className="d-flex justify-content-center">
							<Spinner animation="border" size="sm" className="mr-2" />
						</div>
					) : (
						<div
							className="mb-4 mb-lg-0"
							style={{ height: "500px", overflow: "scroll" }}
						>
							<>
								{repoList?.length > 0 ? (
									repoList
								) : (
									<div className="d-flex justify-content-center">
										No projects in this repository
									</div>
								)}
							</>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-top"></Modal.Footer>
			</div>
		</Modal>
	);
};

export default RepositoryViewModal;
