import React, { useState, useEffect, useRef } from "react";
import { InboxInIcon, PaperClipIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Image, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { URLs } from "routes";
import { socket } from "shared/socket";
import { useSelector } from "react-redux";
import InputEmoji from "react-input-emoji";
import { getUserProfile } from "services/userService";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { TrashIcon } from "@heroicons/react/outline";

const capitalizeFirstLetter = (string) =>
	string[0].toUpperCase() + string.slice(1);

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

const Message = () => {
	const { chatId } = useParams();
	const { user } = useSelector((state) => state.user);

	const [currentUser, setCurrentUser] = useState(null);
	const [messages, setMessages] = useState([]);
	const [newMessage, setNewMessage] = useState("");

	const messagesContainerRef = useRef(null);

	const currentUserName = `${currentUser?.first_name
		.charAt(0)
		.toUpperCase()}${currentUser?.first_name.slice(1)} ${currentUser?.last_name
		.charAt(0)
		.toUpperCase()}${currentUser?.last_name.slice(1)}`;

	// GET Current User
	useEffect(() => {
		const fetchUserProfile = async () => {
			try {
				const { success, data } = await getUserProfile(chatId);
				if (success === true) {
					setCurrentUser(data);
				}
			} catch (error) {
				// Handle any errors that occurred during the API call
				toast("Hmm, something is wrong at message area", {
					type: "error",
					autoClose: 3000,
				});
			}
		};

		fetchUserProfile();
	}, [chatId]);

	// fetch messages
	useEffect(() => {
		const fetchMessages = async () => {
			try {
				socket.emit("fetch-messages", {
					senderId: user?._id,
					recipientId: chatId,
				});
				socket.on("get-messages", (msgs) => {
					setMessages(msgs);
				});
			} catch (error) {
				console.log(error);
			}
		};

		fetchMessages();

		return () => {
			// Unsubscribe from the event listener when the component unmounts
			socket.off("get-messages");
		};
	}, [messages, chatId, user]);

	const handleChange = (newMessage) => {
		setNewMessage(newMessage);
	};

	const handleSend = async (e) => {
		e.preventDefault();
		const message = {
			senderId: user?._id,
			text: newMessage,
			msg_type: "text",
			recipientId: chatId,
		};

		try {
			if (newMessage === "") {
				console.log("");
			} else {
				socket.emit("send-message", message);
				setNewMessage("");

				socket.on("update-message", (message) => {
					setMessages((messages) => [...messages, message]);
				});
			}
		} catch (err) {
			// console.log(err);
			toast("Hmm, something is wrong at message area", {
				type: "error",
				autoClose: 3000,
			});
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			handleSend(e);
		}
	};

	const deleteMessage = (msgId) => {
		console.log("Delete Pressed::::: ", msgId);
		socket.emit("delete-message", msgId);
		// setNewMessage("");

		socket.on("deleted-message", (messages) => {
			setMessages(messages);
		});
	};

	useEffect(() => {
		const handleNewMessage = (message) => {
			setMessages((messages) => [...messages, message]);

			// Scroll to the bottom of the messages container
			if (
				messagesContainerRef.current &&
				messagesContainerRef.current.scrollTop +
					messagesContainerRef.current.clientHeight ===
					messagesContainerRef.current.scrollHeight
			) {
				messagesContainerRef.current.scrollTop =
					messagesContainerRef.current.scrollHeight;
			}
		};

		socket.on("update-message", handleNewMessage);

		return () => {
			socket.off("update-message", handleNewMessage);
		};
	}, [messages]);

	// Scroll to the bottom of the messages container when the component mounts
	useEffect(() => {
		if (messagesContainerRef.current) {
			messagesContainerRef.current.scrollTop =
				messagesContainerRef.current.scrollHeight;
		}
	}, []);

	const imageRef = useRef();

	const MyMessage = ({ msg }) => {
		return (
			<Card
				border="0"
				className="shadow msg-right bg-gray-800 text-white p-3 ms-md-5 ms-lg-6 mb-5"
			>
				<div className="d-flex justify-content-between align-items-center mb-2">
					<span className="font-small">
						<span className="fw-bold">(You)</span>
						<span className="fw-normal text-gray-300 ms-2">
							<small style={{ fontStyle: "italic", fontSize: "10px" }}>
								<Moment fromNow>{msg?.createdAt}</Moment>
							</small>
						</span>
					</span>
					<div className="">
						<Button
							onClick={() => deleteMessage(msg?._id)}
							variant="link"
							className="text-danger"
						>
							<TrashIcon className="icon icon-xs" style={{ width: "15px" }} />
						</Button>
					</div>
				</div>
				<p className="text-gray-300 m-0">{msg?.text}</p>
			</Card>
		);
	};

	// ===================================================================================

	const Message = ({ msg }) => {
		return (
			<Card border="0" className="shadow msg-left p-3 mb-5">
				<div className="d-flex justify-content-between align-items-center mb-2">
					<span className="font-small">
						<Card.Link href={currentUserName}>
							{currentUser?.profile_image ? (
								<Image
									src={currentUser?.profile_image}
									className="avatar-sm img-fluid rounded-circle me-2"
								/>
							) : (
								<div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-2">
									<span>{getInitialsOfFirstTwoWords(currentUserName)}</span>
								</div>
							)}
							<span className="fw-bold">{capitalizeFirstLetter(currentUserName)}</span>
						</Card.Link>
						<span className="fw-normal ms-2">
							<small style={{ color: "green", fontStyle: "italic", fontSize: "10px" }}>
								<Moment fromNow>{msg?.createdAt}</Moment>
							</small>
						</span>
					</span>
				</div>
				<p className="m-0">{msg?.text}</p>
			</Card>
		);
	};

	return (
		<div>
			<Row className="justify-content-center mt-3" id="chat_holder">
				<Col
					xs={12}
					lg={8}
					className="d-flex justify-content-between flex-column flex-lg-row mt-4 mb-2 align-items-center" // Add align-items-center class to vertically center the content
				>
					<Card.Link
						as={Link}
						to={URLs.Messages.path}
						className="fw-bold text-dark hover:underline d-inline-flex align-items-center mb-2 mb-lg-0"
					>
						<InboxInIcon className="icon icon-xs me-2" /> Back to messages
					</Card.Link>
					<small className="text-muted fw-normal">
						Your chat with: {capitalizeFirstLetter(currentUserName)}
					</small>
				</Col>
			</Row>
			<Row className="justify-content-center mt-3">
				<Col
					xs={12}
					lg={8}
					className="px-4 message-container"
					style={{
						overflowY: "scroll",
						scrollbarWidth: "thin",
					}}
					ref={messagesContainerRef}
				>
					{messages &&
						messages?.map((msg) =>
							msg.senderId?._id === user?._id ? (
								<MyMessage key={`my-message-${msg?._id}`} msg={msg} />
							) : (
								<Message key={`message-${msg?._id}`} msg={msg} />
							),
						)}
				</Col>
			</Row>
			<div id="chat_msg_input">
				<Form
					className="mb-0 p-0"
					style={{
						backgroundColor: "#343a40",
						borderRadius: "5px",
						padding: "0px",
						marginTop: "auto",
						height: "3rem",
					}}
				>
					<div className="d-flex align-items-center">
						<InputEmoji
							value={newMessage}
							onChange={handleChange}
							onKeyDown={handleKeyDown}
							style={{ fontStyle: "italic", fontSize: "20px", color: "#FFF" }}
							placeholder={`Message...`}
						/>
						<div className="file-field">
							<div className="d-flex align-items-center">
								<PaperClipIcon
									onClick={() => imageRef.current.click()}
									className="icon icon-xs mx-1 text-gray-400"
								/>
								<input accept="image/*" type="file" ref={imageRef} />
							</div>
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default Message;
