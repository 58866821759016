import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

 const EditRole = (props) => {
	const { setShowEdit, showEdit, name, role, userID } = props;
	const [newRole, setNewRole] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		if (newRole === "admin") {
			props.makeAdmin(userID);
		}
		if (newRole === "master") {
			props.makeMaster(userID);
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			show={showEdit}
			onHide={() => setShowEdit(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Assign {name} a new Role
					</h5>
					<Button variant="close" onClick={() => setShowEdit(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Select
							value={newRole}
							className="mb-3"
							onChange={(e) => setNewRole(e.target.value)}
						>
							<option value="">Select a new role</option>

							<option value="admin" disabled={role === "admin"}>
								{role === "admin" ? "ADMIN (current)" : "ADMIN"}
							</option>
							<option value="master" disabled={role === "master"}>
								{role === "master" ? "SUPER ADMIN (current)" : "SUPER ADMIN"}
							</option>
							<option value="tech" disabled={role === "tech"}>
								{role === "tech" ? "TECH (current)" : "TECH"}
							</option>
						</Form.Select>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0">
					<Button variant="outline-gray-500" onClick={() => setShowEdit(false)}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						Submit and Update
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

 export default EditRole;