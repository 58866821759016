import { ClockIcon, PlusIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import {
	Button,
	Col,
	Form,
	InputGroup,
	Modal,
	Row,
	Spinner,
} from "react-bootstrap";
import moment from "moment-timezone";
import { CalendarIcon } from "@heroicons/react/solid";
import Datetime from "react-datetime";
import { toast } from "react-toastify";

import { createEvent, getAllEvents } from "services/calendarService";
import { useDispatch } from "react-redux";
import { getUser } from "redux/actions/userActions";
import { realtimeUpdate } from "shared/socket";

const AddEventModal = (props) => {
	const { showModal, setShowModal } = props;
	const dispatch = useDispatch();

	const [organizer, setOrganizer] = useState("");
	const [description, setDescription] = useState("");
	const [location, setLocation] = useState("");
	const [address, setAddress] = useState("");
	const [startTime, setStartTime] = useState("");
	const [stopTime, setStopTime] = useState("");
	const [start, setStart] = useState(props?.start ? props?.start : "");
	// const [stop, setStop] = useState("");
	const [title, setTitle] = useState("");
	const [recurringDays, setRecurringDays] = useState([]);
	const [colorClassName, setColorClassName] = useState("");
	const [loading, setLoading] = useState(false);

	const handleRecurringDayChange = (e) => {
		// const selectedOptions = Array.from(
		// 	e.target.selectedOptions,
		// 	(option) => option.value,
		// );
		setRecurringDays([e.target.value]);
	};

	const getEvents = async () => {
		try {
			await getAllEvents();
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getEvents();
	}, []);

		useEffect(() => {
			realtimeUpdate.on("event-updated", (data) => {
				if (data) {
					getEvents();
					dispatch(getUser());
				}
			});
		}, [dispatch]);

	const eventData = {
		organizer: organizer,
		location: location,
		address: address,
		startTime: startTime,
		stopTime: stopTime,
		start: start,
		// end: stop,
		title: title,
		description: description,
		allDay: false,
		className: colorClassName,
		dow: recurringDays,
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		const targetDate = new Date(props?.start);
		const currentDate = new Date();

		if (targetDate < currentDate) {
			toast("You can't set event on past date", {
				type: "error",
				autoClose: 3000,
			});
			setLoading(false);
			return;
		}

		try {
			const response = await createEvent(eventData);
			const { success, message } = response;
			if (success === true) {
				getEvents();
				toast(message, { type: "success", autoClose: 2000 });
				setShowModal(false);
				setLoading(false);
				setOrganizer("");
				setDescription("");
				setAddress("");
				setLocation("");
				setStart("");
				setStartTime("");
				// setStop("");
				setStopTime("");
				setTitle("");
				setColorClassName("");
			} else {
				toast(message, { type: "error", autoClose: 2000 });
				setShowModal(false);
				setLoading(false);
			}
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setShowModal(false);
			setLoading(false);
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			scrollable
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Create a New Event
					</h5>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Control
							autoFocus
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							placeholder={`Enter a title for this`}
						/>
					</Form.Group>

					<Form.Group id="description" className="mb-3">
						<Form.Control
							required
							multiple
							as="textarea"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							placeholder={`Enter a description for this`}
						/>
					</Form.Group>
					<Form.Group id="organizer" className="mb-3">
						<Form.Control
							required
							multiple
							as="textarea"
							value={organizer}
							onChange={(e) => setOrganizer(e.target.value)}
							placeholder={`Enter name of organizer`}
						/>
					</Form.Group>
					<Form.Group id="location" className="mb-3">
						<Form.Control
							required
							multiple
							as="textarea"
							value={location}
							onChange={(e) => setLocation(e.target.value)}
							placeholder={`Enter location`}
						/>
					</Form.Group>
					<Form.Group id="address" className="mb-3">
						<Form.Control
							required
							multiple
							as="textarea"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							placeholder={`Enter address`}
						/>
					</Form.Group>
					<Form.Group id="date1" className="mb-4">
						<Form.Label>Event Date</Form.Label>
						<Datetime
							timeFormat={false}
							isValidDate={(date) => moment(date).isSameOrAfter(moment(), "day")}
							onChange={(date) => {
								if (date) {
									setStart(moment(date));
								} else {
									toast("Invalid date selected", { type: "error", autoClose: 3000 });
								}
							}}
							renderInput={(props, openCalendar) => (
								<InputGroup>
									<InputGroup.Text>
										<CalendarIcon className="icon icon-xs" />
									</InputGroup.Text>
									<Form.Control
										type="text"
										value={start ? moment(start).format("DD/MM/YYYY") : ""}
										placeholder="dd/mm/yyyy"
										onFocus={openCalendar}
										onChange={(e) => setStart(e.target.value)}
									/>
								</InputGroup>
							)}
						/>
					</Form.Group>
					{/* <Row className="align-items-center">
						<Col md={6} className="mb-3">
							<Form.Group id="date1">
								<Form.Label>Start Date</Form.Label>
								<Datetime
									timeFormat={false}
									isValidDate={(date) => moment(date).isSameOrAfter(moment(), "day")}
									onChange={(date) => {
										if (date) {
											setStart(moment(date));
										} else {
											toast("Invalid date selected", { type: "error", autoClose: 3000 });
										}
									}}
									renderInput={(props, openCalendar) => (
										<InputGroup>
											<InputGroup.Text>
												<CalendarIcon className="icon icon-xs" />
											</InputGroup.Text>
											<Form.Control
												type="text"
												value={start ? moment(start).format("DD/MM/YYYY") : ""}
												placeholder="dd/mm/yyyy"
												onFocus={openCalendar}
												onChange={(e) => setStart(e.target.value)}
											/>
										</InputGroup>
									)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="date2">
								<Form.Label>End Date</Form.Label>
								<Datetime
									timeFormat={false}
									isValidDate={(date) =>
										!start || moment(date).isSameOrAfter(start, "day")
									}
									onChange={(date) => {
										if (date) {
											setStop(moment(date));
										} else {
											// Handle error or display a message indicating invalid date
											toast("Invalid date selected", { type: "error", autoClose: 3000 });
										}
									}}
									renderInput={(props, openCalendar) => (
										<InputGroup>
											<InputGroup.Text>
												<CalendarIcon className="icon icon-xs" />
											</InputGroup.Text>
											<Form.Control
												type="text"
												value={stop ? moment(stop).format("DD/MM/YYYY") : ""}
												placeholder="dd/mm/yyyy"
												onFocus={openCalendar}
												onChange={(e) => setStop(e.target.value)}
											/>
										</InputGroup>
									)}
								/>
							</Form.Group>
						</Col>
					</Row> */}
					<Row className="align-items-center">
						<Col md={6} className="mb-3">
							<Form.Group id="time" className="mb-3">
								<Form.Label>Start Time</Form.Label>
								<InputGroup>
									<InputGroup.Text>
										<ClockIcon className="icon icon-xs" />
									</InputGroup.Text>
									<Form.Control
										type="time"
										placeholder="Select time"
										value={startTime}
										onChange={(e) => setStartTime(e.target.value)}
									/>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="time" className="mb-3">
								<Form.Label>Stop Time</Form.Label>
								<InputGroup>
									<InputGroup.Text>
										<ClockIcon className="icon icon-xs" />
									</InputGroup.Text>
									<Form.Control
										type="time"
										placeholder="Select time"
										value={stopTime}
										onChange={(e) => setStopTime(e.target.value)}
									/>
								</InputGroup>
							</Form.Group>
						</Col>
					</Row>

					<Row className="align-items-center">
						<Col md={6} className="mb-3">
							<Form.Group id="color" className="mb-3">
								<Form.Label>Color</Form.Label>
								<Form.Select
									onChange={(e) => setColorClassName(e.target.value)}
									value={colorClassName}
								>
									<option value="" disabled>
										Select a color
									</option>
									<option value="bg-blue border-0 shadow">Blue</option>
									<option value="bg-danger border-0 shadow">Red</option>
									<option value="bg-info border-0 shadow">Green</option>
									<option value="bg-yellow border-0 shadow">Yellow</option>
									<option value="bg-purple border-0 shadow">Purple</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="allDay" className="mb-3">
								<Form.Label>Recurring ?</Form.Label>
								<Form.Select value={recurringDays} onChange={handleRecurringDayChange}>
									<option value="" disabled>
										Select day
									</option>
									<option value=""></option>
									<option value="Monday">Monday</option>
									<option value="Tuesday">Tuesday</option>
									<option value="Wednesday">Wednesday</option>
									<option value="Thursday">Thursday</option>
									<option value="Friday">Friday</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0">
					<Button variant="outline-gray-500" onClick={() => setShowModal(false)}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						<PlusIcon className="icon icon-xs me-2" />
						Add Event
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default AddEventModal;
