import { PlusIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { addNewUser } from "services/userService";

const AddMemberModal = (props) => {
	const { showModal, setShowModal } = props;
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		if (firstName === "" || lastName === "" || email === "") {
			toast("Please fill all fields", { type: "error", autoClose: 2000 });
			setLoading(false);
			return;
		}

		try {
			const response = await addNewUser({
				first_name: firstName,
				last_name: lastName,
				email: email,
			});
			const { message } = response;
			toast(message, { type: "success", autoClose: 3000 });
			props?.updateMembers();
			setLoading(false);
			setTimeout(() => {
				setShowModal(false);
			}, 2000);
			setFirstName("");
			setLastName("");
			setEmail("");
		} catch (err) {
			toast(err.message, { type: "error", autoClose: 2000 });
			setLoading(false);
			setTimeout(() => {
				setShowModal(false);
			}, 2000);
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Add New Member
					</h5>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<Form.Group id="title" className="mb-3">
						<Form.Control
							autoFocus
							type="text"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							placeholder={`Enter user first name`}
						/>
					</Form.Group>

					<Form.Group id="title" className="mb-3">
						<Form.Control
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							placeholder={`Enter user last name`}
						/>
					</Form.Group>

					<Form.Group id="title" className="mb-3">
						<Form.Control
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder={`Enter user email`}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-start border-0 pt-0 mt-3">
					<Button variant="outline-gray-500" onClick={() => setShowModal(false)}>
						Close
					</Button>
					<Button
						variant="secondary"
						className="d-inline-flex align-items-center"
						type="submit"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						<PlusIcon className="icon icon-xs me-2" />
						Add Member
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default AddMemberModal;
