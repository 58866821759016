import { getUserData } from "services/userService";
import { SET_MESSAGE } from "./messageActions";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const CLEAR_USER = "CLEAR_USER";

// action creators
export const getUser = () => async (dispatch) => {
	dispatch({ type: GET_USER_REQUEST });
	try {
		const { success, message, data } = await getUserData();
		if (success === true) {
			dispatch({
				type: GET_USER_SUCCESS,
				payload: data,
			});
		} else {
			dispatch({ type: GET_USER_FAIL });
			dispatch({
				type: SET_MESSAGE,
				payload: { text: message, success: success },
			});
		}
	} catch (err) {
		dispatch({ type: GET_USER_FAIL });
		dispatch({
			type: SET_MESSAGE,
			payload: { text: err.message, success: false },
		});
	}
};

export const clearUser = () => (dispatch) => {
	dispatch({ type: CLEAR_USER });
};
