import React, { useEffect, useState } from "react";
import {
	ArrowNarrowLeftIcon,
	LockClosedIcon,
	MailIcon,
	EyeIcon,
	EyeOffIcon,
} from "@heroicons/react/solid";
import {
	Col,
	Row,
	Form,
	Card,
	Button,
	Container,
	InputGroup,
	Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { URLs } from "routes";
import BgImage from "assets/img/illustrations/signin.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { login } from "redux/actions/authActions";
import { CLEAR_MESSAGE } from "redux/actions/messageActions";

const Signin = () => {
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.authId);
	const { message } = useSelector((state) => state.message);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!email || !password) {
			toast("Please fill in all fields.", { type: "error", autoClose: 3000 });
			return;
		}
		dispatch(login(email, password, navigate));
	};

	useEffect(() => {
		setIsMounted(true);
		dispatch({ type: CLEAR_MESSAGE });
	}, [dispatch]);

	useEffect(() => {
		if (!isMounted) {
			return; // Skip displaying toasts on initial mount
		}

		if (message?.success === true) {
			toast(message?.text, { type: "success", autoClose: 3000 });
		}

		if (message?.success === false) {
			toast(message?.text, { type: "error", autoClose: 3000 });
		}
	}, [isMounted, message]);

	return (
		<main>
			<section className="d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft">
				<Container>
					<p className="text-center">
						<Card.Link
							as={Link}
							to={URLs.Presentation.path}
							className="d-flex align-items-center justify-content-center"
						>
							<ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to homepage
						</Card.Link>
					</p>
					<Row
						className="justify-content-center form-bg-image"
						style={{ backgroundImage: `url(${BgImage})` }}
					>
						<Col xs={12} className="d-flex align-items-center justify-content-center">
							<div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
								<div className="text-center text-md-center mb-4 mt-md-0">
									<h3 className="mb-0">Sign in to Amebo</h3>
								</div>
								<Form className="mt-4">
									<Form.Group id="email" className="mb-4">
										<Form.Label>Your Email</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<MailIcon className="icon icon-xs text-gray-600" />
											</InputGroup.Text>
											<Form.Control
												autoFocus
												type="email"
												placeholder="example@company.com"
												autoComplete="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</InputGroup>
									</Form.Group>
									<Form.Group>
										<Form.Group id="password" className="mb-4">
											<Form.Label>Your Password</Form.Label>
											<InputGroup>
												<InputGroup.Text>
													<LockClosedIcon className="icon icon-xs text-gray-600" />
												</InputGroup.Text>
												<Form.Control
													type={passwordVisible ? "text" : "password"}
													placeholder="Password"
													autoComplete="current-password"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
												/>
												<InputGroup.Text
													className="password-toggle"
													onClick={() => setPasswordVisible(!passwordVisible)}
												>
													{passwordVisible ? (
														<EyeOffIcon className="icon icon-xs text-gray-600" />
													) : (
														<EyeIcon className="icon icon-xs text-gray-600" />
													)}
												</InputGroup.Text>
											</InputGroup>
										</Form.Group>
										<div className="d-flex justify-content-between align-items-top mb-4">
											<div className="me-2"></div>
											<Card.Link
												className="small text-end"
												as={Link}
												to={URLs.ForgotPassword.path}
											>
												Lost password?
											</Card.Link>
										</div>
									</Form.Group>
									<div className="d-grid">
										<Button
											variant="gray-800"
											type="submit"
											onClick={handleSubmit}
											disabled={loading}
										>
											{loading ? (
												<Spinner animation="border" size="sm" className="mr-2" />
											) : null}
											Sign in
										</Button>
									</div>
								</Form>

								{/* <div className="d-flex justify-content-center align-items-center mt-4">
									<span className="fw-normal">
										Not registered?
										<Card.Link as={Link} to={URLs.Signup.path} className="fw-bold">
											{` Create account `}
										</Card.Link>
									</span>
								</div> */}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};

export default Signin;
