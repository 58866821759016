import React from "react";
import {
	Col,
	Row,
	Button,
	ButtonGroup,
	Container,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import {
	ArchiveIcon,
	ClockIcon,
	EyeIcon,
	PlusIcon,
} from "@heroicons/react/solid";
// import { useSelector } from "react-redux";
import moment from "moment-timezone";

const KanbanHeader = (props) => {
	// const { user } = useSelector((state) => state.user);
	const { project, showAdd, setShowAdd, showView, setShowView } = props;
	// console.log("project", props)
	return (
		<Container fluid className="px-3">
			<Row className="mt-4 mb-3">
				<Col xs={12} md={6} className="mb-3 mb-md-0">
					<div className="me-lg-3">
						<Button
							as={Button}
							variant="secondary"
							className="d-inline-flex align-items-center me-2"
							onClick={props.onNewCard}
						>
							<PlusIcon className="icon icon-xs me-2" /> Add Task
						</Button>
					</div>
					{project && (
						<h5 className="fs-6 mt-2">
							<span className="project-name">{project?.name + ":"}</span>{" "}
							{project?.description}
						</h5>
					)}
					<Button
						variant="primary"
						size="sm"
						className="d-inline-flex align-items-center rounded py-2 ps-3 text-start bg-dark text-white"
					>
						<ClockIcon
							className="icon icon-xs text-gray-500 me-2"
							style={{ color: "white" }}
						/>
						Project Due Date: {moment(project?.due_date).format("MMMM Do YYYY")}
					</Button>
				</Col>
				<Col xs={12} md={6} className="text-md-end">
					<ButtonGroup>
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip className="m-0">Add supporting document(s)</Tooltip>}
						>
							<Button variant="gray-800" onClick={() => setShowAdd(!showAdd)}>
								<ArchiveIcon className="icon icon-xs text-white" />
							</Button>
						</OverlayTrigger>

						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip className="m-0">View supporting document(s)</Tooltip>}
						>
							<Button variant="gray-800" onClick={() => setShowView(!showView)}>
								<EyeIcon className="icon icon-xs text-white" />
							</Button>
						</OverlayTrigger>
					</ButtonGroup>
				</Col>
			</Row>
		</Container>
	);
};

export default KanbanHeader;
