import React, { useState, useCallback, useEffect } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import ApexChart from "react-apexcharts";
import { getMemberPerformance } from "services/analyticsService";

const StaffPerformanceValueWidget = ({userId}) => {
	const [monthlyData, setMonthlyData] = useState([]);
	const [dataLabels, setDataLabels] = useState([]);
	const [totalCompleted, setTotalCompleted] = useState(0);
	const [totalPending, setTotalPending] = useState(0);
	const [totalInProgress, setTotalInProgress] = useState(0);
	const [totalInReview, setTotalInReview] = useState(0);
	const [userData, setUserData] = useState(userId);

	useEffect(() => {
		setUserData(userId);
	}, [userId]);

	const getData = useCallback(async () => {
		try {
			const { success, message, data } = await getMemberPerformance(userData);
			if (success === true) {
				setMonthlyData(data.yearlyPerformance.data);
				setDataLabels(data.yearlyPerformance.labels);
				setTotalCompleted(data.totalCompletedTasks);
				setTotalPending(data.totalPendingTasks);
				setTotalInProgress(data.totalInProgressTasks);
				setTotalInReview(data.totalInReviewTasks);

			} else {
				console.log("SERVER_ERROR:", message);
			}
		} catch (err) {
			console.error("Error:", err.message);
		}
	}, [userData]);

	useEffect(() => {
		getData();
	}, [getData]);

	const PerformanceValueChart = () => {
		const chartSeries = [
			{
				name: "Tasks",
				data: monthlyData,
			},
		];

		const chartOptions = {
			chart: {
				fontFamily: "Inter",
				foreColor: "#262B40",
				toolbar: {
					show: true,
					offsetX: 0,
					offsetY: 0,
					tools: {
						download: false,
						selection: false,
						zoom: false,
						zoomin: true,
						zoomout: true,
						pan: false,
						reset: false | '<img src="/static/icons/reset.png" width="20">',
						customIcons: [],
					},
					export: {
						csv: {
							filename: undefined,
							columnDelimiter: ",",
							headerCategory: "category",
							headerValue: "value",
							dateFormatter(timestamp) {
								return new Date(timestamp).toDateString();
							},
						},
					},
					autoSelected: "zoom",
				},
			},
			dataLabels: {
				enabled: false,
			},
			tooltip: {
				style: {
					fontSize: "14px",
					fontFamily: "Inter",
				},
			},
			theme: {
				monochrome: {
					enabled: true,
					color: "#17a5ce",
				},
			},
			grid: {
				show: true,
				borderColor: "#61DAFB",
				strokeDashArray: 1,
			},
			markers: {
				size: 5,
				strokeColors: "#17a5ce",
				hover: {
					size: undefined,
					sizeOffset: 3,
				},
			},
			xaxis: {
				categories: dataLabels,
				labels: {
					style: {
						fontSize: "12px",
						fontWeight: 500,
					},
				},
				axisBorder: {
					color: "#61DAFB",
				},
				axisTicks: {
					color: "#61DAFB",
				},
			},
			yaxis: {
				labels: {
					style: {
						colors: ["#262B40"],
						fontSize: "12px",
						fontWeight: 500,
					},
				},
			},
			responsive: [
				{
					breakpoint: 768,
					options: {
						yaxis: {
							show: false,
						},
					},
				},
			],
		};

		return (
			<ApexChart
				type="area"
				height={420}
				series={chartSeries}
				options={chartOptions}
			/>
		);
	};

	return (
		<Card className="border-0 bg-secondary-alt shadow">
			<Card.Header className="d-sm-flex flex-row align-items-center border-0 flex-0">
				<div className="row">
					<h5 className="fs-5 fw-normal mb-2">Monthly Performance</h5>
					<div className="col-md-6">
						<div className="d-block mb-3 mb-sm-0">
							<small className="d-flex align-items-center mt-2">
								<span className="fw-normal me-2">Completed</span>
								<span className={`fw-bold text-success`}>{totalCompleted}</span>
							</small>
							<small className="d-flex align-items-center mt-1">
								<span className="fw-normal me-2">Pending</span>
								<span className={`fw-bold text-warning`}>{totalPending}</span>
							</small>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-block mb-3 mb-sm-0">
							<small className="d-flex align-items-center mt-2">
								<span className="fw-normal me-2">In Progress</span>
								<span className={`fw-bold text-primary`}>{totalInProgress}</span>
							</small>
							<small className="d-flex align-items-center mt-1">
								<span className="fw-normal me-2">In Review</span>
								<span className={`fw-bold text-info`}>{totalInReview}</span>
							</small>
						</div>
					</div>
				</div>

				<ButtonGroup className="ms-auto">
					<Button variant="secondary" disabled={true}>
						Year {new Date().getFullYear()}
					</Button>
				</ButtonGroup>
			</Card.Header>
			<Card.Body className="p-2">
				<PerformanceValueChart />
			</Card.Body>
		</Card>
	);
};

export default StaffPerformanceValueWidget;
