import React, { Suspense } from "react";
import RouterEntry from "RouterEntry";

const App = () => {
	return (
		<>
			<Suspense fallback={<div>Loading...</div>}>
				<RouterEntry />
			</Suspense>
		</>
	);
};

export default App;
