import React from "react";
import { v4 as uuidv4 } from "uuid";
import { CalendarIcon } from "@heroicons/react/solid";

import {
	ManageCodeIcon,
	TeamCodeIcon,
	RoleCodeIcon,
} from "components/BrandIcons";

const features = [
	{
		id: uuidv4(),
		title: "Manage projects",
		icon: <ManageCodeIcon size="md" color="gray-500" className="mb-3" />,
		description:
			"Create projects for your teams or become a member on another project.",
	},
	{
		id: uuidv4(),
		title: "Work together",
		icon: <TeamCodeIcon size="md" color="gray-500" className="mb-3" />,
		description:
			"Work on projects and keep track of all changes at different levels of progress.",
	},
	{
		id: uuidv4(),
		title: "Membership",
		icon: <RoleCodeIcon size="md" color="gray-500" className="mb-3" />,
		description:
			"Assign members and priorities based on their availability and the schedule.",
	},
	{
		id: uuidv4(),
		title: "Calendar",
		icon: <CalendarIcon className="icon icon-md text-gray-500 mb-3" />,
		description:
			"Make plans and schedules with shared events and common goals for your organization.",
	},
];

const featureData = { features };

export default featureData;
