import { Col, Card} from "react-bootstrap";
import { Link } from "react-router-dom";

import { URLs } from "routes";

const MyProjectCard = (props) => {

	const { _id, name, description } = props;

	// const deleteTask = () => {
	// 	props.deleteTask && props.deleteTask(_id);
	// };

	return (
		<Card
			border="bottom"
			className="hover-state rounded-0 rounded-top py-3"
			as={Link}
			to={URLs.Kanban.path + "/" + _id}
		>
			<Card.Body className="d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0">
				<Col xs={11} lg={10} className="px-0 mb-4 mb-md-0">
					<div className="mb-2">
						<h5 className="">{name}</h5>
					</div>
					<div className="fw-bold text-dark">
						<span className={`fw-normal text-gray`}>{description}</span>
					</div>
				</Col>
			</Card.Body>
		</Card>
	);
};

export default MyProjectCard;