import { toast } from "react-toastify";
import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { uploadProjectAttachment } from "services/projectService";
import { useDispatch } from "react-redux";
import { PlusIcon } from "@heroicons/react/solid";
import { getUser } from "redux/actions/userActions";

export const AddProjectAttachmentModal = (props) => {
	const { showAdd, setShowAdd, project } = props;
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		name: "",
		file_type: "",
		attachment_file: null,
		file_link: "",
	});

	const [loading, setLoading] = useState(false);
	const [isLink, setIsLink] = useState(false);

	const handleInputChange = (event) => {
		const { name, value, files } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: files ? files[0] : value,
		}));

		if (name === "file_type") {
			if (value === "link") {
				setIsLink(true);
			} else {
				setIsLink(false);
			}
		}
	};

	const handleSubmit = async (event) => {
		setLoading(true);
		event.preventDefault();
		const fileType = formData.file_type;
		const allowedTypes = {
			document: [
				"application/pdf",
				"application/msword",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			],
			image: ["image/jpeg", "image/png"],
			video: ["video/mp4", "video/x-msvideo"],
			audio: ["audio/mpeg", "audio/wav", "audio/x-wav"],
			link: [],
		};

		const fileTypeLabel = {
			document: "DOCUMENT (doc, docx, pdf)",
			image: "Image",
			video: "Video",
			audio: "Audio",
			link: "Link",
		};

		const selectedFileTypes = allowedTypes[fileType];

		if (
			!selectedFileTypes ||
			(selectedFileTypes.length > 0 &&
				!selectedFileTypes.includes(formData.attachment_file?.type))
		) {
			toast.error(
				`Invalid file type for ${fileTypeLabel[fileType]} option. Please select a ${fileTypeLabel[fileType]} file.`,
			);
			return;
		}

		const data = new FormData();
		data.append("name", formData.name);
		data.append("file_type", formData.file_type);

		if (isLink) {
			data.append("file_link", formData.file_link);
		} else {
			data.append("attachment_file", formData.attachment_file);
		}

		try {
			const response = await uploadProjectAttachment(project?._id, data);
			const { success, message } = response;
			if (success === true) {
				toast(message, { type: "success", autoClose: 3000 });
				props.setIsUpdated(!props.isUpdated);
				dispatch(getUser());
				setLoading(false);
				setShowAdd(false);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
				setLoading(false);
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
			setLoading(false);
		}
	};

	return (
		<Modal
			as={Modal.Dialog}
			centered
			scrollable
			show={showAdd}
			onHide={() => setShowAdd(false)}
		>
			<Form className="modal-content p-3">
				<Modal.Header className="border-0 px-3 pb-0">
					<Modal.Title className="fw-normal">Add Supporting Document</Modal.Title>
					<Button variant="close" onClick={() => setShowAdd(false)} />
				</Modal.Header>

				<Modal.Body className="px-3 pb-0">
					<Form.Group controlId="name" className="mb-3">
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							name="name"
							value={formData.name}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group controlId="file_type" className="mb-3">
						<Form.Label>Attachment Type</Form.Label>
						<Form.Select
							name="file_type"
							value={formData.file_type}
							onChange={handleInputChange}
						>
							<option value="">Select a file type</option>
							<option value="document">DOCUMENT(doc, docx, pdf)</option>
							<option value="image">Image</option>
							<option value="video">Video</option>
							<option value="audio">Audio</option> {/* Added Audio option */}
							<option value="link">Link</option>
						</Form.Select>
					</Form.Group>
					{isLink && (
						<Form.Group controlId="file_link" className="mb-3">
							<Form.Label>File Link</Form.Label>
							<Form.Control
								type="text"
								name="file_link"
								onChange={handleInputChange}
							/>
						</Form.Group>
					)}
					{!isLink && (
						<Form.Group controlId="attachment_file" className="mb-3">
							<Form.Label>Upload File</Form.Label>
							<Form.Control
								type="file"
								name="attachment_file"
								onChange={handleInputChange}
							/>
						</Form.Group>
					)}
					<Button
						variant="primary"
						type="submit"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? (
							<Spinner animation="border" size="sm" className="mr-2" />
						) : null}
						<PlusIcon className="icon icon-xs me-2" /> Add
					</Button>
				</Modal.Body>
			</Form>
		</Modal>
	);
};
