import React, { useState } from "react";
import {
	CheckCircleIcon,
	DotsHorizontalIcon,
	EyeIcon,
	InformationCircleIcon,
	ChatAlt2Icon,
	UserRemoveIcon,
	RefreshIcon,
	UserAddIcon,
	ChartPieIcon,
	DownloadIcon,
	LockClosedIcon,
	LockOpenIcon,
} from "@heroicons/react/solid";
import {
	Card,
	Form,
	Image,
	Button,
	Table,
	Dropdown,
	Pagination,
	FormCheck,
	ButtonGroup,
} from "react-bootstrap";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";

import { URLs } from "routes";
import EditRole from "./EditRole";
import { RoleCodeIcon } from "./BrandIcons";
import { useSelector } from "react-redux";
import ReportOptionModal from "./ReportOptionModal";

const capitalizeFirstLetter = (string) =>
	string[0].toUpperCase() + string.slice(1);

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

const MembersTable = (props) => {
	const { user } = useSelector((state) => state.user);

	const { users = [], allSelected } = props;
	const [bulkOption, setBulkOption] = useState(0);
	const disabledBulkMenu = users.filter((u) => u.isSelected).length === 0;
	const selectUser = (id) => {
		props.selectUser && props.selectUser(id);
	};

	const selectAllUsers = () => {
		props.selectAllUsers && props.selectAllUsers();
	};

	const bulkActionChange = (e) => {
		const newOption = e.target.value;
		setBulkOption(newOption);
	};

	const sendBulkMail = (ids) => {
		// props.sendMails && props.sendMails(ids);
	};

	const applyBulkAction = () => {
		if (bulkOption === "send_mail") sendBulkMail();
	};

	const revokeRole = (id) => {
		props.revokeRole && props.revokeRole(id);
	};

	const suspendUser = (id) => {
		props.suspendUser && props.suspendUser(id);
	};
	const unsuspendUser = (id) => {
		props.unsuspendUser && props.unsuspendUser(id);
	};
	const activateUser = (id) => {
		props.suspendUser && props.activateUser(id);
	};
	const deactivateUser = (id) => {
		props.unsuspendUser && props.deactivateUser(id);
	};
	const makeAdmin = (id) => {
		props.makeAdmin && props.makeAdmin(id);
	};
	const makeMaster = (id) => {
		props.makeMaster && props.makeMaster(id);
	};

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	// Get current page of items
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = users?.slice(indexOfFirstItem, indexOfLastItem);

	// Change page
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const TableRow = (props) => {
		const {
			_id,
			verified,
			status,
			role,
			profile_image,
			first_name,
			last_name,
			email,
			createdAt,
			isSelected,
		} = props;

		const name = `${first_name.charAt(0).toUpperCase()}${first_name.slice(
			1,
		)} ${last_name.charAt(0).toUpperCase()}${last_name.slice(1)}`;
		const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;

		const statusVariant = status === "active" ? "success" : "danger";

		const verifyVariant = verified === true ? "success" : "warning";

		const [showEdit, setShowEdit] = useState(false);
		const [show, setShow] = useState(false);

		const handleShowEdit = () => {
			setShowEdit(true);
		};

		const navigate = useNavigate();

		return (
			<tr className="border-bottom">
				{user?.role === "master" ? (
					<td>
						<FormCheck type="checkbox" className="dashboard-check">
							<FormCheck.Input
								id={`user-${_id}`}
								checked={isSelected}
								onChange={() => selectUser(_id)}
							/>
							<FormCheck.Label htmlFor={`user-${_id}`} />
						</FormCheck>
					</td>
				) : (
					// render an empty td for users who are not "admin" or "master"
					<td>
						<FormCheck type="checkbox" disabled className="dashboard-check" />
					</td>
				)}

				<td>
					<Card.Link className="d-flex align-items-center justify-content-start">
						{profile_image ? (
							<Image src={profile_image} className="avatar rounded-circle" />
						) : (
							<div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary">
								<span>{getInitialsOfFirstTwoWords(name)}</span>
							</div>
						)}
						<div className="d-flex flex-column flex-grow-1">
							<span className="fw-bold">{name}</span>
							<div className="small text-gray">{email}</div>
						</div>
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{moment(createdAt).format("DD/MM/YYYY")}</span>
				</td>
				<td>
					<span className="fw-normal d-flex align-items-center">
						<VerifiedIcon className={`icon icon-xxs text-${verifyVariant} me-1`} />
						Email
					</span>
				</td>
				<td>
					<span className="fw-normal d-flex align-items-center">
						<VerifiedIcon className={`icon icon-xxs text-${statusVariant} me-1`} />
						{capitalizeFirstLetter(status)}
					</span>
				</td>
				<td>
					<span className="w-normal text-purple">
						{role === "master"
							? capitalizeFirstLetter("Super Admin")
							: capitalizeFirstLetter(role)}
					</span>
				</td>
				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle
							as={Button}
							split
							variant="link"
							className="text-dark m-0 p-0"
						>
							<DotsHorizontalIcon className="icon icon-xs" />
						</Dropdown.Toggle>
						<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
							<Dropdown.Item
								className="d-flex align-items-center"
								onClick={() => navigate(`${URLs.Message.path + "/" + _id}`)}
							>
								<ChatAlt2Icon className="dropdown-icon text-gray-400 me-2" />
								Send Message
							</Dropdown.Item>
							<Dropdown.Item
								className="d-flex align-items-center"
								onClick={() => navigate(`${URLs.DashboardMembers.path}/${_id}/stats`)}
							>
								<ChartPieIcon className="dropdown-icon me-2" /> View Analytics
							</Dropdown.Item>
							<Dropdown.Item
								className="d-flex align-items-center"
								onClick={() => setShow(!show)}
							>
								<DownloadIcon className="dropdown-icon me-2" /> Download Report
							</Dropdown.Item>
							<Dropdown.Item
								as={Link}
								to={URLs.Profile.path + "/" + _id}
								className="d-flex align-items-center"
							>
								<EyeIcon className="dropdown-icon text-gray-400 me-2" />
								View Details
							</Dropdown.Item>

							{user?.role === "master" || user?.role === "tech" ? (
								<Dropdown.Item
									as={Button}
									className="d-flex align-items-center"
									onClick={handleShowEdit}
									style={{ backgroundColor: "transparent" }}
								>
									<RoleCodeIcon className="dropdown-icon text-gray-400 me-2" />
									Assign new Role
								</Dropdown.Item>
							) : null}

							{user?.role === "master" || user?.role === "tech" ? (
								<>
									{status === "active" && (
										<Dropdown.Item
											as={Button}
											className={`d-flex align-items-center text-danger`}
											style={{ backgroundColor: "transparent" }}
											onClick={() => suspendUser(_id)}
										>
											<LockClosedIcon className="dropdown-icon text-primary me-2" />
											Suspend
										</Dropdown.Item>
									)}

									{status === "suspended" && (
										<Dropdown.Item
											as={Button}
											className={`d-flex align-items-center text-danger`}
											style={{ backgroundColor: "transparent" }}
											onClick={() => unsuspendUser(_id)}
										>
											<LockOpenIcon className="dropdown-icon text-primary me-2" />
											Unsuspend
										</Dropdown.Item>
									)}

									{status === "active" && (
										<Dropdown.Item
											as={Button}
											className={`d-flex align-items-center text-danger`}
											style={{ backgroundColor: "transparent" }}
											onClick={() => deactivateUser(_id)}
										>
											<UserRemoveIcon className="dropdown-icon text-primary me-2" />
											Deactivate
										</Dropdown.Item>
									)}
									{status === "inactive" && (
										<Dropdown.Item
											as={Button}
											className={`d-flex align-items-center text-danger`}
											style={{ backgroundColor: "transparent" }}
											onClick={() => activateUser(_id)}
										>
											<UserAddIcon className="dropdown-icon text-primary me-2" />
											Activate
										</Dropdown.Item>
									)}
								</>
							) : null}

							{user?.role === "master" || user?.role === "tech" ? (
								<Dropdown.Item
									as={Button}
									className="d-flex align-items-center"
									style={{ backgroundColor: "transparent" }}
									onClick={() => revokeRole(_id)}
								>
									<RefreshIcon className="dropdown-icon text-secondary me-2" />
									Revoke Role
								</Dropdown.Item>
							) : null}
						</Dropdown.Menu>
					</Dropdown>
				</td>
				<EditRole
					setShowEdit={setShowEdit}
					showEdit={showEdit}
					userID={_id}
					role={role}
					name={name}
					makeAdmin={makeAdmin}
					makeMaster={makeMaster}
				/>
				<ReportOptionModal
					setShow={setShow}
					show={show}
					type={"user"}
					user={props}
				/>
			</tr>
		);
	};

	return (
		<Card border="0" className="table-wrapper table-responsive shadow">
			<Card.Body>
				{user?.role === "admin" ||
					(user?.role === "master" && (
						<div className="d-flex mb-3">
							<Form.Select
								className="fmxw-200"
								disabled={disabledBulkMenu}
								value={bulkOption}
								onChange={bulkActionChange}
							>
								<option value="bulk_action">Bulk Action</option>
								<option value="send_email">Send Email</option>
							</Form.Select>
							<Button
								variant="secondary"
								size="sm"
								className="ms-3"
								disabled={disabledBulkMenu}
								onClick={applyBulkAction}
							>
								Apply
							</Button>
						</div>
					))}

				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">
								{user?.role === "admin" ||
									(user?.role === "master" && (
										<FormCheck type="checkbox" className="dashboard-check">
											<FormCheck.Input
												id="userCheckAll"
												checked={allSelected}
												onChange={selectAllUsers}
											/>
											<FormCheck.Label htmlFor="userCheckAll" />
										</FormCheck>
									)) || (
										// render an empty td for users who are not "admin" or "master"
										<FormCheck type="checkbox" disabled className="dashboard-check" />
									)}
							</th>
							<th className="border-bottom">Name</th>
							<th className="border-bottom">Date Joined</th>
							<th className="border-bottom">Verified</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Role</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody className="border-0">
						{currentItems?.map((u) => (
							<TableRow key={`user-${u?._id}`} {...u} />
						))}
					</tbody>
				</Table>
				<Card.Footer className="px-3 border-0 d-flex flex-column flex-md-row justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<span className="me-2">Page:</span>
						<Pagination className="mb-0">
							<Pagination.Prev
								disabled={currentPage === 1}
								onClick={() => handlePageChange(currentPage - 1)}
							>
								Prev
							</Pagination.Prev>
							{Array.from(
								{ length: Math.ceil(users.length / itemsPerPage) },
								(_, i) => (
									<Pagination.Item
										key={i}
										active={i + 1 === currentPage}
										onClick={() => handlePageChange(i + 1)}
									>
										{i + 1}
									</Pagination.Item>
								),
							)}
							<Pagination.Next
								disabled={currentPage === Math.ceil(users.length / itemsPerPage)}
								onClick={() => handlePageChange(currentPage + 1)}
							>
								Next
							</Pagination.Next>
						</Pagination>
					</div>
					<div className="d-flex align-items-center mt-3 mt-md-0">
						<span className="me-2">Show:</span>
						<select
							className="form-select form-select-sm"
							style={{ width: "80px", paddingRight: "1rem" }}
							onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
							value={itemsPerPage}
						>
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={15}>15</option>
						</select>
						<span className="mx-2">entries</span>
					</div>
					<div className="d-flex align-items-center mt-3 mt-md-0">
						<span className="me-2">Showing:</span>
						<span className="fw-normal">
							{currentItems.length} out of {users.length} entries
						</span>
					</div>
				</Card.Footer>
			</Card.Body>
		</Card>
	);
};

export default MembersTable;
