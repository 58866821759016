import {
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USER_FAIL,
	CLEAR_USER,
} from "../actions/userActions";

const initialState = {
	user: null,
	loading: false,
};

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_USER_REQUEST:
			return { ...state, loading: true };
		case GET_USER_SUCCESS:
			return { ...state, loading: false, user: action.payload };
		case GET_USER_FAIL:
			return { ...state, loading: false };
		case CLEAR_USER:
			return { ...state, user: null };
		default:
			return state;
	}
};
