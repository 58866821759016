import httpService from "./httpService";

export const getUserData = async () => {
	try {
		const res = await httpService.get("/users/user-profile");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const updateUserData = async (form) => {
	try {
		const response = await httpService.patch("/users/update-profile", form);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't update user data, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const uploadProfilePhoto = async (form) => {
	try {
		const response = await httpService.patch("/users/profile-image", form);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't upload, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const uploadCoverPhoto = async (form) => {
	try {
		const response = await httpService.patch("/users/cover-image", form);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't upload, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllUsers = async () => {
	try {
		const response = await httpService.get("/users/all-users");
		return response.data;
	} catch (err) {
		let errorMessage = "Something went wrong.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getUserProfile = async (id) => {
	try {
		const res = await httpService.get(`/users/single-user/${id}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

// User Management [Special roles]

export const addNewUser = async (form) => {
	try {
		const response = await httpService.post("/master/add-user", form);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't add user, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const makeAdmin = async (id) => {
	try {
		const response = await httpService.patch(`/master/make-admin/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't update role, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const makeMaster = async (id) => {
	try {
		const response = await httpService.patch(`/master/make-master/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't update role, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const revokeUserRole = async (id) => {
	try {
		const response = await httpService.patch(`/master/revoke-previleges/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't revoke user role, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const suspendUser = async (id) => {
	try {
		const response = await httpService.patch(`/master/suspend-user/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't suspend user, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const unsuspendUser = async (id) => {
	try {
		const response = await httpService.patch(`/master/unsuspend-user/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't unsuspend user, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const activateUser = async (id) => {
	try {
		const response = await httpService.patch(`/master/activate-user/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't activate user, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const deativateUser = async (id) => {
	try {
		const response = await httpService.patch(`/master/deactivate-user/${id}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't deactivate user, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
