import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Col, Row, Form, InputGroup, Pagination } from "react-bootstrap";
import { realtimeUpdate } from "shared/socket";
import MyProjectCard from "components/MyProjectCard";
import { SearchIcon } from "@heroicons/react/solid";
import { getUser } from "redux/actions/userActions";
import { useDispatch } from "react-redux";
import { getMemberAllProjects } from "services/projectService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const SwalWithBootstrapButtons = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary me-3",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

const MemberProjects = () => {
	const dispatch = useDispatch();
	const { memberId } = useParams();

	const [myProjects, setMyProjects] = useState([]);
	const [projects, setProjects] = useState([]);
	const [memberID, setMemberID] = useState(memberId);
	useEffect(() => {
		setMemberID(memberId);
	}, [memberId]);

	const getMemberProjects = useCallback(async () => {
		try {
			const { success, data, message } = await getMemberAllProjects(memberID);
			if (success === true) {
				setMyProjects(data.map((p) => ({ ...p, show: true })));
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, [memberID]);

	useEffect(() => {
		getMemberProjects();
	}, [getMemberProjects]);

	useEffect(() => {
		realtimeUpdate.on("project-updated", (data) => {
			if (data) {
				getMemberProjects();
				dispatch(getUser());
			}
		});
	}, [dispatch, getMemberProjects]);

	useEffect(() => {
		// Update projects when myProjects changes
		const updatedProjects = myProjects?.map((p) => ({ ...p, show: true }));
		setProjects(updatedProjects);
		dispatch(getUser());
	}, [myProjects, dispatch]);

	const [searchValue, setSearchValue] = useState("");

	const deleteProjects = async (ids) => {
		const projectsNr = ids.length;
		const textMessage =
			projectsNr === 1
				? "Are you sure do you want to delete this task?"
				: `Are you sure do you want to delete these ${projectsNr} tasks?`;

		const result = await SwalWithBootstrapButtons.fire({
			icon: "error",
			title: "Confirm deletion",
			text: textMessage,
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			const newProjects = projects.filter((t) => !ids.includes(t.id));
			const confirmMessage =
				projectsNr === 1
					? "The project has been deleted."
					: "The projects have been deleted.";

			setProjects(newProjects);
			await SwalWithBootstrapButtons.fire("Deleted", confirmMessage, "success");
		}
	};

	const changeSearchValue = (e) => {
		const newSearchValue = e.target.value;
		const newProjects = projects.map((p) => {
			const name = p.name.toLowerCase();
			const description = p.description.toLowerCase();

			const shouldShow =
				name.includes(newSearchValue) ||
				description.includes(newSearchValue) ||
				`${p.due_date}`.includes(newSearchValue);

			return {
				...p,
				show: shouldShow,
				isSelected: shouldShow && p.isSelected ? false : p.isSelected,
			};
		});

		setProjects(newProjects);
		setSearchValue(newSearchValue);
	};

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentProjects = projects.slice(indexOfFirstItem, indexOfLastItem);
	// Change page
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
					<div className="me-lg-3"></div>
				</Col>

				<Col xs={12} lg={6}>
					<InputGroup className="d-flex justify-content-lg-end input-group-merge search-bar">
						<InputGroup.Text>
							<SearchIcon className="icon icon-xs" />
						</InputGroup.Text>{" "}
						<Form.Control
							type="text"
							placeholder="Search Projects Here"
							className="w-100 fmxw-300"
							value={searchValue}
							onChange={changeSearchValue}
						/>
					</InputGroup>
				</Col>
			</div>

			<div className="task-wrapper border bg-white border-light shadow-sm py-1 rounded">
				{currentProjects
					.filter((p) => p.show)
					.map((p) => (
						<MyProjectCard
							{...p}
							key={`task-${p?._id}`}
							deleteTask={(id) => deleteProjects([id])}
						/>
					))}

				<Row className="d-flex align-items-center p-4">
					<div className="col-md-4 d-flex align-items-center mb-3 mb-md-0">
						<span className="me-2">Page:</span>
						<Pagination className="mb-0">
							<Pagination.Prev
								disabled={currentPage === 1}
								onClick={() => handlePageChange(currentPage - 1)}
							>
								Prev
							</Pagination.Prev>
							{Array.from(
								{ length: Math.ceil(projects.length / itemsPerPage) },
								(_, i) => (
									<Pagination.Item
										key={i}
										active={i + 1 === currentPage}
										onClick={() => handlePageChange(i + 1)}
									>
										{i + 1}
									</Pagination.Item>
								),
							)}
							<Pagination.Next
								disabled={currentPage === Math.ceil(projects.length / itemsPerPage)}
								onClick={() => handlePageChange(currentPage + 1)}
							>
								Next
							</Pagination.Next>
						</Pagination>
					</div>
					<div className="col-md-4 d-flex align-items-center mb-3 mb-md-0">
						<span className="me-2">Show:</span>
						<select
							className="form-select form-select-sm"
							style={{ width: "80px", paddingRight: "1rem" }}
							onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
							value={itemsPerPage}
						>
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={15}>15</option>
						</select>
						<span className="mx-2">entries</span>
					</div>
					<div className="col-md-4 d-flex align-items-center">
						<span className="me-2">Showing:</span>
						<span className="fw-normal">
							{currentProjects.length} out of {projects.length} entries
						</span>
					</div>
				</Row>
			</div>
		</>
	);
};

export default MemberProjects;
