import httpService from "./httpService";

export const newsSetting = async () => {
	try {
		const response = await httpService.put("/settings/news");
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const accountActivitySetting = async () => {
	try {
		const response = await httpService.put("/settings/account-activities");
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const meetupSetting = async () => {
	try {
		const response = await httpService.put("/settings/meetups");
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
